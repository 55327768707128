import {MarketplaceProgramReserve} from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import {
    GET_PROGRAM_RESERVE_FAILURE,
    GET_PROGRAM_RESERVE_SUCCESS,
    GetMarketplaceProgramReserveInStoreAction
} from "../../../actions/dashboard/marketplacePurchase/getMarketplaceProgramReserveInStore";

const initialReserve = {
    amount: 0
} as MarketplaceProgramReserve;

export const getMarketplaceProgramReserveInStoreReducer = (state: MarketplaceProgramReserve | null = initialReserve, action: GetMarketplaceProgramReserveInStoreAction) => {
    switch (action.type) {
        case GET_PROGRAM_RESERVE_SUCCESS:
            return {...action.programReserve}
        case GET_PROGRAM_RESERVE_FAILURE:
            return {...state}
        default:
            return {...state}
    }
}
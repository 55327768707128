import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../core/commons/reduxStore";
import { Card, Col, Row } from "antd";
import "../../../../../styles/layout/dashboard/marketplace/affiliateDetails/affiliateStatistics.less";
import  marketplaceAction from "../../../../../core/actions/dashboard/marketplace/index";
import affiliateStatisticsResource from "./Resource";

const {updateAffiliatePurchaseStatisticsInStoreAction} = marketplaceAction;

export interface AffiliateStatisticsProps {
    affiliateId: string
}

const AffiliateStatisticsComponent: React.FC<AffiliateStatisticsProps>  = (props: any) => {

    useEffect(() => {
        props.updateAffiliatePurchaseStatisticsInStoreAction(props.affiliateId, null)

    }, []);   

    return (<>
            <Row className="row-card statistic-row" gutter={[16, 16]}>
                <Col span={6} >
                    <Card className="card-item">
                        <span className="statistic-item">
                            <span className="statistic-value"> {props.affiliatePurchaseStatistics.totalPurchases ? props.affiliatePurchaseStatistics.totalPurchases : (`-`)}<br />    
                            <span className="statistic-name"> {affiliateStatisticsResource.totalPurchases}</span></span>
                        </span>
                    </Card>
                </Col>
                <Col span={6} >
                    <Card className="card-item">
                        <span className="statistic-item">
                            <span className="statistic-value"> { props.affiliatePurchaseStatistics.totalPurchasesAmount ? `$${ props.affiliatePurchaseStatistics.totalPurchasesAmount}` : (`-`)}<br />    
                            <span className="statistic-name"> {affiliateStatisticsResource.totalPurchasesAmount}</span></span>
                        </span>
                    </Card>
                </Col>
                <Col span={6} >
                    <Card className="card-item">
                        <span className="statistic-item">
                            <span className="statistic-value"> { props.affiliatePurchaseStatistics.totalGuacCashBackAmount ? `$${ props.affiliatePurchaseStatistics.totalGuacCashBackAmount}` : (`-`)} <br />    
                            <span className="statistic-name"> {affiliateStatisticsResource.totalGuacCashback}</span></span>
                        </span>
                    </Card>
                </Col>
                <Col span={6} >
                    <Card className="card-item">
                        <span className="statistic-item">
                            <span className="statistic-value"> { props.affiliatePurchaseStatistics.totalUserCashBackAmount ? `$${ props.affiliatePurchaseStatistics.totalUserCashBackAmount}`  : (`-`)} <br />    
                            <span className="statistic-name"> {affiliateStatisticsResource.totalUserCashback}</span></span>
                        </span>
                    </Card>
                </Col>
            </Row>
    </>)
}

const mapStateToProps = ({affiliatePurchaseStatistics }: AppState) =>
({
    affiliatePurchaseStatistics: affiliatePurchaseStatistics
});

export default connect(mapStateToProps, {
    updateAffiliatePurchaseStatisticsInStoreAction: updateAffiliatePurchaseStatisticsInStoreAction
})(AffiliateStatisticsComponent);
import { connect } from "react-redux";
import React, { useState } from "react";
import { AppState } from "../../../../../../core/commons/reduxStore";
import { AutoComplete, Button, Col, DatePicker, Drawer, Input, InputNumber, Popover, Row, Space } from "antd";
import AlertComponent from "../../../../../common/components/dataDisplay/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../../../../styles/layout/dashboard/marketplace/affiliateDetails/newTransaction.less";
import marketplaceAction from "../../../../../../core/actions/dashboard/marketplace";
import { currencyFormatter, currencyParser, s3Url } from "../../../../../common/utils/Utils";
import searchUsers from "../../../../../../core/services/dashboard/users/searchUsers";
import { commandRequest, queryRequest } from "../../../../../common/requestUtils/requestUtils";
import { UserDetails } from "../../../../../../core/models/dashboard/users/userDetails";
import newTransactionResource from "./Resource";
import marketplacePurchase from "../../../../../../core/services/dashboard/marketplacePurchase/index"
import { AddNewMarketplacePurchase } from "../../../../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import { InfoCircleOutlined } from "@ant-design/icons";

const { updateAffiliateDetailsInStoreAction } = marketplaceAction;
const { addNewMarketplaceTransaction } = marketplacePurchase;

const { Option } = AutoComplete;

export interface NewTransactionProps {
    isVisible: boolean;
    changeVisibility: (isVisible: boolean) => void;
}

interface AddNewTransactionValues {
    userId: string;
    amount: number;
    cashBack: number;
    guacCashBack: number;
    purchaseDate: string;
    lockingDate: string;
    orderNumber: string;
}

let initialValues: AddNewTransactionValues = {
    userId: "",
    amount: 0,
    cashBack: 0,
    guacCashBack: 0,
    purchaseDate: "",
    lockingDate: "",
    orderNumber: ""
}

const formValidator = () => Yup.object({
    userId: Yup.string().required(newTransactionResource.userRequiredValidator),
    purchaseDate: Yup.string().required(newTransactionResource.purchaseDateRequiredValidator),
    lockingDate: Yup.string().required(newTransactionResource.lockingDateRequiredValidator),
    amount: Yup.number().required(newTransactionResource.amountRequiredValidator).moreThan(0),
    orderNumber: Yup.string().required(newTransactionResource.orderNumberRequiredValidator)
});

const NewTransactionComponent: React.FC<NewTransactionProps> = (props: any) => {

    const [usersList, setUsersList] = useState<Array<UserDetails>>();
    const [errorMessage, setErrorMessage] = useState<any>(null);

    const onClose = () => {
        formik.setValues({
            userId: "",
            amount: 0,
            cashBack: 0,
            guacCashBack: 0,
            purchaseDate: "",
            lockingDate: "",
            orderNumber: ""
        });
        props.changeVisibility(props.isVisible);
    };

    const getFooter = (
        <>
            <div style={{ textAlign: 'right' }}>
                <Button className="cancel-button" onClick={onClose} value='Cancel' style={{ marginRight: 8 }}>
                    {newTransactionResource.cancelButton}
                </Button>
                <Button className="save-button" onClick={() => { return (formik.handleSubmit()) }} value='Save' type="primary">
                    {newTransactionResource.saveButton}
                </Button>
            </div>
        </>
    )

    const onSaveNewTransaction = () => {
        var newTransaction: AddNewMarketplacePurchase = {
            amount: formik.values.amount,
            cashBack: formik.values.cashBack,
            guacCashBack: formik.values.guacCashBack,
            marketplaceId: props?.affiliateDetails?.id,
            purchaseDate: formik.values.purchaseDate,
            userId: formik.values.userId,
            lockingDate: formik.values.lockingDate
        };

        const res = commandRequest(() => addNewMarketplaceTransaction(newTransaction))
            .then((res: any) => {
                onClose();
            }, (reason: any) => {
                setErrorMessage(reason);
            })
            .catch((error) => {
            })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        onSubmit: onSaveNewTransaction
    })


    const onSearchUser = async (value: string) => {
        if (value.trim().length == 0) {
            setUsersList([]);
        }
        else {
            const res = await queryRequest(() => searchUsers(value))
                .then((response: any) => {
                    setUsersList(response);
                }, (reason: any) => {
                    setUsersList([]);
                    setErrorMessage(reason);

                })
                .catch((error) => {
                    setUsersList([]);

                })
        }
    }

    const closeNotification = () => {
        setErrorMessage(null);
    }

    return (<>
        <Space>
            <Drawer
                title={
                    <>
                        <span className="modal-header-title">{newTransactionResource.headerTitle} : {props?.affiliateDetails?.name}</span>
                        {errorMessage ? <AlertComponent {...{ type: 'error', closable: true, message: 'Error', description: errorMessage, showIcon: true, onClose: closeNotification }}></AlertComponent> : ("")}

                    </>
                }
                placement={"right"}
                closable={false}
                onClose={onClose}
                visible={props.isVisible}
                key={"right"}
                width={464}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <>
                        {getFooter}
                    </>
                }>

                <Row className="transaction-affiliate-row-item">
                    <Row className="row-item">
                        <span className="item-title">{newTransactionResource.user}</span>
                        <AutoComplete
                            className="fullwidth-input item-input"
                            placeholder={<i>{newTransactionResource.userPlaceholder}</i>}
                            onSelect={(value: any, option: any) => { formik.setFieldValue('userId', option.key) }}
                            onSearch={onSearchUser}>
                            {usersList &&
                                <>
                                    {usersList.map((item) => (
                                        <Option key={item.id} value={item.fullName}>
                                            <span><b>{item.fullName}</b>&nbsp;(<i>{item.email}</i>)</span>
                                        </Option>
                                    ))}
                                </>
                            }
                        </AutoComplete>
                        {formik.errors.userId && formik.touched.userId && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.userId}</div>
                            </div>
                        )}
                    </Row>

                    <Row className="row-item">
                        <span className="item-title">{newTransactionResource.transactionNumber}&nbsp;
                            <Popover placement="bottom" content={newTransactionResource.transactionNumberPopover}
                                title={<><InfoCircleOutlined /> <b>{newTransactionResource.transactionNumber}</b></>} trigger="hover">
                                <a><InfoCircleOutlined /></a>
                            </Popover>
                        </span>
                        <Input onChange={formik.handleChange} name="orderNumber" className="item-input with-element" />
                        {formik.errors.orderNumber && formik.touched.orderNumber && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.orderNumber}</div>
                            </div>
                        )}
                    </Row>

                    <Row className="row-item">
                        <span className="item-title">{newTransactionResource.transactionDate} &nbsp;
                            <Popover placement="bottom" content={newTransactionResource.transactionDatePopover}
                                title={<><InfoCircleOutlined /> <b>{newTransactionResource.transactionDate}</b></>} trigger="hover">
                                <a><InfoCircleOutlined /></a>
                            </Popover>
                        </span>
                        <DatePicker
                            onChange={(value: any) => { formik.setFieldValue('purchaseDate', value) }}
                            className="fullwidth-input item-input" name="purchaseDate" />
                        {formik.errors.purchaseDate && formik.touched.purchaseDate && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.purchaseDate}</div>
                            </div>
                        )}
                    </Row>

                    <Row className="row-item">
                        <span className="item-title">{newTransactionResource.lockingDate}&nbsp;
                            <Popover placement="bottom" content={newTransactionResource.lockingDatePopover}
                                title={<><InfoCircleOutlined /> <b>{newTransactionResource.lockingDate}</b></>} trigger="hover">
                                <a><InfoCircleOutlined /></a>
                            </Popover>
                        </span>
                        <DatePicker
                            onChange={(value: any) => { formik.setFieldValue('lockingDate', value) }}
                            className="fullwidth-input item-input" name="lockingDate" />
                        {formik.errors.lockingDate && formik.touched.lockingDate && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.lockingDate}</div>
                            </div>
                        )}
                    </Row>

                    <Row className="row-item">
                        <span className="item-title">{newTransactionResource.amount} &nbsp;
                            <Popover placement="bottom" content={newTransactionResource.amountValuePopover}
                                title={<><InfoCircleOutlined /> <b>{newTransactionResource.amount}</b></>} trigger="hover">
                                <a><InfoCircleOutlined /></a>
                            </Popover>
                        </span>
                        <InputNumber
                            min={0}
                            name="amount"
                            onChange={(value: any) => { formik.setFieldValue('amount', Number(value)) }}
                            className="fullwidth-input item-input with-element"
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            // formatter={currencyFormatter}
                            parser={currencyParser}
                        />
                        {formik.errors.amount && formik.touched.amount && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.amount}</div>
                            </div>
                        )}
                    </Row>

                    <Row className="row-item">
                        <span className="item-title">{newTransactionResource.userCashback} <i>({newTransactionResource.optional})</i> </span>
                        <InputNumber
                            min={0}
                            name="cashBack"
                            onChange={(value: any) => { formik.setFieldValue('cashBack', Number(value)) }}
                            className="fullwidth-input item-input with-element"
                            // formatter={currencyFormatter}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={currencyParser}
                        />
                        {formik.errors.cashBack && formik.touched.cashBack && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.cashBack}</div>
                            </div>
                        )}
                    </Row>

                    <Row className="row-item">
                        <span className="item-title">{newTransactionResource.guacCashback} <i>({newTransactionResource.optional})</i> </span>
                        <InputNumber
                            min={0}
                            name="guacCashBack"
                            className="fullwidth-input item-input with-element"
                            onChange={(value: any) => { formik.setFieldValue('guacCashBack', Number(value)) }}
                            // formatter={currencyFormatter}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={currencyParser}
                        />
                        {formik.errors.guacCashBack && formik.touched.guacCashBack && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.guacCashBack}</div>
                            </div>
                        )}
                    </Row>

                </Row>
            </Drawer>
        </Space>

    </>);

}

const mapStateToProps = ({ affiliateDetails }: AppState) =>
({
    affiliateDetails: affiliateDetails
});

export default connect(mapStateToProps, {
    updateAffiliateDetailsInStoreAction
})(NewTransactionComponent);

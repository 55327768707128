import {
    AdminNotificationSendJobData,
    AdminNotificationSendJobModel,
    BaseRequestParams,
    GetNotificationJobModel, NotificationTemplateData
} from "../../../models/dashboard/notification/notification";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../../../commons/reduxStore";
import {Action} from "redux";
import {executeAction} from "../../../commons/baseAction";
import {commandRequest, queryRequest} from "../../../../app/common/requestUtils/requestUtils";
import {
    getAdminNotificationJobs,
    getAdminNotificationSubJobs
} from "../../../services/dashboard/notification/handleAdminNotifications";

export const GET_NOTIFICATION_JOBS_SUCCESS = "GET_NOTIFICATION_JOBS_SUCCESS";
export const GET_NOTIFICATION_JOBS_FAILURE = "GET_NOTIFICATION_JOBS_FAILURE";

export interface NotificationJobsInStoreAction {
    type: typeof GET_NOTIFICATION_JOBS_SUCCESS
        | typeof GET_NOTIFICATION_JOBS_FAILURE,
    model: AdminNotificationSendJobModel | null,
    searchParams: BaseRequestParams
}

export const updateNotificationJobsInStore = (
    model: GetNotificationJobModel,
    currentParams: BaseRequestParams | null,
    onSuccess: Function,
    onFailure: Function
):
    ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(() => getAdminNotificationJobs(model)),
        (res : AdminNotificationSendJobData) => {
            dispatch({
                type: GET_NOTIFICATION_JOBS_SUCCESS,
                model: res,
                searchParams: {
                    pageNumber: model.pageNumber,
                    pageSize: model.pageSize
                }
            } as unknown as NotificationJobsInStoreAction);
            onSuccess();
        },
        (err : any) => {
            dispatch({
                type: GET_NOTIFICATION_JOBS_FAILURE,
                model: null,
                searchParams: currentParams
            } as NotificationJobsInStoreAction);
            onFailure(err);
        }
    );
}

export const updateNotificationSubJobs = (
    model : GetNotificationJobModel,
    onSuccess: Function,
    onFailure: Function
) : void => {
    executeAction(
        commandRequest(() => getAdminNotificationSubJobs(model)),
        (res: AdminNotificationSendJobModel) => {onSuccess(res)},
        (_: any) => {onFailure()} );
}

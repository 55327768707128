import { GetListOfUserSubscriptions } from './../../../../models/dashboard/subscriptions/subscriptions';
import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../../commons/reduxStore";
import { PaginatedUserSubscriptions } from "../../../../models/dashboard/subscriptions/subscriptions";
import getListOfUserSubscriptions from '../../../../services/dashboard/subscriptions/getListOfUserSubscriptions';

export const GET_USERSUBSCRIPTIONS_SUCCESS = "GET_USERSUBSCRIPTIONS_SUCCESS";
export const GET_USERSUBSCRIPTIONS_ERROR = "GET_USERSUBSCRIPTIONS_ERROR";
var initData : PaginatedUserSubscriptions = {
    items:[],
    pageCount:0,
    pageNumber:0,
    pageSize:0,
    totalItemCount:0
}
export interface UserSubscriptionsInStoreAction {
    type: typeof GET_USERSUBSCRIPTIONS_SUCCESS | typeof GET_USERSUBSCRIPTIONS_ERROR,
    userSubscriptions: PaginatedUserSubscriptions
}

export const updateUserSubscriptionsInStoreAction = (model: GetListOfUserSubscriptions)
    : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        queryRequest(() => getListOfUserSubscriptions(model))
            .then((res: PaginatedUserSubscriptions) => {
                dispatch({
                    type: GET_USERSUBSCRIPTIONS_SUCCESS,
                    userSubscriptions: res
                } as UserSubscriptionsInStoreAction);
            })
            .catch((err) => {
                dispatch({
                    type: GET_USERSUBSCRIPTIONS_ERROR,
                    userSubscriptions: initData
                } as UserSubscriptionsInStoreAction);
            })
    }
import { FullscreenOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Row } from "antd";
import { Image } from 'antd';
import TextArea from "antd/lib/input/TextArea";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../core/commons/reduxStore";

import "../../../../../styles/layout/dashboard/marketplace/amazon/amazonCashbackRequest.less"
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    CashbackRequestModel,
    MoreInformationCashbackRequest
} from "../../../../../core/models/dashboard/marketplace/marketplaceCashbackRequestModel";
import { commandRequest } from "../../../../common/requestUtils/requestUtils";
import marketplace from "../../../../../core/services/dashboard/marketplace";
import { warning } from "../../../../common/utils/Utils";

export interface RequestMoreInformationProps {
    marketplaceCashbackRequest: CashbackRequestModel | null,
    isVisible: boolean
    changeVisibility: (isVisible: boolean) => void;
}

let initialValues: MoreInformationCashbackRequest = {
    userId: '',
    cashbackRequestId: '',
    subject: "",
    message: "",
}
const formValidator = () =>
    Yup.object({
        subject: Yup.string(),//.required("Subject is required"),
        message: Yup.string()//.required("Message is required"),
    });

const RequestMoreInformationComponent: React.FC<RequestMoreInformationProps> = (props: RequestMoreInformationProps) => {
    const [isLoadingModalMore, setLoadingModalMore] = useState<boolean>(false)
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [visible, setImageVisible] = useState(false);
    const [cashbackRequest, setCashbackRequest] = useState<CashbackRequestModel | null>(null);
    
    const onSendRequestMoreInformation = () => {

        if (cashbackRequest && cashbackRequest.user) {
            setLoadingModalMore(true);

            let object: MoreInformationCashbackRequest = {
                cashbackRequestId: cashbackRequest.id,
                userId: cashbackRequest.user.id,
                subject: formik.values.subject,
                message: formik.values.message
            };

            setTimeout(() => {
                try {

                    commandRequest(() => marketplace.sendCashbackRequestMoreInformation(object))
                        .then(() => {
                            setModalVisible(false);
                            props.changeVisibility(false);
                        }, (reason: any) => {
                            setModalVisible(false);
                            props.changeVisibility(false);
                        })
                        .catch((error) => {
                            setModalVisible(false);
                            props.changeVisibility(false);
                        })
                } catch (error) {
                    setModalVisible(false);
                    props.changeVisibility(false);
                }
            }, 2000);
        } else {
        warning("Please fill subject and message.")
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        onSubmit: onSendRequestMoreInformation
    })

    useEffect(() => {
        reset();
        setLoadingModalMore(false);
        setModalVisible(props.isVisible);
    }, [props.isVisible])

    useEffect(() => {
        reset();
        setLoadingModalMore(false);
        setCashbackRequest(props.marketplaceCashbackRequest);
    }, [props.marketplaceCashbackRequest])

    const handleCancel = () => {
        reset();
        setModalVisible(false);
        props.changeVisibility(false);
    };

    const getInvoiceUrls = (invoicesUrls: Array<string>) => {
        return (
            <>
                {invoicesUrls.length > 0 &&
                    <>
                        <Button type="primary" className="general-button " onClick={() => setImageVisible(true)}
                            icon={<FullscreenOutlined />}>
                            screenshot.png
                        </Button>
                        <Image
                            width={200}
                            style={{ display: 'none' }}
                            src={invoicesUrls[0]}
                            preview={{
                                visible,
                                onVisibleChange: value => {
                                    setImageVisible(value);
                                },
                            }}
                        />
                    </>
                }
            </>
        );
    }

    const reset = () => {
        formik.setValues({...initialValues});
        formik.resetForm();
    };

    return (
        <Modal
            title={`Request for more information: ${cashbackRequest?.user.fullName}`}
            visible={modalVisible}
            onOk={onSendRequestMoreInformation}
            onCancel={handleCancel}
            confirmLoading={isLoadingModalMore}

            footer={[
                <Button className="general-button cancel" onClick={() => {
                    handleCancel()
                }}>
                    Cancel
                </Button>,
                <Button className="general-button" key={"submit"}
                    onClick={() => onSendRequestMoreInformation()} type="primary"
                    loading={isLoadingModalMore}>
                    Send
                </Button>
            ]}>

            <Row className="row-modal">
                <Form.Item label="Subject"
                           required
                           tooltip="This is a required field"
                           className="form-item"
                           rules={[
                               {
                                   required: true,
                                   message: formik.errors.subject,
                               },
                           ]}>
                    <Input onChange={formik.handleChange}
                        name="subject"
                        className="input-subject"
                        value={formik.values.subject}
                        placeholder="Subject"
                    />
                    {formik.errors.subject && formik.touched.subject && (
                        <div  className="ant-form-item-explain ant-form-item-explain-error">
                            <div role="alert">{formik.errors.subject}</div>
                        </div>
                    )}
               </Form.Item>
               <Form.Item
                    label="Reasons"
                    required
                    tooltip="This is a required field"
                    className="form-item"
                    rules={[
                        {
                            required: true,
                            message: formik.errors.message,
                        },
                    ]}>
                    <TextArea onChange={formik.handleChange}
                        name="message"
                        value={formik.values.message}
                        rows={4}
                        className="input-msg"
                        placeholder="Enter reasons..." />
                    {formik.errors.message && formik.touched.message && (
                        <div
                            className="ant-form-item-explain ant-form-item-explain-error">
                            <div role="alert">{formik.errors.message}</div>
                        </div>
                    )}
              </Form.Item>

                <Row>
                    {getInvoiceUrls(cashbackRequest ? cashbackRequest.invoicesUrls : [])}
                </Row>
            </Row>
        </Modal>
    )
}

const mapStateToProps = ({ }: AppState) => ({});

export default connect(mapStateToProps, {})(RequestMoreInformationComponent);
import React from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const NotFound: React.FC = () => {
  let history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="This page not found"
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          Back
        </Button>
      }
    />
  );
};

export default NotFound;

import { act } from 'react-dom/test-utils';
import { GET_MOVEMONEYBYUSER_ERROR, GET_MOVEMONEYBYUSER_SUCCESS, MoveMoneyByUserInStoreAction } from '../../../actions/dashboard/moveMoney/updateMoveMoneyListByUserInStore';
import { MoveMoneyModel } from './../../../models/dashboard/moveMoney/moveMoney';


export const updateMoveMoneyByUserInStoreReducer = (state: MoveMoneyModel | null = null, action: MoveMoneyByUserInStoreAction) => {
    switch (action.type) {
        case GET_MOVEMONEYBYUSER_SUCCESS:
            return { ...state, ...action.userMoveMoney }
        case GET_MOVEMONEYBYUSER_ERROR:
            return {...state};
        default:
            return {...state};
    }
}
import { GET_PURCHASE_MARKETPLACE_SUCCESS, GET_PURCHASE_MARKETPLACE_ERROR, 
    MarketplacePurchaseByMarketplaceInStoreAction } from './../../../actions/dashboard/marketplacePurchase/updateMarketplacePurchaseByMarketplaceInStore';
import { MarketplacePurchaseModel } from './../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel';


export const updateMarketplacePurchaseByMarketplaceInStoreReducer = (state: MarketplacePurchaseModel| null= null, action: MarketplacePurchaseByMarketplaceInStoreAction) => {
    switch (action.type) {
        case GET_PURCHASE_MARKETPLACE_SUCCESS:
            return { ...state, ...action?.marketplacePurchases }
        case GET_PURCHASE_MARKETPLACE_ERROR:
            return {...state};
        default:
            return {...state};
    }
}
import {Empty, Input, message, Row, Spin} from 'antd';
import Table from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../core/commons/reduxStore';
import { MoveMoneyType, Transactions, TransactionsModel, TransactionsSearch, TransferStatus, TransferType } from '../../../core/models/dashboard/transactions/transation';
import { updateTransactionsListByUserInStoreAction } from "../../../core/actions/dashboard/transactions/updateTransactionsListByUserInStore";
import TransactionsDepositComponent from "./depositActivity/TransactionDepositComponent";
import WithdrawalActivityComponent from "./withdrawalActivity/WithdrawalActivityComponent";
import moment from 'moment';
import TransactionStatusComponent from '../../common/components/dashboard/TransactionStatusComponent';
import "../../../styles/layout/dashboard/transactions/userTransaction.less";
import ReferralActivityComponent from './referralAcivity/ReferralActivityComponent';
import { render } from '@testing-library/react';
import TransactionTypeComponent from '../../common/components/dashboard/TransactionTypeComponent';
import CashbackActivityComponent from './cashbackActivity/CashbackActivityComponent';
import TransactionsDirectDepositComponent from './directDepositActivity/DirectDepositActivityComponent';

const { Search } = Input;

export interface TransactionsComponentProps {
  userId: string,
  userTransactions: TransactionsModel,
  updateTransactionsListByUserInStoreAction: (
    transactions: TransactionsModel | null,
    searchParams: TransactionsSearch,
    onSuccess: Function,
    onFailure: Function) => void;
}

const initSearchParams: TransactionsSearch = {
  pageNumber: 1,
  pageSize: 10,
  searchValue: "",
  orderDescending: true,
  userId: "",
  sortColumn: "createdAt",
  transferStatus: null
};

const TransactionsComponent: React.FC<TransactionsComponentProps> = props => {
  const maxSearchLength = 50;
  const [isMounting, setMounting] = useState(true);
  const [keyExpanded, setKeyExpanded] = useState<Array<string>>([]);
  const [isLoading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState<TransactionsModel>();


  useEffect(() => {
    if (props.userTransactions) {
      if (!currentState) {
        setCurrentState(props.userTransactions)
      }
      if (currentState && !Object.is(currentState, props.userTransactions)) {
        setLoading(false);
        setCurrentState(props.userTransactions)
      }
      if (!Object.is(currentState, props.userTransactions)) {
        setCurrentState(props.userTransactions)
      }
    }
  }, [props.userTransactions]);

  const onTransactionLoadSuccess = () => {
    setLoading(false);
  }
  const onTransactionLoadFailure = () => {
    setLoading(false);
    message.error('Transactions failed to load!');
  }

  const getTransactions = () => {
    initSearchParams.userId = props.userId;
    props.updateTransactionsListByUserInStoreAction(null, initSearchParams, onTransactionLoadSuccess, onTransactionLoadFailure);
    setLoading(true);
    setMounting(false)

  }

  if (isMounting) {
    getTransactions();
  }

  const onSearchSubmit = (searchValue: string) => {
    initSearchParams.searchValue = searchValue;
    initSearchParams.pageNumber = 1;
    props.updateTransactionsListByUserInStoreAction(null, initSearchParams, onTransactionLoadSuccess, onTransactionLoadFailure);
    setLoading(true);
  }

  const getTransferType = (transferType: TransferType, row: Transactions) => {
    return (
      <>
        {transferType == TransferType.Withdraw ?
          <>
            {row.withdrawalActivity?.moneyTransferActivity?.type == MoveMoneyType.MarketplacePurchase &&
              <TransactionTypeComponent {...{ transferType: transferType }}></TransactionTypeComponent>
            }
            {row.withdrawalActivity?.moneyTransferActivity?.type == MoveMoneyType.Withdrawal &&
              <TransactionTypeComponent {...{ transferType: transferType }}></TransactionTypeComponent>
            }
          </>
          :
          <>
            <TransactionTypeComponent {...{ transferType: transferType }}></TransactionTypeComponent>
          </>
        }
      </>
    )
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (createdAt: Date) => (
        <> { createdAt ? `${moment(new Date(createdAt)).format("MM-DD-YYYY")}` : ("")} </>
      )
    },
    { title: 'Transaction ID', dataIndex: 'coreProTransferId', key: 'coreProTransferId', sorter: true, },
    {
      title: 'Transaction type', dataIndex: 'transferType', key: 'transferType', sorter: true,
      render: (transferType: number, row: Transactions) => (
        <>
          {getTransferType(transferType, row)}
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'transferStatus',
      key: 'transferStatus',
      sorter: true,
      filterMultiple: false,
      filters: [
        {
          text: <><TransactionStatusComponent {...{ transferStatus: TransferStatus.Completed }}></TransactionStatusComponent></>,
          value: TransferStatus.Completed,
        },
        {
          text: <><TransactionStatusComponent {...{ transferStatus: TransferStatus.Pending }}></TransactionStatusComponent></>,
          value: TransferStatus.Pending,
        },
        {
          text: <><TransactionStatusComponent {...{ transferStatus: TransferStatus.Cancelled }}></TransactionStatusComponent></>,
          value: TransferStatus.Cancelled,
        },
        {
          text: <><TransactionStatusComponent {...{ transferStatus: TransferStatus.Cancelled }}></TransactionStatusComponent></>,
          value: TransferStatus.Cancelled,
        },
        {
          text: <><TransactionStatusComponent {...{ transferStatus: TransferStatus.Processing }}></TransactionStatusComponent></>,
          value: TransferStatus.Processing,
        },
        {
          text: <><TransactionStatusComponent {...{ transferStatus: TransferStatus.Returned }}></TransactionStatusComponent></>,
          value: TransferStatus.Returned,
        },
      ],
      render: (transferStatus: TransferStatus, row: Transactions) => (
        <>
          <TransactionStatusComponent {...{ transferStatus: transferStatus }}></TransactionStatusComponent>
        </>
      )
    },
    {
      title: 'Amount', dataIndex: 'amount', key: 'amount',
      sorter: true,
      render: (amount: any) => (<>{`$${amount}`}</>)
    }
  ];


  const onTableRowExpand = (expanded: boolean, record: Transactions) => {
    var keys: Array<string> = [];
    if (expanded) {
      keys.push(record.transferId);
    }
    setKeyExpanded(keys);
  }

  const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {

    if (filters) {
      if (filters.status != undefined || filters.status != null) {
        initSearchParams.transferStatus = Number(filters.status[0]);
      } else {
        initSearchParams.transferStatus = null;
      }
      if (filters.transferStatus != undefined || filters.transferStatus != null) {
        initSearchParams.transferStatus = Number(filters.transferStatus[0]);
      } else {
        initSearchParams.transferStatus = null;
      }
    }

    if (sorter) {
      if (sorter.order || sorter.undefined) {
        initSearchParams.sortColumn = sorter.field;
        initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;

      } else {
        initSearchParams.sortColumn = 'createdAt';
        initSearchParams.orderDescending = true;
      }
    }

    if (pagination) {
      if (props.userTransactions?.pageNumber != pagination.current) {
        initSearchParams.pageNumber = pagination.current;
      }
    }

    props.updateTransactionsListByUserInStoreAction(null, initSearchParams, onTransactionLoadSuccess, onTransactionLoadFailure);
    setLoading(true);
  }

  const expandedRowContent = (record: Transactions) => {
    return (
      <>
        {record.transferType == TransferType.Deposit &&
          <TransactionsDepositComponent {...{ userId: record.userId, transferId: record.transferId, userTransactionsDeposit: record.depositActivity }} />
        }
        {record.transferType == TransferType.Cashback &&
          <CashbackActivityComponent {...{ procesingDate: record.createdAt, availableDate: record.createdAt, cashBackActivity: record.withdrawalActivity?.cashBackActivity }} />
        }

        {record.transferType == TransferType.Withdraw &&
          <WithdrawalActivityComponent {...{ userId: record.userId, transferId: record.transferId, userTransactionsWithdrawal: record.withdrawalActivity }} />
        }
        {record.transferType == TransferType.ReferralReward &&
          <ReferralActivityComponent {...{ userId: record.userId, transferId: record.transferId, referralActivity: record.referralTransaction }} />
        }
        {record.transferType == TransferType.DirectDeposit &&
            <TransactionsDirectDepositComponent {...{ userId: record.userId, transferId: record.transferId, directDeposits: record.directDepositActivity }}/>
        }
      </>
    )
  }
  return (
    <>
      <Row className="searchbox">
        <Search size="large"
          name="searchValue"
          className="search-input-goals"
          maxLength={maxSearchLength}
          placeholder="Search something..."
          onSearch={onSearchSubmit} />
      </Row>

      <Spin spinning={isLoading}>
        <Table
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
          pagination={{
            total: props.userTransactions?.totalItemCount, current: props.userTransactions?.pageNumber,
            pageSize: props.userTransactions?.pageSize,
            showSizeChanger: false,
          }}

          rowKey="transferId"
          onExpand={onTableRowExpand}
          expandedRowKeys={keyExpanded}
          className="transaction-table"
          expandable={{
            expandedRowRender: (record: Transactions) => <>
            {expandedRowContent(record)}
              {/* {record.transferType == TransferType.Deposit &&
                <TransactionsDepositComponent {...{ userId: record.userId, transferId: record.transferId, userTransactionsDeposit: record.depositActivity }} />
              }
              {record.transferType == TransferType.Cashback &&
                <CashbackActivityComponent {...{ procesingDate: record.createdAt, availableDate: record.createdAt, cashBackActivity: record.withdrawalActivity?.cashBackActivity }} />
              }

              {record.transferType == TransferType.Withdraw &&
                <WithdrawalActivityComponent {...{ userId: record.userId, transferId: record.transferId, userTransactionsWithdrawal: record.withdrawalActivity }} />
              }
              {record.transferType == TransferType.ReferralReward &&
                <ReferralActivityComponent {...{ userId: record.userId, transferId: record.transferId, referralActivity: record.referralTransaction }} />
              } */}
            </>,
            rowExpandable: (record: Transactions) => (record.transferType != null),
          }}
          onChange={(onChangeEvent)}
          columns={columns} dataSource={props.userTransactions?.items} />
      </Spin>
    </>
  );
}
const mapStateToProps = ({ userTransactions }: AppState) =>
({
  userTransactions: userTransactions
});

export default connect(mapStateToProps, {
  updateTransactionsListByUserInStoreAction
})(TransactionsComponent);

import { BankOutlined, CheckCircleFilled, CheckOutlined, ClockCircleOutlined, CloseOutlined, DeleteFilled, DollarCircleOutlined, EditFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";
import { CashBackStatus, MarketplacePurchaseType } from "../../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import { UserStatus } from "../../../../core/models/dashboard/users/user";

export interface PlatformPartnerProps {
    iconUrl: string,
    name: string
}

const PlatformComponent: React.FC<PlatformPartnerProps> = props => {

    return (
        <> {props.iconUrl ? <img className="platform-icon" src={`https://gauc-images.s3-us-west-2.amazonaws.com/${props.iconUrl}`}></img> : ("")}
            <span className="platform-title">{props.name}</span>
        </>
    )
}

export default PlatformComponent;
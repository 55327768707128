import React from "react";
import '../../../../styles/_variables.less';
import 'antd/dist/antd.less';
import '../../../../styles/layout/index.less';
import {Card, Col, Tabs} from "antd";
import TransactionCard from "./TransactionCard";

const {TabPane} = Tabs;

const TransactionCardWrapper: React.FC<any> = (props: any) => {
    return(<>
        <Col className="card-column" span={12}>
            <Card className="card-item" bordered={false}>
                <Tabs defaultActiveKey="2" >
                    <TabPane tab="All time" key="1">
                        <TransactionCard data={props.allTime} isToday={false} isAmount={props.isAmount}/>
                    </TabPane>
                    <TabPane tab="Today" key="2">
                        <TransactionCard data={props.today} isToday={true} isAmount={props.isAmount}/>
                    </TabPane>
                </Tabs>
            </Card>
        </Col>
    </>);
}


export default TransactionCardWrapper;
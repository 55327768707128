
export interface Goal {
    id: string;
    name: string;
    iconUrl: string;
    goalAmountLimit: number;
    goalAvailableBalance: number;
    goalStatus: GoalStatus;
    deadline: Date;
    percentage: number;
    createdAt: Date;
    isDeleted: boolean;
    goalSavingPercentage:number;
}

export interface Goals {
    items: Goal[];
    totalItemCount: number;
    pageCount: number;
    pageSize: number;
    pageNumber: number;
}


export interface GetGoals {
    searchValue: string,
    pageNumber: number,
    pageSize: number,
    orderDescending: boolean,
    userId: string,
    deletedGoals: boolean,
    sortColumn:string
}

export enum GoalStatus
{
    Active,
    Completed,
    Paused
}
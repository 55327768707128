import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { queryRequest } from '../../../../app/common/requestUtils/requestUtils';
import { AppState } from '../../../commons/reduxStore';
import { DepositModel, TransactionsDepositSearch } from '../../../models/dashboard/transactions/transation';
import getTransactionDepositByTransfer from '../../../services/dashboard/transactions/getTransactionDepositByTransfer';

export const GET_TRANSACTIONSDEPOSITBYTRANSFER_SUCCES = "GET_TRANSACTIONSDEPOSITBYTRANSFER_SUCCES";
export const GET_TRANSACTIONSDEPOSITBYTRANSFER_ERROR = "GET_TRANSACTIONSDEPOSITBYTRANSFER_ERROR";


export interface TransactionsDepositByTransferInStoreAction {
    type: typeof GET_TRANSACTIONSDEPOSITBYTRANSFER_SUCCES | typeof GET_TRANSACTIONSDEPOSITBYTRANSFER_ERROR,
    isLoading: true,
    userTransactionsDeposit: DepositModel | null,
    searchParams: TransactionsDepositSearch
}

export const updateTransactionsDepositByTransferInStoreAction  = (transactions: DepositModel | null, searchParams:
    TransactionsDepositSearch)
: ThunkAction<void, AppState, unknown, Action> => dispatch => {

    queryRequest(() => getTransactionDepositByTransfer(searchParams))
        .then((res) => {
            dispatch({
                    type: GET_TRANSACTIONSDEPOSITBYTRANSFER_SUCCES,
                    isLoading: false,
                    userTransactionsDeposit: res
                } as unknown as TransactionsDepositByTransferInStoreAction)
        })
        .catch((err) => {
            dispatch({
                    type: GET_TRANSACTIONSDEPOSITBYTRANSFER_ERROR,
                    isLoading: false,
                    userTransactionsDeposit: transactions
                } as unknown as TransactionsDepositByTransferInStoreAction)
        });
}

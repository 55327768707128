import React, { ReactElement, useEffect, useState } from "react";
import { Button, Card, Col, Divider, Drawer, Empty, Input, Popconfirm, Row, Select, Spin, Table } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CashbackRequestModel, FeeOrdersAmazonDetailsMatched, FeeOrdersReportModel, MatchCashbackRequestModel } from "../../../../../core/models/dashboard/marketplace/marketplaceCashbackRequestModel";
import moment from "moment";
import { getMoneyType } from "../../../../common/utils/Utils";
import { CheckOutlined, CloseOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import ImagePreviewGroupComponent from "../../../../common/components/dashboard/ImagePreviewGroupComponent";
import { MarketplaceModel } from "../../../../../core/models/dashboard/marketplace/affiliatesModel";
import { commandRequest } from "../../../../common/requestUtils/requestUtils";
import marketplace from "../../../../../core/services/dashboard/marketplace";
import AmazonCommisions from "../amazon-commisions/AmazonCommisions";
import { AppState } from "../../../../../core/commons/reduxStore";
import { updateAllMarketplacesInStoreAction } from "../../../../../core/actions/dashboard/marketplace/affiliates/updateAllMarketplaceInStore";
import { connect } from "react-redux";


export interface AddMatchedCashbackRequestProps {
    isVisible: boolean;
    amazonAffiliates: Array<MarketplaceModel>,
    selectedCashbackRequest: Array<CashbackRequestModel>,
    feeOrdersMatched: Array<FeeOrdersReportModel>,
    changeVisibility: (isVisible: boolean) => void;
    onConfirmCashbackPressed: () => void;
    updateAllMarketplacesInStoreAction: (allMarketplaces: Array<MarketplaceModel> | [], deleted: boolean) => void;
    allMarketplaces: Array<MarketplaceModel> | [],
}
interface AmazonCashbackPercentage {
    affiliateId: string;
    userCashbackPercentage: number;
    guacCashbackPercentage: number;
}

let initialValues: AmazonCashbackPercentage = {
    affiliateId: '',
    guacCashbackPercentage: 0,
    userCashbackPercentage: 0
}
const formValidator =
    Yup.object(({
        affiliateId: Yup.string().required("Amazon category is required"),
        userCashbackPercentage: Yup.number().moreThan(0).required("User cashback percentage is required"),
        guacCashbackPercentage: Yup.number().moreThan(0).required("Guac cashback percentage is required"),
    }))

const AddMatchedCashbackRequestComponent: React.FC<AddMatchedCashbackRequestProps> = (props: AddMatchedCashbackRequestProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState(false);
    const [hideChild, setHideChild] = useState<boolean>(false);
    const [isEditTotal, setEditTotal] = useState<boolean>(false);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [editTotalAmount, setEditTotalAmount] = useState<number>(0);
    const [userPercentage, setUserPercentage] = useState<any>(null);
    const [guacPercentage, setGuacPercentage] = useState<any>(null);
    const [guacCashback, setGuacCashback] = useState<any>(0);
    const [userCashback, setUserCashback] = useState<any>(0);
    const [selectedCategory, setAmazonCategory] = useState<any>(null);
    const [amazonAffiliates, setAmazonAffiliates] = useState<Array<MarketplaceModel>>([]);

    const onClose = () => {
        setUserCashback(0);
        setGuacCashback(0);
        setTotalAmount(0);
        setGuacPercentage(null);
        setUserPercentage(null);
        setAmazonCategory(null);
        props.changeVisibility(false);
    };

    useEffect(() => {
        setUserCashback(0);
        setGuacCashback(0);
        setTotalAmount(0);
        setUserPercentage(null)
        setGuacPercentage(null)
        setAmazonCategory(null)
    }, [props.isVisible])
    useEffect(() => {
        props.updateAllMarketplacesInStoreAction([], false);
    }, [])
    useEffect(() => {
        setUserCashback(totalAmount * (userPercentage / 100))
    }, [userPercentage])

    useEffect(() => {
        setGuacCashback(totalAmount * (guacPercentage / 100))
    }, [guacPercentage])

    useEffect(() => {
        setUserCashback(totalAmount * (userPercentage / 100))
    }, [totalAmount]);
    useEffect(() => {
        if (props.feeOrdersMatched.length > 0
        ) {
            let totalAmount = 0;
            props.feeOrdersMatched.forEach((item: FeeOrdersReportModel) => {
                let subTotal = item.price * item.quantity;
                totalAmount = subTotal + totalAmount;
            })
            setTotalAmount(totalAmount);
        }
        if (props.selectedCashbackRequest
            && props.selectedCashbackRequest.length > 0) {
            setTotalAmount(props.selectedCashbackRequest[0].purchaseDetails.amazonDetails.plaidAmount)
        }

    }, [props.selectedCashbackRequest])

    useEffect(() => {
            setAmazonAffiliates(props.allMarketplaces.filter(item => item.platform?.name.toLowerCase() == 'amazon'))
    }, [props.allMarketplaces])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        onSubmit: () => { }
    })

    const onImageOpen = (value: boolean) => {
        setHideChild(value)
    }

    const getInvoiceUrls = (item: CashbackRequestModel) => {
        return (
            <>
                <ImagePreviewGroupComponent key={item.id} imageUrls={item.invoicesUrls} isOpen={visible} isParent={true}
                    changeVisibility={onImageOpen} />
            </>
        )
    }

    const columns = [
        {
            title: 'User', dataIndex: 'user.fullName',
            key: 'user.fullName',
            width: 220,
            render: (id: string, row: CashbackRequestModel) => (
                <>
                    {row.user.fullName}
                </>
            )
        },
        {
            title: 'Screenshot',
            dataIndex: 'invoicesUrls',
            width: 270,
            key: 11,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    {getInvoiceUrls(row)}
                </>
            )
        },
        {
            title: 'Guac affiliate click date',
            dataIndex: 'marketplaceClickDate',
            width: 270,
            key: 12,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.amazonDetails.marketplaceClickDate ? `${moment(new Date(row.purchaseDetails.amazonDetails.marketplaceClickDate)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Request date',
            dataIndex: 'createdAt',
            key: 13,
            render: (createdAt: any, row: CashbackRequestModel) => (
                <>
                    <> {row.createdAt ? `${moment(new Date(row.createdAt)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Transaction date (Plaid)',
            dataIndex: 'transactionDate',
            key: 14,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.amazonDetails.authorizedDate ? `${moment(new Date(row.purchaseDetails.amazonDetails.authorizedDate)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Plaid Amount',
            dataIndex: 'transactionDate',
            key: 15,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.amazonDetails.plaidAmount ? <>{getMoneyType(row.purchaseDetails.amazonDetails.plaidAmount)}</> : ("")} </>
                </>
            )
        }
    ]

    const getFooter = (
        <>
            <Button className="cancel-button" onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
            </Button>
            <Button className="save-button"
                htmlType="submit"
                onClick={() => { handleOk() }}
                disabled={(userCashback == 0 || selectedCategory == null)}
                type="primary">
                Save
            </Button>
        </>
    )

    const onAffiliateSelected = (item: any) => {
        let percentage = amazonAffiliates.find(itm => itm.id == item)?.cashBackPercentage ?? 0;
        setUserPercentage(percentage * 100);
        setAmazonCategory(item);
    }

    const getAffiliates = (
        <>
            {amazonAffiliates.map((item: MarketplaceModel, i) => {
                return (<option key={i} value={item.id}>{item.name}</option>)
            })
            }
        </>
    )

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            let feeOrders: Array<FeeOrdersAmazonDetailsMatched> = [];

            props.feeOrdersMatched.forEach((item: FeeOrdersReportModel) => {
                let itemModel: FeeOrdersAmazonDetailsMatched = {
                    asin: item.asin,
                    category: item.category,
                    date: item.date,
                    name: item.name,
                    price: item.price,
                    quantity: item.quantity
                };
                feeOrders.push(itemModel);
            });

            let model: MatchCashbackRequestModel = {
                cashbackRequestId: props.selectedCashbackRequest[0].id,
                purchaseId: props.selectedCashbackRequest[0].purchaseDetails.id,
                userId: props.selectedCashbackRequest[0].user.id,
                feeOrdersAmazonDetailsMatched: feeOrders,
                userCashBackPercentage: userPercentage,
                guacCashBackPercentage: 0,
                affiliateId: selectedCategory,
                totalAmount: totalAmount
            }
            if (model) {
                matchCashbackRequest(model);
            }

        }, 2000);
    };

    const matchCashbackRequest = (model: MatchCashbackRequestModel) => {
        try {
            commandRequest(() => marketplace.matchCashbackRequestTransaction(model))
                .then(() => {
                    props.onConfirmCashbackPressed()
                    setLoading(false);
                }, (reason: any) => {
                    props.onConfirmCashbackPressed()
                    setLoading(false);
                })
                .catch((error) => {
                    resetStates();
                })
        } catch (error) {
            resetStates();
        }
    };

    const resetStates = () => {
        setUserCashback(0);
        setGuacCashback(0);
        setTotalAmount(0);
        setUserPercentage(null)
        setGuacPercentage(null)
        setAmazonCategory(null)
    }

    const editTotalAmountValue = (amount: number) => {
        setEditTotalAmount(amount);
    }

    return (
        <Drawer
            title="Confirm matched cashback request"
            placement="right"
            closable={false}
            key={"right"}
            width={'55%'}
            onClose={onClose}
            bodyStyle={{ paddingBottom: 80 }}
            visible={props.isVisible}
            footer={
                <Row style={{ justifyContent: "right" }}>
                    {getFooter}
                </Row>
            }>
            <Spin tip="Loading..." spinning={isLoading}>
                <>
                    <Table
                        locale={{
                            emptyText:
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No results for ths search. Try again..." />
                        }}
                        expandedRowKeys={props.selectedCashbackRequest.map(item => item.id)}
                        sortDirections={['ascend', 'descend', 'ascend']}
                        scroll={{ x: 1200 }}
                        columns={columns}
                        dataSource={props.selectedCashbackRequest}
                        rowKey="id"
                    />
                    <Divider />
                    <Row className={"amazon-request-row-item"}>
                        <Row style={{ 'width': '100%' }} className={hideChild ? 'row-child-hidden' : ''} gutter={[16, 16]}>
                            <Col span={12}>
                                <span className="amazon-affiliate-item">
                                    <span className="item-title">Amazon Category</span>
                                    <Select
                                        onChange={onAffiliateSelected}
                                        className="item-select"
                                        value={selectedCategory}
                                        defaultValue="Select category">
                                        {getAffiliates}
                                    </Select>
                                </span>
                            </Col>
                            <Col className={'percentage-items'} span={12}>
                                <span className="percentage">
                                    <span className="item-title">User cashback percentage</span>
                                    <Input
                                        readOnly={true}
                                        //onChange={(event) => setUserPercentage(event.target.value)}
                                        min={0}
                                        type="number"
                                        value={userPercentage}
                                        placeholder="Enter percentage..."
                                        className="item-input with-element-after" addonAfter="%" />
                                </span>
                            </Col>
                        </Row>

                        <Row className={hideChild ? "row-card-amazon statistic-row row-child-hidden" : 'row-card-amazon statistic-row'}
                            gutter={[16, 16]}>
                            <Col span={12}>
                                <Card className="card-item">
                                    <Row className="statistic-item">
                                        <Row className="statistic-value">
                                            <span>Total Amount</span>
                                            <Row className="statistic-value">
                                                {!isEditTotal ?
                                                    <span className="statistic-name "> {getMoneyType(totalAmount)}</span>
                                                    :
                                                    <span className="percentage">
                                                        <Input
                                                            min={0}
                                                            type="number"
                                                            defaultValue={totalAmount}
                                                            onChange={(ev: any) => { editTotalAmountValue(ev.target.value) }}
                                                            className="item-input" addonBefore={"$"} />
                                                    </span>
                                                }
                                            </Row>
                                        </Row>
                                    </Row>
                                    <Button
                                        // 
                                        className="resend-anchor-link button-item"
                                        type="link">
                                        {!isEditTotal ?
                                            <EditOutlined onClick={() => {
                                                setEditTotal(!isEditTotal)
                                                setEditTotalAmount(totalAmount);
                                            }} /> :
                                            <>
                                                <CheckOutlined onClick={() => {
                                                    setEditTotal(!isEditTotal);
                                                    setTotalAmount(editTotalAmount);
                                                }} />
                                                <CloseOutlined onClick={() => {
                                                    setEditTotal(!isEditTotal);
                                                    setEditTotalAmount(totalAmount);
                                                }} />
                                            </>
                                        }

                                    </Button>

                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="card-item">
                                    <Row className="statistic-item">
                                        <Row className="statistic-value">
                                            <span>Total user Cashback</span>
                                            <Row className="statistic-value">
                                                <span className="statistic-name">{getMoneyType(userCashback)}</span>
                                            </Row>
                                        </Row>
                                    </Row>
                                </Card>
                            </Col>

                        </Row>
                        <Row className={"percentage-rates"}>
                            <AmazonCommisions />
                        </Row>
                    </Row>
                </>
            </Spin>
        </Drawer>
    )
}
// export default AddMatchedCashbackRequestComponent;
const mapStateToProps = ({ allMarketplaces }: AppState) =>
({
    allMarketplaces: allMarketplaces,
});

export default connect(mapStateToProps, {
    updateAllMarketplacesInStoreAction
})(AddMatchedCashbackRequestComponent);
import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../commons/reduxStore";
import { UserData, UserModel } from '../../../models/dashboard/users/user';
import getListOfUsersByDevice from '../../../services/dashboard/users/getListOfUsersByDevice';

export const GET_USERSBYDEVICE_SUCCESS ="GET_USERSBYDEVICE_SUCCESS";
export const GET_USERSBYDEVICE_ERROR = "GET_USERSBYDEVICE_ERROR";

export interface ListOfUsersByDeviceAction{
    type:  typeof GET_USERSBYDEVICE_SUCCESS | typeof GET_USERSBYDEVICE_ERROR,
    usersDevice: Array<UserData>
}
export const updateListOfUsersByDeviceInStoreAction = (
    deviceId :string
) : ThunkAction<void, AppState,unknown,Action> => dispatch => {
    queryRequest(()=> getListOfUsersByDevice(deviceId))
    .then((res:any) =>{
        dispatch({
            type:GET_USERSBYDEVICE_SUCCESS,
            usersDevice: res
        } as ListOfUsersByDeviceAction);
    })
    .catch((err) => {
        dispatch({
            type:GET_USERSBYDEVICE_ERROR,
            usersDevice: []
        } as ListOfUsersByDeviceAction);
        
    });
}
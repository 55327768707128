import {
    AdminNotificationExecutionLogData,
    AdminNotificationExecutionLogModel,
    BaseRequestParams, GetNotificationLogsModel
} from "../../../models/dashboard/notification/notification";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../../../commons/reduxStore";
import {Action} from "redux";
import {executeAction} from "../../../commons/baseAction";
import {queryRequest} from "../../../../app/common/requestUtils/requestUtils";
import {
    getAdminNotificationExecutionLogsSimple
} from "../../../services/dashboard/notification/handleAdminNotifications";

export const GET_NOTIFICATION_SIMPLE_LOGS_SUCCESS = "GET_NOTIFICATION_SIMPLE_LOGS_SUCCESS";
export const GET_NOTIFICATION_SIMPLE_LOGS_FAILURE = "GET_NOTIFICATION_SIMPLE_LOGS_FAILURE";

export interface NotificationSimpleLogsInStoreAction {
    type: typeof GET_NOTIFICATION_SIMPLE_LOGS_SUCCESS
        | typeof GET_NOTIFICATION_SIMPLE_LOGS_FAILURE,
    model: AdminNotificationExecutionLogModel | null,
}

export const updateNotificationLogsInStoreSimple = (
    pageNumber: number,
    pageSize: number,
    onSuccess: Function,
    onFailure: Function
):
    ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(() => getAdminNotificationExecutionLogsSimple({
            isSub: false, jobId: "",
            pageNumber: pageNumber,
            pageSize: pageSize
            })),
        (res : AdminNotificationExecutionLogData) => {
            dispatch({
                type: GET_NOTIFICATION_SIMPLE_LOGS_SUCCESS,
                model: res
            } as unknown as NotificationSimpleLogsInStoreAction);
            onSuccess();
        },
        (err : any) => {
            dispatch({
                type: GET_NOTIFICATION_SIMPLE_LOGS_FAILURE,
                model: null
            } as unknown as NotificationSimpleLogsInStoreAction);
            onFailure(err);
        }
    );
}
import { AffiliatePurchaseStatisticsInStoreAction, GET_AFFILIATE_STATISTICS_ERROR, GET_AFFILIATE_STATISTICS_SUCCESS } from "../../../../actions/dashboard/marketplace/affiliates/updateAffiliateStatisticsInStore";
import { MarketplacePurchaseStatisticsModel } from "../../../../models/dashboard/marketplace/affiliateStatisticsModel";

export const updateAffiliatePurchaseStatisticsInStoreReducer = (state: MarketplacePurchaseStatisticsModel | null = null, action: AffiliatePurchaseStatisticsInStoreAction) => {
    switch (action.type) {
        case GET_AFFILIATE_STATISTICS_SUCCESS:
            return { ...state, ...action.affiliatePurchaseStatistics }
        case GET_AFFILIATE_STATISTICS_ERROR:
            return {...state};
        default:
            return {...state};
    }
}
import {Card, Col, Row} from "antd";
import {UnorderedListOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../../core/commons/reduxStore";
import {getTotalMarketplacePurchasesInStoreAction} from "../../../../core/actions/dashboard/marketplacePurchase/getTotalMarketplacePurchasesInStore";

const TotalMarketPlacePurchase : React.FC<any> = (props: any) => {

    useEffect(() => {
        props.getTotalMarketplacePurchasesInStoreAction();
    }, [])

    return (<Col className="card-column" span={24}>
    <Card className="card-item inline" bordered={false}>
        <Row className=" statistics ">
            <div className="centered-column">
                <UnorderedListOutlined className="icon-styles circle-icon"/>
            </div>
            <div className="stats-details margin-left">
                <h6 className="title-item">Total purchase amount</h6>
                <span
                    className="value-item">{`$${props.totalMarketplacePurchases?.amount}` || '-'}&nbsp;&nbsp;</span>
                <span
                    style={{
                        borderLeft: '1px solid '
                    }}
                    className={"value-item inline-block"}> &nbsp;{`${props.totalMarketplacePurchases?.count}`}</span>
            </div>
        </Row>
    </Card>
</Col>);
};

const mapStateToProps = ({totalMarketplacePurchases}: AppState) => ({
    totalMarketplacePurchases
});

export default connect(
    mapStateToProps,{
        getTotalMarketplacePurchasesInStoreAction
    }
)(TotalMarketPlacePurchase)
import {
    PlusCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    UploadOutlined,
    DeleteFilled,
    MenuOutlined,
    DownOutlined,
    RightOutlined
} from "@ant-design/icons";
import { Card,  Modal, Row, Table, Button, Upload, Input,  Popover } from "antd";
import React, { useEffect, useState } from 'react';
import "../../../../styles/layout/dashboard/marketplace/affiliateHomeCategories.less";
import { AddMarketplaceCategoryModel, CategoryStatus, MarketplaceCategoriesModel, OrderType, UpdateMarketplaceCategoryModel, UpdateMarketplaceOrderModel } from "../../../../core/models/dashboard/marketplace/marketplaceCategoryModel";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import {  useFormik } from "formik";
import * as Yup from "yup";
import { FileResponse } from "../../../../core/models/files/fileModel";
import { commandRequest } from "../../../common/requestUtils/requestUtils";
import services from "../../../../core/services";
import EditCategoriesComponent from "./EditCategoriesComponent";
import { updateMarketplaceCategoriesInStoreAction } from "../../../../core/actions/dashboard/marketplace/categories/updateMarketplaceCategoriesInStore";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { OrderIndex } from "../../../../core/models/dashboard/marketplace/affiliatesModel";
import { acceptFileUpload, error } from "../../../common/utils/Utils";

const { marketplace, uploadIcon } = services;

export interface AffiliateHomeCategoryProps {
    affiliateCategory: Array<MarketplaceCategoriesModel>,
    updateMarketplaceCategoriesInStoreAction: (affiliateCategory: Array<MarketplaceCategoriesModel> | []) => void;
}

interface CategoryValues {
    // id: string;
    name: string;
    fileUrl: string;
    //status: boolean,
}

let initialValues: CategoryValues = {
    // id: "",
    name: "",
    fileUrl: "",
    //status: true
}

const formValidator = () =>
    Yup.object({
        name: Yup.string().max(50, 'You have reached your maximum limit of characters allowed').required("Name is required"),
        fileUrl: Yup.string().required("Image is required"),
    });

const ASortableItem = SortableElement((props: any) => <tr {...props} />);
const ASortableContainer = SortableContainer((props: any) => <tbody {...props} />);

const AffiliateHomeCategoriesComponent: React.FC<AffiliateHomeCategoryProps> = props => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedItem, setSelectedItem] = useState<MarketplaceCategoriesModel | null>(null)
    const [uploadedFile, setUploadedFile] = useState<FormData>();
    const [fileListUpload, setFileListUpload] = useState<Array<UploadFile>>([]);
    const [isCollapsed, setCollapsed] = useState(false);
    const [affiliateCategory, setAffiliateCategory] = useState<Array<MarketplaceCategoriesModel>>([]);
    const [affiliateCategoryLast, setLastAffiliateCategory] = useState<Array<MarketplaceCategoriesModel>>([]);
    const [acceptedFiles, setAcceptedFiles] = useState<Array<string>>([]);

    const maxInputLength = 50;

    useEffect(() => {

        if (!Object.is(affiliateCategoryLast, props.affiliateCategory)) {
            setAffiliateCategory(props.affiliateCategory)
             setLastAffiliateCategory(props.affiliateCategory)
            setAcceptedFiles(acceptFileUpload.split(','));
        }

    })

    const collapse = () => {
        setCollapsed(!isCollapsed);
    }

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
    ));

    const onSortEnd = (index: OrderIndex) => {
        if (index.oldIndex !== index.newIndex) {
            const newData = arrayMove([].concat([...affiliateCategory] as never[]), index.oldIndex, index.newIndex).filter(el => !!el);
            (newData as Array<MarketplaceCategoriesModel>).forEach((item, index) => {
                item.order = index + 1;
            });
            let orderdAffiliate: Array<UpdateMarketplaceOrderModel> = [];
            orderdAffiliate = newData.map((f: MarketplaceCategoriesModel) =>
            ({
                id: f.id,
                order: f.order
            } as UpdateMarketplaceOrderModel));

            if (orderdAffiliate.length > 0) {
                updateOrderNumberOfCategories(orderdAffiliate);
            }
            setAffiliateCategory([...[], ...newData])

        }
    };

    const updateOrderNumberOfCategories = (categories: Array<UpdateMarketplaceOrderModel>) => {
        commandRequest(() => marketplace.updateOrder(OrderType.Categories, categories))
            .then((res: any) => {
                props.updateMarketplaceCategoriesInStoreAction([]);
            }).catch((error: any) => {
                // todo :: show alert;
            })
    }

    const DraggableContainer = (props: any) => {
        return (
            <ASortableContainer
                useDragHandle
                disableAutoscroll={false}
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />
        )
    }

    const DraggableBodyRow = (dragable: any) => {
        const index = affiliateCategory.findIndex(x => x.order === dragable['data-row-key']);
        return <ASortableItem index={index} {...dragable} />;
    };

    const uploadProps = {
        name: 'file',
        action: '',
        beforeUpload: (file: any) => {
            if (file.type !== 'image/png') {
                error(`${file.name} is not a png file`);
            }
            return file.type === 'image/png' ? true : false;
        },

        onChange(info: UploadChangeParam) {
            if (info) {
                if (info.file && acceptedFiles.length > 0 && acceptedFiles.includes(info.file.type)) {

                    if (info.fileList.length > 0) {
                        setFileListUpload([]);
                        let formData = new FormData();
                        formData.append("file", info.file as any);
                        setUploadedFile(formData)
                        setFileListUpload(info.fileList.filter(f => acceptedFiles.includes(f.type)));
                        formik.values.fileUrl = info.file.fileName ? info.file.fileName : "uploaded";
                        formik.setValues({ ...formik.values });
                    }
                }
            } else {
                setFileListUpload([]);
                setUploadedFile(undefined)
                formik.values.fileUrl = "";
                formik.setValues({ ...formik.values });
            }
        },
        onRemove() {
            setFileListUpload([]);
            setUploadedFile(undefined)
            formik.values.fileUrl = "";
            formik.setValues({ ...formik.values });
        }
    };

    const showModal = () => {
        setIsEditMode(false);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (isEditMode) {

        } else {
            if (uploadedFile) {
                commandRequest(() => uploadIcon(uploadedFile))
                    .then((res: FileResponse) => {
                        addNewCategory(res);
                    }).catch((error: any) => {
                        // todo :: show alert;
                    })
            }
        }
        setIsModalVisible(false);
    };

    const addNewCategory = (uploadedIcon: FileResponse) => {

        let newCategory: AddMarketplaceCategoryModel = {
            iconUrl: `${uploadedIcon.fileName}`,
            name: formik.values.name
        }

        if (newCategory) {
            try {
                commandRequest(() => marketplace.addNewCategory(newCategory))
                    .then(() => {
                        props.updateMarketplaceCategoriesInStoreAction([]);
                    })
                    .catch((error) => {
                        console.log("error: ", error)
                    })
            }
            catch (error) {
                console.log("error: ", error)
            }
        }
    }

    const handleCancel = () => {
        formik.resetForm();
        setFileListUpload([]);
        setIsModalVisible(false);
    };

    const delteCategory = (row: MarketplaceCategoriesModel) => {
        commandRequest(() => marketplace.deleteCategory(row.id))
            .then((item) => {
                props.updateMarketplaceCategoriesInStoreAction([]);
            }, (reason: any) => {
                error(reason);
            })
            .catch(err => {
            })
    }

    const onDeleteCategory = (row: MarketplaceCategoriesModel) => {
        return (
            Modal.confirm({
                title: `Are you sure you want to delete "${row.name}"?`,
                icon: <DeleteFilled className={"delete-icon-modal"} />,
                content: `All affiliates that are currently assigned to this category will be temporary paused, until assigned another category. 
                Assigning a new category can be done by going into the affiliate details page and select one of the existing categories.`,
                okText: 'Delete',
                cancelText: 'Cancel',
                className: "delete-modal",
                onOk() {
                    delteCategory(row);
                },
                onCancel() {
                    console.log('Cancel: ', row);
                },
            })
        )
    }

    const getFileNameFromUrl = (url: string) => {
        if (url.trim().length > 0) {
            var urlSplited = url.split('/');
            return (urlSplited[urlSplited.length - 1])
        }
        return "";
    }

    const onEditRow = (row: MarketplaceCategoriesModel) => {
        formik.resetForm();
        setIsEditMode(true);
        setIsModalVisible(false);
        setSelectedItem(row);
    }

    const closeEditMode = (isVisible: boolean) => {
        setIsEditMode(!isVisible)
        props.updateMarketplaceCategoriesInStoreAction([]);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        onSubmit: handleOk
    })

    const columns = [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: '',
            dataIndex: 'iconUrl',
            width: 30,
            render: (iconUrl: string, row: MarketplaceCategoriesModel) => (
                <>
                    <img className="category-icon" src={`${iconUrl}`}></img>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'name',
            render: (name: string, row: MarketplaceCategoriesModel) => (
                <>
                    <span className="title">
                        {name.length > 30 ?
                            <>
                                <Popover content={row.name} title="Name" trigger="hover">
                                    {`${name.substring(0, 30)}...`}
                                </Popover>
                            </> :
                            <>  {name} </>
                        }
                        <span className={"affiliates-count"}>
                            ({row.affiliates.length})</span>
                    </span>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'id',

            render: (id: string, row: MarketplaceCategoriesModel) => (
                <>
                    <span className="action-icons">
                        <span className="icon-circle">
                            <EditOutlined onClick={() => onEditRow(row)} />
                        </span>
                        <span className="icon-circle">
                            <DeleteOutlined onClick={() => onDeleteCategory(row)} />
                        </span>
                    </span>

                </>
            )
        },
    ];

    const getHeaderTitle = () => {
        return (
            <>
                {isCollapsed ? <><DownOutlined onClick={collapse} /> {`Categories (${props.affiliateCategory.length})`}</> : <><RightOutlined onClick={collapse} /> {`Categories (${props.affiliateCategory.length})`}</>}
            </>
        )
    }

    return (
        <>
            <Card onClick={collapse} className={isCollapsed ? "collapsed-off" : "collapsed-in"} title={getHeaderTitle()}
                extra={<a className="add-new-category" href="#" onClick={showModal}><PlusCircleOutlined
                    className="add-new-icon" />New</a>}>
                <Table
                    className="categories-table"
                    pagination={false}
                    dataSource={affiliateCategory}
                    columns={columns}
                    rowKey="order"
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                    rowClassName={(record, index) => record.status == CategoryStatus.Paused ? 'disabled-row ' : ''}
                />
            </Card>

            <Modal className="modal" title="Add new category" visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button className="footer-cancel-button" key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button className="footer-submit-button" key="submit"
                        type="primary"
                        disabled={!formValidator().isValidSync(formik.values)}
                        onClick={handleOk}>
                        Save
                    </Button>,
                ]}>
                {!isEditMode &&
                    <>
                        <Row className="row-modal">
                            <span className="title-field">Category name </span>
                            <Input className="name"
                                maxLength={maxInputLength}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                size="large" name="name" />
                            {formik.errors.name && formik.touched.name && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.name}</div>
                                </div>
                            )}
                        </Row>
                        <Row className="row-modal upload-row">
                            <span className="title-field">Category icon</span>
                            <Upload listType="picture"
                                {...uploadProps}
                                fileList={fileListUpload}
                                beforeUpload={() => false}
                                name="fileUrl"
                                accept={acceptFileUpload}
                                defaultFileList={[]}>
                                <Button className="upload-button" icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                            {formik.errors.fileUrl && formik.touched.fileUrl && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.fileUrl}</div>
                                </div>
                            )}
                        </Row>
                        <Row>
                            <p className="image-description">Image format should be .png and minimum size 24px x 24px</p>
                        </Row>
                    </>
                }
            </Modal>

            {isEditMode &&
                <>
                    <EditCategoriesComponent {...{ isVisible: isEditMode, changeVisibility: closeEditMode, category: selectedItem }}></EditCategoriesComponent>
                </>
            }
        </>
    )
}
const mapStateToProps = ({ affiliateCategory }: AppState) =>
({
    affiliateCategory: affiliateCategory
});

export default connect(mapStateToProps, {
    updateMarketplaceCategoriesInStoreAction
})(AffiliateHomeCategoriesComponent);
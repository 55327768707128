import { GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_ERROR, GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_SUCCES, TransactionWithdrawalByTransferInStoreAction } from "../../../actions/dashboard/transactions/updateTransactionWithdrawalByTransferInStore";
import { WithdrawalTransactionModel } from "../../../models/dashboard/transactions/transation";

export const updateTransactionWithdrawalByTransferInStoreReducer 
=(state:WithdrawalTransactionModel| null= null, action: TransactionWithdrawalByTransferInStoreAction) => {
    switch (action.type) {
        case GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_SUCCES:
            return { ...state, ...action.userTransactionsWithdrawal }
        case GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_ERROR:
            return {...state};
        default:
            return {...state};
    }
}
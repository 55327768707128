import { DateFilter } from "../users/user";


export interface MoveMoneyModel {
  type: MoveMoneyType;
  from: string;
  nameOfFrom: string;
  destination: string;
  nameOfDestination: string;
  createdAt: Date;
  status: MoveMoneyStatus;
  amount: number;
}

export interface GetMoveMoney {
  items: MoveMoneyModel[];
  totalItemCount: number;
  pageCount: number;
  pageSize: number;
  pageNumber: number;

}

export interface MoveMoneySearch {
  userId:string;
  pageNumber:number;
  pageSize:number;
  searchValue:string;
  orderDescending:boolean;
  sortColumn: string;
  status: number | null;
  type:number | null;
  createdAt: DateFilter 
}
export enum MoveMoneyType
{
    GoalToGoal, 
    Withdrawal,
    MarketplacePurchase
}

export enum MoveMoneyStatus 
{
  Completed, 
  Pending, 
  Voided
}
import React from "react";
import { Row, Col } from "antd";

import Logo from "../../assets/icons/login-logo.png";


const GuacHeader: React.FC = () => {


  return (
        <a className="account-header" href="#">
          <img className="header-logo" src={Logo} alt="guac-logo" />
        </a>
  );
};

export default GuacHeader;
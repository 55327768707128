import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { queryRequest } from '../../../../app/common/requestUtils/requestUtils';
import { AppState } from '../../../commons/reduxStore';

import getTransactionsByUser from '../../../services/dashboard/transactions/getTransactionsByUserId';
import { TransactionsModel, TransactionsSearch } from './../../../models/dashboard/transactions/transation';

export const GET_TRANSACTIONSBYUSER_SUCCES = "GET_TRANSACTIONSBYUSER_SUCCES";
export const GET_TRANSACTIONSBYUSER_ERROR = "GET_TRANSACTIONSBYUSER_ERROR";

export interface TransactionsByUserInStoreAction {
    type: typeof GET_TRANSACTIONSBYUSER_SUCCES | typeof GET_TRANSACTIONSBYUSER_ERROR,
    userTransactions: TransactionsModel | null,
    searchParams: TransactionsSearch
}

export const updateTransactionsListByUserInStoreAction =
    (transactions: TransactionsModel | null,
     searchParams: TransactionsSearch,
     onSuccess: Function,
     onFailure: Function)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {

            queryRequest(() => getTransactionsByUser(searchParams))
                .then((res) => {
                    dispatch({
                        type: GET_TRANSACTIONSBYUSER_SUCCES,
                        userTransactions: res
                    } as TransactionsByUserInStoreAction);
                    onSuccess();
                })
                .catch((err) => {
                    dispatch({
                        type: GET_TRANSACTIONSBYUSER_ERROR,
                        userTransactions: transactions
                    } as TransactionsByUserInStoreAction);
                    onFailure();
                });
        }

import React, {useEffect, useRef, useState} from "react";
import '../../../../styles/_variables.less';
import 'antd/dist/antd.less';
import '../../../../styles/layout/index.less';
import {Button, Checkbox, Col, Dropdown, Empty, Row} from "antd";
import PieChartComponent from "../../../common/components/dataDisplay/PieChart";
import StatisticsItemComponent from "../../../common/components/dataDisplay/StatisticItem";
import {TransactionStatisticsChartColor} from "../../../common/utils/ChartColors";
import {CloseCircleOutlined, FilterOutlined} from "@ant-design/icons";
import {
    TransactionModel,
    TransactionStatusEnum,
    TransactionTypeEnum,
    TransactionTypeWrapper
} from "../../../../core/models/dashboard/statistics";
import {CurrencyNumberFormat} from "../../../common/utils/Utils";
import TransactionStatusComponent from "../../../common/components/dashboard/TransactionStatusComponent";
import {TransferStatus} from "../../../../core/models/dashboard/transactions/transation";

function useOutsideAlerter(ref: any, setVisible: any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
          if (ref.current && !ref.current.contains(event.target)) {
                //alert("You clicked outside of me!");
                setVisible(false);
                //document.removeEventListener("mousedown", handleClickOutside);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setVisible]);
}

const FilterMenu: React.FC<any> = (props: any) => {
    const filterRef = useRef(null);
    const [isVisible, setVisible] = useState(false);
    const [selectedValues, setSelectedValues] = useState(new Array<any>());
    const [filters, setFilters] = useState(new Array<string>());

    const statusMap: Map<number, String> =  new Map<number, String>([
        [0, "Pending"],
        [1, "Initiated"],
        [2, "Processing"],
        [3, "Cancelled"],
        [4, "Settled"],
        [5, "Returned"]
    ]);

    useOutsideAlerter(filterRef, setVisible);

    const removeFilter = (e: string) => {
        const newSelectedValues = selectedValues.filter(x => e !== x);
        setSelectedValues(newSelectedValues);
        setFilters(newSelectedValues);
        props.callback(newSelectedValues);
    }

    const menu = (

        // <div ref={filterRef}>
            <Col  ref={filterRef} style={{padding: '10px'}} onMouseLeave={()=> setVisible(false)}>
                <div>
                    <Checkbox.Group
                        value={selectedValues}
                        onChange={(a) => {
                            setSelectedValues(a);
                        }}
                    >
                        <Checkbox value='0'>
                            <TransactionStatusComponent transferStatus={TransferStatus.Pending}/>
                        </Checkbox>
                        <br/>
                        <Checkbox value='1'>
                            <TransactionStatusComponent transferStatus={TransferStatus.Initiated}/>
                        </Checkbox>
                        <br/>
                        <Checkbox value='2'>
                            <TransactionStatusComponent transferStatus={TransferStatus.Processing}/>

                        </Checkbox>
                        <br/>
                        <Checkbox value='4'>
                            <TransactionStatusComponent transferStatus={TransferStatus.Completed}/>
                        </Checkbox>
                        <br/>
                        <Checkbox value='3'>
                            <TransactionStatusComponent transferStatus={TransferStatus.Cancelled}/>
                        </Checkbox>
                        <br/>
                        <Checkbox value='5'>
                            <TransactionStatusComponent transferStatus={TransferStatus.Returned}/>
                        </Checkbox>
                    </Checkbox.Group>
                </div>
                <div style={{marginTop: '5px', borderTop: '1px solid #D1D1D1', paddingTop: '5px'}}>
                    <div style={{display: 'inline-flex', justifyContent: 'space-between', width: '100%'}}>
                        <button
                            className="rounded-button rounded-button-stealth"
                            onClick={() => {
                                props.callback([]);
                                setSelectedValues([]);
                                setFilters([])
                                setVisible(false);
                            }}>
                            Reset
                        </button>
                        <button className="rounded-button rounded-button-filled" onClick={() => {
                            props.callback(selectedValues);
                            setFilters(selectedValues);
                            setVisible(false);
                        }}>
                            OK
                        </button>
                    </div>
                </div>
            </Col>
    );

    return (<>
        <Row
             className="home-row-item  home-row-item-margin-left">
            <Dropdown overlay={menu} trigger={["click"]} visible={isVisible}>
                <Button className="rounded-button" onClick={() => setVisible(prev => !prev)}>
                    <FilterOutlined/> Status
                </Button>
            </Dropdown>
        </Row>
        {filters.length > 0 ?
            <Row className="home-row-item home-row-item-margin-left home-row-item-margin-top">
                {
                    filters.map((e) => (
                        <div className="rounded-button rounded-button-exit margin-right margin-top"
                             onClick={() => removeFilter(e)}>
                            {statusMap.get(parseInt(e))} <CloseCircleOutlined/>
                        </div>
                    ))
                }
                &nbsp;&nbsp;&nbsp;
            </Row> : null
        }
    </>);
}

const TransactionCard : React.FC<any> = (props: any) => {
    const [filters, setFilters] = useState(new Array<any>())

    const getCountOrAmount = (type: TransactionTypeEnum) => {
        const transactions = props.data?.transactions?.filter((x: TransactionTypeWrapper) => x.type === type)[0];
        if(!transactions) return 0;
        if(props.isAmount){
            if(filters.length === 0) return transactions.amount;
            return transactions.entries?.filter((x: TransactionModel) => filters.some((y: TransactionStatusEnum) => String(y) === String(x.status)))
                .reduce((total: number, x: TransactionModel) => total + x.amount, 0);
        }
        if(filters.length === 0) return transactions.count;
        return transactions.entries?.filter((x: TransactionModel) => filters.some((y: TransactionStatusEnum) => String(y) === String(x.status)))
            .reduce((total: number, x: TransactionModel) => total + x.count, 0) ?? 0;
    }

    const getTotal = () => {
        if(filters.length === 0) return props.isAmount ? (props.data?.amount ?? 0) : (props.data?.count ?? 0);

        return props.data?.transactions?.map( (e: TransactionTypeWrapper) => getCountOrAmount(e.type))
            .reduce((total: number, x: number) => total + x, 0) ?? 0;

    }

    const deposit = getCountOrAmount(TransactionTypeEnum.Deposit);
    const withdrawal = getCountOrAmount(TransactionTypeEnum.Withdrawal);
    const purchase = getCountOrAmount(TransactionTypeEnum.MarketplacePurchase);
    const cashback = getCountOrAmount(TransactionTypeEnum.Cashback);
    const referral = getCountOrAmount(TransactionTypeEnum.Referral);
    const directDeposit = getCountOrAmount(TransactionTypeEnum.DirectDeposit);

    const getChartStats = () => {
        return [
            { title: 'Deposit', value: deposit, color: TransactionStatisticsChartColor.Deposit },
            { title: 'MarketplacePurchase', value: purchase, color: TransactionStatisticsChartColor.MarketplacePurchase },
            { title: 'Withdrawal', value: withdrawal, color: TransactionStatisticsChartColor.Withdrawal },
            { title: 'Cashback', value: cashback, color: TransactionStatisticsChartColor.Cashback },
            { title: 'Referral', value: referral, color: TransactionStatisticsChartColor.Referral },
        ];
    }

    return (
        <>
            {props.data?.count  ?
                <Row className="row-tab-content">
                    <Row className="home-row-item home-row-item-margin-top-main">
                        <FilterMenu callback={(filters : any[]) => {
                            setFilters(filters)
                        }}/>
                    </Row>
                    <Row className="home-row-item vcenter home-row-item-margin-top">
                        <Col md={7}>
                            <Row className="home-row-item home-row-item-margin-left home-row-item-total">
                                <h6 className="title-item">Total {props.isAmount ? 'amount' : 'transactions'}</h6>
                                <h6 className="value-item-main">
                                    {props.isAmount ? `${CurrencyNumberFormat(getTotal())}` : getTotal()}
                                </h6>
                            </Row>
                        </Col>
                        <Col md={15}>
                            <Row className="home-row-item-simple home-row-item-right-block">
                                <PieChartComponent {...{ data: getChartStats(), className: "chart-content-transaction" }} />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="home-row-item home-row-item-margin-top">
                        <Row className="home-row-item">
                            <Col md={12} >
                                <Row className="home-row-item home-row-item-margin-top statistics">
                                    <StatisticsItemComponent {...{
                                        title: 'Deposit',
                                        value: props.isAmount ? `${CurrencyNumberFormat(deposit)}` : deposit,
                                        badgeColor: (TransactionStatisticsChartColor.Deposit)
                                    }}/>
                                </Row>
                                <Row className="home-row-item  home-row-item-margin-top statistics">
                                    <StatisticsItemComponent {...{
                                        title: 'Marketplace purchase',
                                        value: props.isAmount ? `${CurrencyNumberFormat(purchase)}` : purchase,
                                        badgeColor: (TransactionStatisticsChartColor.MarketplacePurchase)
                                    }}/>
                                </Row>
                                <Row className="home-row-item home-row-item-margin-top statistics">
                                    <StatisticsItemComponent {...{
                                        title: 'Referral',
                                        value: props.isAmount ? `${CurrencyNumberFormat(referral)}` : referral,
                                        badgeColor: (TransactionStatisticsChartColor.Referral)
                                    }}/>
                                </Row>
                            </Col>
                            <Col md={12} >
                                <Row className="home-row-item home-row-item-margin-top statistics">
                                    <StatisticsItemComponent {...{
                                        title: 'Withdrawal',
                                        value: props.isAmount ? `${CurrencyNumberFormat(withdrawal)}` : withdrawal,
                                        badgeColor: (TransactionStatisticsChartColor.Withdrawal)
                                    }}/>
                                </Row>
                                <Row className="home-row-item home-row-item-margin-top statistics">
                                    <StatisticsItemComponent {...{
                                        title: 'Cashback',
                                        value: props.isAmount ? `${CurrencyNumberFormat(cashback)}` : cashback,
                                        badgeColor: (TransactionStatisticsChartColor.Cashback)
                                    }}/>
                                </Row>

                                <Row className="home-row-item home-row-item-margin-top statistics">
                                    <StatisticsItemComponent {...{
                                        title: 'Direct deposit',
                                        value: props.isAmount ? `${CurrencyNumberFormat(directDeposit)}` : directDeposit,
                                        badgeColor: (TransactionStatisticsChartColor.DirectDeposit)
                                    }}/>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                </Row> :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No transaction data found." />
            }

        </>
    )
}

export default TransactionCard;
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Row, Space, Table, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FeeEarningsReportModel, FeeOrdersReportModel } from "../../../../../core/models/dashboard/marketplace/marketplaceCashbackRequestModel";
import SearchComponent from "../../../../common/components/dataInput/components/SearchComponent";
import { maxSearchLength } from "../../../../common/utils/Utils";
const { TabPane } = Tabs;

export interface OrderReportsProps {
    feeOrdersRecords: Array<FeeOrdersReportModel>
    feeEarningRecords: Array<FeeEarningsReportModel>
}

const AmazonOrderReportComponent: React.FC<OrderReportsProps> = (props: OrderReportsProps) => {

    const { feeOrdersRecords, feeEarningRecords } = props;
    const [data, setData] = useState<Array<any>>([]);
    const [pageSize, setPageSize] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const [selectedRowKeys, setSelectedRowKey] = useState<Array<string>>([]);
    const [selectedRowItem, setSelectedRowItem] = useState<Array<FeeOrdersReportModel>>([]);
    const [activeTab, setActiveTab] = useState<string>("fee-orders-uploaded");

    useEffect(() => {
        if (feeOrdersRecords.length > 0)
            setData(feeOrdersRecords);
        else
            setData(feeEarningRecords);


    }, [feeOrdersRecords, feeEarningRecords])

    const rowSelection = {

        onChange: (selectedKeys: any, selectedRows: Array<any>) => {

            let _selectedKey: Array<string> = [];
            _selectedKey = selectedRows.map(itm => itm.index);

            setSelectedRowKey(_selectedKey);
            setSelectedRowItem(selectedRows);

        },
        getCheckboxProps: (record: any) => ({}),
    };

    const feeEarningsColumns = [
        {
            title: 'Category',
            dataIndex: 'category',
            width: 270,
            key: 'orderReport-1',
            render: (category: any, row: FeeEarningsReportModel) => (
                <>
                    {row.category}
                </>
            ),
        },
        {
            title: 'Product name',
            dataIndex: 'name',
            key: 'orderReport-2',
            render: (name: any, row: FeeEarningsReportModel) => (
                <>
                    {row.name}
                </>
            )
        },
        {
            title: 'ASIN',
            dataIndex: 'asin',
            key: 'orderReport-3',
            render: (asin: any, row: FeeEarningsReportModel) => (
                <>
                    {row.asin}
                </>
            )
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'orderReport-5',
            render: (price: any, row: FeeEarningsReportModel) => (
                <>
                    {row.price}
                </>
            )
        },
        {
            title: 'Date Shipped',
            dataIndex: 'dateShipped',
            key: 'orderReport-6',
            width: 170,

            render: (date: any, row: FeeEarningsReportModel) => (
                <>
                    <> {row.dateShipped ? `${moment(new Date(row.dateShipped)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Returns',
            dataIndex: 'returns',
            key: 'orderReport-6',
            width: 170,

            render: (date: any, row: FeeEarningsReportModel) => (
                <>
                    {row.returns}
                </>
            )
        },
        {
            title: 'Revenue($)',
            dataIndex: 'revenue',
            key: 'orderReport-6',
            width: 170,

            render: (date: any, row: FeeEarningsReportModel) => (
                <>
                    {row.revenue}
                </>
            )
        }
    ]


    const feeOrdersColumns = [
        {
            title: 'Category',
            dataIndex: 'category',
            width: 270,
            key: 'orderReport-1',
            render: (category: any, row: FeeOrdersReportModel) => (
                <>
                    {row.category}
                </>
            ),
        },
        {
            title: 'Product name',
            dataIndex: 'name',
            key: 'orderReport-2',
            render: (name: any, row: FeeOrdersReportModel) => (
                <>
                    {row.name}
                </>
            )
        },
        {
            title: 'ASIN',
            dataIndex: 'asin',
            key: 'orderReport-3',
            render: (asin: any, row: FeeOrdersReportModel) => (
                <>
                    {row.asin}
                </>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'orderReport-4',
            render: (quantity: any, row: FeeOrdersReportModel) => (
                <>
                    {row.quantity}
                </>
            )
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'orderReport-5',
            render: (price: any, row: FeeOrdersReportModel) => (
                <>
                    {row.price}
                </>
            )
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'orderReport-6',
            width: 170,

            render: (date: any, row: FeeOrdersReportModel) => (
                <>
                    <> {row.date ? `${moment(new Date(row.date)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        }
    ]

    const onSearchSubmit = (searchValue: string) => {

        if (feeEarningRecords.length ==  0) {
            let data = feeOrdersRecords.filter((item: FeeOrdersReportModel) => {
                if (item.name.toLowerCase().includes(searchValue.toLowerCase()))
                    return item
            })
            setData(data);
        } else {
            let data = feeEarningRecords.filter((item: FeeEarningsReportModel) => {
                if (item.name.toLowerCase().includes(searchValue.toLowerCase()))
                    return item
            })
            setData(data);
        }
    }

    return (
        <>

            {data.length > 0 ?
                <Tabs defaultActiveKey={activeTab}>

                    <TabPane
                        tab="Amazon report uploaded"
                        key="fee-orders-uploaded">


                        <Row className="amazon-report-item-search">
                            <span className="searchbox">
                                <SearchComponent {...{ className: "search-input", maxLength: maxSearchLength, size: "large", name: "searchValue", placeholder: "Search something...", onSearch: onSearchSubmit }} />
                            </span>
                        </Row>

                        <Table
                            pagination={{ pageSize: pageSize, showSizeChanger: true }}
                            rowKey="index"
                            className="table-amazon"
                            dataSource={data}
                            columns={
                                feeEarningRecords.length > 0 ?
                                feeEarningsColumns as any : feeOrdersColumns

                            }
                            scroll={{ x: 1200 }}
                        />
                    </TabPane>
                </Tabs>
                : <></>
            }
        </>
    )
}
export default AmazonOrderReportComponent;


import { updateBankAccountDetailsInStoreReducer } from "./updateBankAccountDetailsInStoreReducer";
import { updateDeviceIdsWithMoreUsersInStoreReducer } from "./updateDeviceIdsWithMoreUsersInStoreReducer";
import { updateListOfUsersByDeviceInStoreReducer } from "./updateListOfUsersByDeviceInStoreReducer";
import { updatePaymentOptionDetailsInStoreReducer } from "./updatePaymentOptionDetailsInStoreReducer";
import { updateUserDetailsInStoreReducer } from "./updateUserDetailsInStoreReducer";
import { updateUserListInStoreReducer } from "./updateUserListInStoreReducer";

const userReducer = {
    updateDeviceIdsWithMoreUsersInStoreReducer,
    updateBankAccountDetailsInStoreReducer,
    updatePaymentOptionDetailsInStoreReducer,
    updateUserDetailsInStoreReducer,
    updateUserListInStoreReducer,
    updateListOfUsersByDeviceInStoreReducer
}

export default userReducer;
import { AffiliatesInStoreAction, GET_AFFILIATES_ERROR, GET_AFFILIATES_SUCCESS } from "../../../../actions/dashboard/marketplace/affiliates/updateAffiliatesInStore";
import { AffiliateModel } from "../../../../models/dashboard/marketplace/affiliatesModel";

export const updateAffiliatesInStoreReducer =  (state: Array<AffiliateModel> = [], action: AffiliatesInStoreAction) => {
    switch (action.type) {
        case GET_AFFILIATES_SUCCESS:
            return [ ...state, ...action.affiliates ]
        case GET_AFFILIATES_ERROR:
            return [];
        default:
            return [...state];
    }
}
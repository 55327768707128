import {CashbackRequestModel} from "../../../../models/dashboard/marketplace/marketplaceCashbackRequestModel";
import {
    GET_MARKETPLACECASHBACK_REQUEST_ERROR,
    GET_MARKETPLACECASHBACK_REQUEST_SUCCESS, MarketplaceCashbackRequestInStoreAction
} from "../../../../actions/dashboard/marketplace/amazon/updateCashbackRequestInStore";

export const updateCashbackRequestInStoreReducer = (state: Array<CashbackRequestModel>| []= [], action: MarketplaceCashbackRequestInStoreAction) => {
    switch (action.type) {
        case GET_MARKETPLACECASHBACK_REQUEST_SUCCESS:
            return action?.marketplaceCashbackRequest 
        case GET_MARKETPLACECASHBACK_REQUEST_ERROR:
            return [];
        default:
            return [...state];
    }
}

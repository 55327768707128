import { AnnualRapidWithdrawInStoreAction, GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_ERROR, GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_SUCCESS } from "../../../actions/dashboard/moveMoney/updateAnnualRapidWithdrawInStoreAction";
import { AnnualRapidWithdrawModel } from "../../../models/dashboard/withdraw/withdraw";


export const udpateAnnualRapidWithdrawInStoreReducer = (state: Array<AnnualRapidWithdrawModel>  | [] = [], 
    action: AnnualRapidWithdrawInStoreAction) => {
    switch (action.type) {
        case GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_SUCCESS:
            return [...action.annualRapidWithdraw ]
        case GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_ERROR:
            return [...state];
        default:
            return [];
    }
}
import { ArrowRightOutlined, BankOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import React from "react";
import { TransferStatus } from "../../../../core/models/dashboard/transactions/transation";
import { s3DestinationMoveMoneyImgUrl, s3FromMoveMoneyImgUrl } from "../../utils/Utils";

export interface TransactionStatusComponentProps {
    transferStatus: TransferStatus
}

const TransactionStatusComponent: React.FC<TransactionStatusComponentProps> = props => {
    return <>
        {props.transferStatus == TransferStatus.Completed &&
            <span>   <Badge color={"#3AB100"} text={"Settled"} /></span>}
        {props.transferStatus == TransferStatus.Pending &&
            <span>   <Badge color={"#A6A6A6"} text={"Pending"} /></span>}
        {props.transferStatus == TransferStatus.Initiated &&
            <span>   <Badge color={"#13C2C2"} text={"Initiated"} /></span>}
        {props.transferStatus == TransferStatus.Cancelled &&
            <span>   <Badge color={"#F5222D"} text={"Cancelled"} /></span>}
        {props.transferStatus == TransferStatus.Processing &&
            <span>   <Badge color={"#FA8C16"} text={"Processing"} /></span>}
        {props.transferStatus == TransferStatus.Returned &&
            <span>   <Badge color={"#520339"} text={"Returned"} /></span>}

    </>
}
export default TransactionStatusComponent;
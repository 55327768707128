const transactionMarketplaceResource= {
    success: 'Affiliate purchases loaded successfully!',
    error: 'Error loading affiliate purchases!',
    transactions:'Transactions',
    export:{
        fileName:'marketplace-purchases.csv',
        title:'Marketplace Purchases',
        name:'Download CSV'
    },
    tableColumns: {
        purchaseDate: 'Purchase date',
        user: 'User',
        lastModifiedAt: 'Last updated',
        status: 'Purchase status',
        cashBackStatus: 'Cashback status',
        totalCashBackPercentage: 'Total cashback percentage',
        guacCashBackPercentage: 'Guac cashback percentage',
        userCashBackPercentage: 'User cashback percentage',
        totalCommission: 'Total cashback amount',
        guacCashBack: 'Guac cashback amount',
        userCashBack: 'User cashback amount',
        amount: 'Purchase amount'
    }
}

export default transactionMarketplaceResource;
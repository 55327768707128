
import { Input } from "antd";
import React, { ChangeEventHandler } from "react";

const { Search } = Input;

export interface SearchProps {
    maxLength: number;
    name: string;
    className: string;
    placeholder: string;
    size: 'small' | 'middle' | 'large' | undefined;
    onSearch?: (value: string, event?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>) => void;

}

class SearchComponent extends React.Component<SearchProps> {

    render() {
        const { ...props } = this.props;
        return (
            <Search {...props}  />
        )
    }
}

export default SearchComponent;
import { boolean, number } from "yup";

// list of transactions
export interface Transactions {
    transferId: string;
    coreProTransferId: number;
    transferStatus: TransferStatus;
    amount: number;
    transferType: TransferType;
    createdAt: Date;
    userId:string;
    depositActivity : Array<Deposits>;
    withdrawalActivity : WithdrawalTransactionModel;
    referralTransaction: ReferralTransaction;
    directDepositActivity: Array<DirectDeposits>;
}

export interface DirectDeposits{
    amount: number;
    percentage: number;
    goalName: string;
    iconUrl: string;
    bankName: string;
    type: DirectDepositType;
}

export enum DirectDepositType{
    Distribution,
    Single
}

export interface TransactionsModel {
    items: Transactions[];
    totalItemCount: number;
    pageCount: number;
    pageSize: number;
    pageNumber: number;
}

export interface TransactionsSearch {
    searchValue: string;
    pageNumber: number;
    pageSize: number;
    orderDescending: boolean;
    userId: string;
    sortColumn:string;
    transferStatus:number | null
}

export enum TransferType
{
    Withdraw, 
    Deposit,
    Cashback,
    ReferralReward,
    DirectDeposit,
    MarketplacePurchase
}

export enum TransferStatus
{
    Pending,
    Initiated,
    Completed,
    Cancelled,
    Processing,
    Returned, 
    Received
}
///////////////////////////////////////////////////////



/// withdrawal
export interface WithdrawalTransactionModel {
    moneyTransferActivity: MoneyTransferActivity;
    cashBackActivity: CashBackTransactionDetailsModel;
}

export interface MarketplaceDetails {
    marketplaceId: string;
    name: string;
    iconUrl: string;
    cashBackPercentage: number;
    guacCashBackPercentage: number;
}

export interface MoneyTransferActivity {
    type: MoveMoneyType;
    from: string;
    nameOfFrom: string;
    destination: string;
    nameOfDestination: string;
    status: MoveMoneyStatus;
    amount: number;
    marketplaceDetails: MarketplaceDetails;
}
///////////////////////////////////////////////////////////////////

    export interface CashbackGoalCollected {
        goalId: string;
        goalName: string;
        cashBack:number;
    }

    export interface CashbackMarketplaceCollected {
        marketplaceId: string;
        marketplaceName: string;
        cashback: number;
        trackedOn: Date;
    }

    export interface CashBackTransactionDetailsModel {
        collectionType: CollectionType;
        totalCashBack: number;
        goals: CashbackGoalCollected[];
        marketplace: CashbackMarketplaceCollected[];
    }





export interface TransactionWithdrawalSearch {
    userId: string;
    transferId: string;
}

/////////////////////////////////////////////////////////////////
// deposit
export interface DepositModel {
    items: Deposits[];
    totalItemCount: number;
    pageCount: number;
    pageSize: number;
    pageNumber: number;
}

export interface GoalSaving {
    id: string;
    name: string;
    percentage?: number;

}

export interface SavingsDistributionActivity {
    id: string;
    goalSaving: GoalSaving;
    amountSaved: number;
}

export interface Deposits {
    id:string;
    transactionId: string;
    savingPercentage: number;
    name: string;
    merchantName: string;
    amount: number;
    status: SavingStatus;
    totalAmountSaved: number;
    createdAt: Date;
    savingsDistributionActivities: SavingsDistributionActivity[];
}

export interface TransactionsDepositSearch {
    searchValue: string;
    pageNumber: number;
    pageSize: number;
    orderDescending: boolean;
    transferId: string;
    userId: string;
}

// referrals 
export interface ReferralTransaction {
    userId: string;
    fullName: string;
    refferedBy: string;
    imageUrl: string;
    goalId: string;
    goalName: string;
    ownerType: number;
    amount: number;
    isCollected: boolean;
    expirationDate: Date;
    collectedDate: Date;
    createdAt: Date;
}


export enum SavingStatus
{
    Pending,
    Completed,
    Cancelled
}


export enum MoveMoneyType
{
    GoalToGoal, 
    Withdrawal,
    MarketplacePurchase
}

export enum MoveMoneyStatus
{
    Completed, 
    Pending, 
    Voided
}

export enum CollectionType
{
    Distributed, 
    SingleGoal
}


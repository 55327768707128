import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../commons/reduxStore";
import { MarketplacePurchaseModel, MarketplacePurchaseSearch } from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import getMarketplacePurchase from "../../../services/dashboard/marketplacePurchase/getMarketplacePurchasesByUser";
import {executeAction} from "../../../commons/baseAction";


export const GET_MARKETPLACEPURCHASE_SUCCESS ="GET_MARKETPLACEPURCHASE_SUCCESS";
export const GET_MARKETPLACEPURCHASE_ERROR ="GET_MARKETPLACEPURCHASE_error";

export interface MarketplacePurchaseByUserInStoreAction {
    type: typeof GET_MARKETPLACEPURCHASE_SUCCESS | typeof GET_MARKETPLACEPURCHASE_ERROR,
    userMarketplacePurchase: MarketplacePurchaseModel | null,
    searchParams: MarketplacePurchaseSearch
}

export const updateMarketplacePurchaseByUserInStoreAction =
(marketplacePurchase: MarketplacePurchaseModel | null,
 searchParams: MarketplacePurchaseSearch,
 onSuccess: Function,
 onFailure: Function)
: ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(()=> getMarketplacePurchase(searchParams)),
        (res: any) =>{
            dispatch({
                type: GET_MARKETPLACEPURCHASE_SUCCESS,
                userMarketplacePurchase: res
            } as MarketplacePurchaseByUserInStoreAction);
            onSuccess();
        },
        (_: any) => {
            dispatch({
                type: GET_MARKETPLACEPURCHASE_ERROR,
                userMarketplacePurchase: marketplacePurchase
            } as MarketplacePurchaseByUserInStoreAction);
            onFailure();
        }
    );
}
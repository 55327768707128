import { Empty, Row, Switch, Table, Input, Badge, Spin, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetGoals, Goals, GoalStatus } from '../../../core/models/dashboard/goals/goal';
import { AppState } from "../../../core/commons/reduxStore";
import { connect } from "react-redux";
import { updateUserGoalsListInStoreAction } from "../../../core/actions/dashboard/goals/updateGoalListInStore";
import { Image } from 'antd';
import { CheckCircleOutlined, PauseCircleOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import '../../../styles/layout/dashboard/goals/goalsTable.less';
import moment from 'moment';
import ProgressBarComponent from '../../common/components/dataDisplay/ProgressBar';
import { CurrencyNumberFormat } from '../../common/utils/Utils';
import ButtonComponent from '../../common/components/dataDisplay/Button';
import goalsServices from '../../../core/services/dashboard/goals/index';
import { commandRequest } from '../../common/requestUtils/requestUtils';
const { Search } = Input;
const { syncGoalServices } = goalsServices;

export interface GoalUserComponentProps {
    userId: string,
    userGoals: Goals,
    updateUserGoalsListInStoreAction: (userGoals: Goals | null, searchParams: GetGoals, onSuccess: Function, onFailure: Function) => void;
}

const initSearchParams: GetGoals = {
    pageNumber: 1,
    pageSize: 5,
    searchValue: "",
    deletedGoals: false,
    orderDescending: false,
    userId: "",
    sortColumn: "name",

};

const UserGoalsTableComponent: React.FC<GoalUserComponentProps> = props => {
    const maxSearchLength = 50;
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [pausedAllGoals, setPausedAllGoals] = useState(false);

    useEffect(() => {
        if (props.userGoals && props.userGoals?.items) {
            setPausedAllGoals(!props?.userGoals?.items.some(item => item.goalStatus == GoalStatus.Active || item.goalStatus == GoalStatus.Completed))
            console.log(pausedAllGoals)

        }
    }, [props.userGoals])

    const onGoalLoadSuccess = () => {

        setLoading(false);
    }

    const onGoalLoadFailure = () => {
        message.error('Goals failed to load!');
        setLoading(false);
    }

    const onGoalSyncBalance = () => {
        try {
            setLoading(true);

            commandRequest(() => goalsServices.syncGoalServices(props.userId))
                .then(() => {
                    getUserGoals();
                }, (reason: any) => {
                    setErrorMessage(reason);
                    setLoading(false);

                })
                .catch((error) => {
                    setLoading(false);

                })
        }
        catch (error) {
        }
    }

    const onPauseAllGoals = () => {
        try {
            setLoading(true);

            commandRequest(() => goalsServices.pauseAllGoals(props.userId))
                .then(() => {
                    getUserGoals();
                }, (reason: any) => {
                    setErrorMessage(reason);
                    setLoading(false);

                })
                .catch((error) => {
                    setLoading(false);

                })
        }
        catch (error) {
        }
    }

    const getUserGoals = () => {
        initSearchParams.userId = props.userId;
        props.updateUserGoalsListInStoreAction(null, initSearchParams, onGoalLoadSuccess, onGoalLoadFailure);
        setLoading(true);
    }

    if (isMounting) {
        getUserGoals();
        setMounting(false)
    }

    const showHideDeletedGoals = (val: boolean) => {
        initSearchParams.searchValue = "";
        initSearchParams.deletedGoals = val;
        initSearchParams.pageNumber = 1;
        props.updateUserGoalsListInStoreAction(null, initSearchParams, onGoalLoadSuccess, onGoalLoadFailure);
        setLoading(true);
    }

    const onSearchSubmit = (searchValue: string) => {
        initSearchParams.searchValue = searchValue;
        initSearchParams.pageNumber = 1;
        props.updateUserGoalsListInStoreAction(null, initSearchParams, onGoalLoadSuccess, onGoalLoadFailure);
        setLoading(true);
    }

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;
                props.updateUserGoalsListInStoreAction(null, initSearchParams, onGoalLoadSuccess, onGoalLoadFailure);

            } else {
                initSearchParams.sortColumn = 'name';
                initSearchParams.orderDescending = true;
            }
        }

        if (pagination) {
            if (props.userGoals?.pageNumber != pagination.current) {
                initSearchParams.pageNumber = pagination.current;
                props.updateUserGoalsListInStoreAction(null, initSearchParams, onGoalLoadSuccess, onGoalLoadFailure);
            }
        }
        ;

    }

    const columns = [
        {
            title: 'Goal',
            width: 170,
            dataIndex: 'name',
            key: 'name',
            fixed: true,
            sorter: true,
            render: (name: string, row: any) => (
                <>
                    <span className="goal-name-row">
                        <Image className="goal-image" src={row.iconUrl} />
                        <span className="goal-with-progress">
                            <span className="goal-name">{name}</span>
                            {row.goalSavingPercentage > 0 ? (
                                <ProgressBarComponent {...{ percentage: row.goalSavingPercentage }}></ProgressBarComponent>) : ("")}
                        </span>
                    </span>
                </>
            )
        },
        {
            title: 'Available balance',
            width: 220,
            dataIndex: 'goalAvailableBalance',
            key: 'goalAvailableBalance',
            sorter: true,
            render: (goalAvailableBalance: number) => (
                <> {goalAvailableBalance ? CurrencyNumberFormat(goalAvailableBalance) : ("")} </>
            )
        },
        {
            title: 'Target amount',
            dataIndex: 'goalAmountLimit',
            key: 'goalAmountLimit',
            sorter: true,
            width: 220,
            render: (goalAmountLimit: number) => (
                <> {goalAmountLimit ? CurrencyNumberFormat(goalAmountLimit) : ("")} </>
            )
        },
        {
            title: 'Target date',
            dataIndex: 'deadline',
            key: 'deadline',
            sorter: true,
            width: 170,
            render: (deadline: Date) => (
                <> {deadline ? `${moment(new Date(deadline)).format("MM-DD-YYYY")}` : ("")} </>
            )
        },
        {
            title: 'Savings distribution',
            dataIndex: 'percentage',
            key: 'percentage',
            sorter: true,
            width: 220,
            render: (percentage: number) => (
                <> {percentage > 0 ? `${percentage}%` : ("")} </>
            )
        },
        {
            title: 'Status',
            dataIndex: 'goalStatus',
            sorter: true,
            key: 'goalStatus',
            width: 170,
            render: (goalStatus: number, row: any) => (
                <>
                    {!row.isDeleted && (goalStatus == GoalStatus.Active || goalStatus == GoalStatus.Completed) &&
                        <Badge color={"#52C41A"} text={"Active"} />
                    }
                    {!row.isDeleted && goalStatus == GoalStatus.Paused &&
                        <Badge color={"#BFBFBF"} text={"Paused"} />
                    }
                    {row.isDeleted &&
                        <Badge color={"#F5222D"} text={"Deleted"} />
                    }
                </>
            )
        }
    ]

    return <>
        <Spin spinning={isLoading}>
            <Row className="search-box-goals">
                <Search size="large"
                    name="searchValue"
                    className="search-input-goals"
                    maxLength={maxSearchLength}
                    placeholder="Search something..."
                    onSearch={onSearchSubmit} />
                <span className="button-row">
                    <span className="switch-deleted-goals">
                        <span className="title"> Show deleted goals</span>
                        <Switch checked={initSearchParams.deletedGoals}
                            onChange={showHideDeletedGoals} />
                    </span>
                    <ButtonComponent
                        {...{
                            name: "Sync Balance", icon: <SyncOutlined />,
                            shape: 'round', className: "sync-button", onClick: onGoalSyncBalance
                        }}></ButtonComponent>
                    <Popconfirm
                        title="Are you sure to pause all goals?"
                        onConfirm={onPauseAllGoals}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <ButtonComponent
                            {...{
                                name: "Pause goals",
                                icon: <PauseCircleOutlined />,
                                disabled: pausedAllGoals,
                                shape: 'round', className: "sync-button", onClick: () => { }
                            }}></ButtonComponent>
                    </Popconfirm>
                </span>
            </Row>

            <Table
                locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No results for ths search. Try again..." />
                }}
                pagination={{
                    total: props.userGoals?.totalItemCount, current: props.userGoals?.pageNumber,
                    pageSize: props.userGoals?.pageSize,
                    showSizeChanger: false,
                    //onChange: (onPageChange)
                }}
                scroll={{ x: 1200 }}
                onChange={(onChangeEvent)}
                columns={columns} dataSource={props.userGoals?.items} />
        </Spin>
    </>
}
const mapStateToProps = ({ userGoals }: AppState) =>
({
    userGoals: userGoals
});

export default connect(mapStateToProps, {
    updateUserGoalsListInStoreAction
})(UserGoalsTableComponent);

function setErrorMessage(reason: any) {
    throw new Error('Function not implemented.');
}

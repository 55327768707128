const newTransactionResource = {
    userRequiredValidator: 'User is required',
    purchaseDateRequiredValidator: 'Purchase date is required',
    lockingDateRequiredValidator: 'Purchase date is required',
    orderNumberRequiredValidator:'Transaction number is required',
    amountRequiredValidator:'Transaction amount is required',
    cancelButton :'Cancel',
    saveButton:'Save',
    headerTitle:'New Transaction',
    user:'User',
    userPlaceholder:'Search for user here by email or full name',
    transactionNumber :'Transaction number',
    transactionDate :'Transaction date',
    lockingDate :'Locking date',

    amount:'Transaction amount',
    userCashback:'User cashback',
    guacCashback: 'Guac cashback',
    optional:'optional', 
    transactionNumberPopover:'The identification number associated with the sale order.',
    amountValuePopover:'The sales amount of the action.',
    transactionDatePopover:'The date and time of the event that originated the sale as informed by the advertiser ',
    lockingDatePopover:'The date when the action locks. After this date, the action cannot be modified by advertiser (brand), and it becomes payable to the partner (media partner).',

}
export default newTransactionResource;

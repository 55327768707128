import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import {
    CashbackRequestsCheckedPaginated, GetPaginatedData
} from "../../../../models/dashboard/marketplace/marketplaceCashbackRequestModel";
import {AppState} from "../../../../commons/reduxStore";
import {executeAction} from "../../../../commons/baseAction";
import {queryRequest} from "../../../../../app/common/requestUtils/requestUtils";
import getRejectedCashbackRequest from "../../../../services/dashboard/marketplace/amazon/getRejectedCashbackRequest";



export const GET_REJECTED_CASHBACK_REQUEST_SUCCESS ="GET_REJECTED_CASHBACK_REQUEST_SUCCESS";
export const GET_REJECTED_CASHBACK_REQUEST_ERROR ="GET_REJECTED_CASHBACK_REQUEST_ERROR";

export interface RejectedCashbackRequestInStoreAction {
    type: typeof GET_REJECTED_CASHBACK_REQUEST_SUCCESS | typeof GET_REJECTED_CASHBACK_REQUEST_ERROR,
    rejectedCashbackRequest: CashbackRequestsCheckedPaginated | null
}

export const updateRejectedCashbackRequestInStoreAction =
    (rejectedCashbackRequest: CashbackRequestsCheckedPaginated | null,
     model: GetPaginatedData
    )
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        executeAction(
            queryRequest(()=> getRejectedCashbackRequest(model)),
            (res: any) =>{
                dispatch({
                    type: GET_REJECTED_CASHBACK_REQUEST_SUCCESS,
                    rejectedCashbackRequest: res
                } as RejectedCashbackRequestInStoreAction);
            },
            (_: any) => {
                dispatch({
                    type: GET_REJECTED_CASHBACK_REQUEST_ERROR,
                    rejectedCashbackRequest: rejectedCashbackRequest
                } as RejectedCashbackRequestInStoreAction);
            }
        );

    }
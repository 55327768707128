import {updateAffiliateDetailsInStoreAction} from "./affiliates/updateAffiliateDetailsInStore";
import {updateAffiliatesInStoreAction} from "./affiliates/updateAffiliatesInStore";
import {updateMarketplacesInStoreAction} from "./affiliates/updateMarketplacesInStore";
import {updateAllMarketplacesInStoreAction} from "./affiliates/updateAllMarketplaceInStore";
import {updateMarketplaceCategoriesInStoreAction} from "./categories/updateMarketplaceCategoriesInStore";
import {updateMarketplacePlatformInStoreAction} from "./platform/updateMarketplacePlatformInStore";
import {updateSuggestedAffiliatesInStoreAction} from "./suggestedAffiliates/updateSuggestedAffiliatesInStore";
import {updateAffiliatePurchaseStatisticsInStoreAction} from "./affiliates/updateAffiliateStatisticsInStore";

const marketplaceAction = {
    updateAffiliateDetailsInStoreAction,
    updateAffiliatesInStoreAction,
    updateMarketplacesInStoreAction,
    updateMarketplaceCategoriesInStoreAction,
    updateMarketplacePlatformInStoreAction,
    updateSuggestedAffiliatesInStoreAction,
    updateAffiliatePurchaseStatisticsInStoreAction,
    updateAllMarketplacesInStoreAction
}

export default marketplaceAction;
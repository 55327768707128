import {ThunkAction} from "redux-thunk";
import {AppState} from "../../../commons/reduxStore";
import {Action} from "redux";
import {executeAction} from "../../../commons/baseAction";
import {queryRequest} from "../../../../app/common/requestUtils/requestUtils";
import getCashbackStatistics from "../../../services/dashboard/marketplacePurchase/getCashbackStatistics";
import {CashbackStatisticsModel} from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";

export const GET_CASHBACK_STATISTICS_SUCCESS = "GET_CASHBACK_STATISTICS_SUCCESS";
export const GET_CASHBACK_STATISTICS_FAILURE = "GET_CASHBACK_STATISTICS_FAILURE";


export interface CashbackStatisticsInStoreAction {
    type: typeof GET_CASHBACK_STATISTICS_SUCCESS | typeof GET_CASHBACK_STATISTICS_FAILURE
    cashbackStatistics : CashbackStatisticsModel | null
}

export const getCashbackStatisticsInStoreAction = (cashbackStatistics: CashbackStatisticsModel | null) : ThunkAction<void, AppState, unknown, Action> => dispatch =>
{
    executeAction(
        queryRequest(() => getCashbackStatistics()),
        (res: any) => {
            dispatch({
                type: GET_CASHBACK_STATISTICS_SUCCESS,
                cashbackStatistics: res
            } as CashbackStatisticsInStoreAction)
        },
        (_: any) => {
            dispatch({
                type: GET_CASHBACK_STATISTICS_FAILURE,
                cashbackStatistics: cashbackStatistics
            } as CashbackStatisticsInStoreAction)
        }
    )
}
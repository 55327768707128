
export interface NotificationTemplateModel {
    items: Array<NotificationTemplateData>,
    totalItemCount: number,
    pageCount: number,
    pageSize: number,
    pageNumber: number
}

export interface NotificationTemplateData{
    id: string | null,
    title: string,
    tag: string,
    description: string,
    createdAt: Date | null,
    lastModifiedAt: Date | null,
    type: NotificationTemplateType
}

export enum NotificationTemplateType {
    Information,
    Warning,
    ConnectionWarning,
    Error,
    TechnicalError,
    Achievement
}

export interface UserSuggestionModel{
    fullName: string,
    email: string,
    id: string
}

export interface BaseResponse {
    success: boolean,
    message: string
}

export interface NotificationPushModel{
    templateIds: Array<string>,
    userIds: Array<string>,
    userStatus:Array<number>
}

export interface BaseRequestParams{
    pageNumber: number,
    pageSize: number
}

export interface AdminNotificationSendJobModel {
    items: Array<AdminNotificationSendJobData>,
    totalItemCount: number,
    pageCount: number,
    pageSize: number,
    pageNumber: number
}

export interface AdminNotificationSendJobData{
    id: string,
    parentJobId: string | null,
    tag: string,
    jobType: JobType,
    jobStatus: JobStatus,
    jobRole: JobRole,
    totalJobs: number,
    failedSubJobs: number,
    processingSubJobs: number,
    succeededSubJobs: number
}

export enum JobType{
    AdminNotifications
}

export enum JobStatus{
    Enqueued,
    Scheduled,
    Awaiting,
    Processing,
    Failed,
    Succeeded,
    Deleted
}

export enum JobRole{
    Root,
    Node,
    Leaf
}

export interface AdminNotificationExecutionLogModel {
    items: Array<AdminNotificationExecutionLogData>,
    totalItemCount: number,
    pageCount: number,
    pageSize: number,
    pageNumber: number
}

export interface AdminNotificationExecutionLogData{
    id: string,
    pushSent: boolean,
    recipientEmail: string,
    recipientFullName: string,
    templateTag: string,
    templateTitle: string,
    templateDescription: string,
    createdAt: Date | null
}

export interface GetNotificationJobModel{
    pageNumber: number,
    pageSize: number,
    parentId: string | null
}

export interface GetNotificationLogsModel{
    pageNumber: number,
    pageSize: number,
    isSub: boolean,
    jobId: string
}
import getTransactionDepositByTransfer from "./getTransactionDepositByTransfer";
import getTransactionsByUser from "./getTransactionsByUserId";
import getTransactionWithdrawalByTransfer from "./getTransactionWithdrawalByTransfer";

const transactions = {
     getTransactionsByUser,
     getTransactionDepositByTransfer,    
     getTransactionWithdrawalByTransfer
}

export default transactions;
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Row, Spin, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { DeviceUsersModel, GetListOfDevices, PaginatedDeciceIdsUsersModel, UserData, UserModel, UserStatus } from "../../../../core/models/dashboard/users/user";
import TagStatusComponent from "../../../common/components/dashboard/UserTagStatusComponent";
import BreadcrumbComponent, { BreadcrumbProps } from "../../../common/components/dataDisplay/BreadcrumbComponent";
import "../../../../styles/layout/dashboard/users/users.less";
import "../../../../styles/layout/dashboard/users/devices.less";
import { updateDeviceIdsWithMoreUsersInStoreAction } from "../../../../core/actions/dashboard/users/updateDeviceIdsWithMoreUsersInStore";
import { updateListOfUsersByDeviceInStoreAction } from "../../../../core/actions/dashboard/users/updateListOfUsersByDeviceInStore";
import SearchComponent from "../../../common/components/dataInput/components/SearchComponent";
import { maxSearchLength } from "../../../common/utils/Utils";
import { useHistory } from "react-router-dom";

export interface DeviceUsersComponentProps {
    deviceIdsUsers: PaginatedDeciceIdsUsersModel | null,
    usersDevice: Array<UserData> | [],
    updateDeviceIdsWithMoreUsersInStoreAction(searchParams: GetListOfDevices): void;
    updateListOfUsersByDeviceInStoreAction(deviceId: string): void;
}

const searchParams: GetListOfDevices = {
    pageNumber: 1,
    pageSize: 10,
    orderDescending: true,
    searchFor: ""
}

const initialDetails: PaginatedDeciceIdsUsersModel = {
    items: [],
    totalItemCount: 1,
    pageCount: 1,
    pageSize: 10,
    pageNumber: 1
};

const DeviceUsersComponent: React.FC<DeviceUsersComponentProps> = (props: DeviceUsersComponentProps) => {
  
    const history = useHistory();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isMounting, setMounting] = useState<boolean>(true);
    const [isSubLoading, setSubLoading] = useState<boolean>(true);
    const [keyExpanded, setKeyExpanded] = useState<Array<string>>([]);
    const [deviceIds, setDeviceIds] = useState<Array<DeviceUsersModel>>([]);
    const [deviceIdsUsers, setDeviceIdsUsers] = useState<PaginatedDeciceIdsUsersModel>(initialDetails);

    useEffect(() => {
        if (props.deviceIdsUsers) {
            if (!deviceIdsUsers) {
                setDeviceIdsUsers(props.deviceIdsUsers)
            }

            if (!Object.is(deviceIdsUsers, props.deviceIdsUsers)) {
                setLoading(false);
                setDeviceIdsUsers(props.deviceIdsUsers)
            }
        }
    });

    if (isMounting) {
        setMounting(false);
        props.updateDeviceIdsWithMoreUsersInStoreAction(searchParams);
    }

    const currentBreadCrumbs: BreadcrumbProps = {
        title: "Dashboard",
        className: "breadcrumb",
        description: "",
        breadcrumbs: [
            {
                title: "Devices",
                path: ""
            }
        ]
    };

    const columns = [
        { title: 'DeviceId', dataIndex: 'deviceId', key: 'deviceId', sorter: true, render: (deviceId: any, row: DeviceUsersModel) => (<>{row.deviceId} </>) },
        { title: 'Total users', dataIndex: 'count', key: 'xount', sorter: true, render: (count: any, row: DeviceUsersModel) => (<> {row.count} </>) }
    ];

    const onClickUser = (id: any) => {
        history.push(`/admin/users/user-details/ ${id}`);
    }

    const expandedColumns = [
        {
            title: 'Full name',
            width: 150,
            dataIndex: 'fullName',
            key: 'fullName',
            fixed: true,
            sorter: true,
            render: (fullName: string, row: UserData) => (
                <> {fullName ?
                    (fullName.length > 30 ?
                        (<>
                            <Button onClick={() => { onClickUser(row.id) }} type="link">
                                <span className="table-item">
                                    <span className="alphabetic-item">{fullName.toString().trimStart().trimEnd().substring(0, 1)}</span>
                                    <span className="item-concat"> {fullName.substring(0, 30)}...<br />
                                        <span className="email-item">{row.email}</span>
                                    </span>
                                </span>
                            </Button>

                        </>)
                        : (<>
                            <Button onClick={() => { onClickUser(row.id) }} type="link">
                                <span className="table-item">
                                    <span className="alphabetic-item">{row.fullName.substring(0, 1)}</span>
                                    <span className="item-concat"> {fullName}<br />
                                        <span className="email-item">{row.email}</span>
                                    </span>
                                </span>
                            </Button>
                        </>)
                    ) :
                    ""}
                </>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            filterMultiple: false,
            sorter: true,

            filters: [
                {
                    text: <><TagStatusComponent {...{ status: UserStatus.Active }} /></>,
                    value: UserStatus.Active,
                },
                {
                    text: <><TagStatusComponent {...{ status: UserStatus.BankConnected }} /></>,
                    value: UserStatus.BankConnected,
                },
                {
                    text: <><TagStatusComponent {...{ status: UserStatus.BankDisconnected }} /></>,
                    value: UserStatus.BankDisconnected,
                }, {
                    text: <><TagStatusComponent {...{ status: UserStatus.DeletedRequest }} /></>,
                    value: UserStatus.DeletedRequest,
                }, {
                    text: <><TagStatusComponent {...{ status: UserStatus.NotVerified }} /></>,
                    value: UserStatus.NotVerified,
                },
                {
                    text: <><TagStatusComponent {...{ status: UserStatus.OnBoarding }} /></>,
                    value: UserStatus.OnBoarding,
                },
            ],
            render: (status: UserStatus) => (
                <>
                    <TagStatusComponent {...{ status: status }} />
                </>
            ),
        },
        {
            title: 'Device',
            dataIndex: 'deviceType',
            key: 'deviceType',
            width: 100,
            sorter: true,
            render: (deviceType: string) => (
                <> {deviceType ? (
                    deviceType.length > 20 ?
                        `${deviceType?.substring(0, 20)}...` : deviceType) : ""} </>
            )
        },
        {
            title: 'App version',
            dataIndex: 'appVersion',
            key: 'appVersion',
            width: 100,
            sorter: true,
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            sorter: true,
            render: (createdAt: Date) => (<> {createdAt ? `${moment(new Date(createdAt)).format("MM-DD-YYYY")}` : null} </>),
        },
        {
            title: 'Last login',
            dataIndex: 'lastModifiedAt',
            key: 'lastModifiedAt',
            width: 120,
            sorter: true,
            render: (lastModifiedAt: Date) => (
                <> {lastModifiedAt ? `${moment(new Date(lastModifiedAt)).format("MM-DD-YYYY")}` : null} </>
            )
        },
        {
            title: '',
            key: 'action',
            fixed: true,
            dataIndex: 'id',
            width: 100,
            render: (id: string) =>
                <>
                    <Button onClick={() => { onClickUser(id) }} type="link">
                        <ArrowRightOutlined />
                    </Button>
                </>
        },
    ];

    const expandedRowContent = (record: DeviceUsersModel) => {

        return (
            <Spin spinning={isSubLoading}>
                <Table
                    columns={expandedColumns}
                    rowKey="id"
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`No result`} /> }}
                    pagination={{
                        pageSize: 5,
                        showSizeChanger: false,
                    }}
                    sortDirections={['ascend', 'descend', 'ascend']}
                    dataSource={(isSubLoading) ? [] : props.usersDevice}
                />
            </Spin>
        )
    }

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter) {
            if (sorter.order || sorter.undefined) {
                searchParams.orderDescending = (sorter.order == 'ascend') ? false : true;
            } else {
                searchParams.orderDescending = false;
            }
        }

        if (pagination) {
            if (props.deviceIdsUsers?.pageNumber != pagination.current) {
                searchParams.pageNumber = pagination.current;
            }
        };
        searchParams.searchFor = "";
        props.updateDeviceIdsWithMoreUsersInStoreAction(searchParams);
        setLoading(true);
    }

    const onTableRowExpand = (expanded: boolean, record: DeviceUsersModel) => {

        var keys: Array<string> = [];
        if (expanded) {
            setSubLoading(true);
            keys.push(record.deviceId);
            props.updateListOfUsersByDeviceInStoreAction(record.deviceId);
            setTimeout(() => {
                setSubLoading(false);
            }, 1500);
        }
        setKeyExpanded(keys);
    }

    const onSearchSubmit = (searchValue: string) => {
        if (searchValue.length > 0) {
            searchParams.searchFor = searchValue;
            props.updateDeviceIdsWithMoreUsersInStoreAction(searchParams);
            setLoading(true);
        }
        else {
            searchParams.searchFor = "";
            props.updateDeviceIdsWithMoreUsersInStoreAction(searchParams);
            setLoading(true);
        }
    }
    
    const onSearchChange = (event: any) => {
        if (!event.target.value || event.target.value.length === 0) {
            searchParams.searchFor = "";
            props.updateDeviceIdsWithMoreUsersInStoreAction(searchParams);
            setLoading(true);
        }
    }

    return (
        <Content>
            <BreadcrumbComponent key="device-breadcrumb" {...currentBreadCrumbs} />

            <Spin spinning={isLoading}>
                <Row className="home-content">
                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item" bordered={false}>
                                <Row className="search-row">
                                    <span className="searchbox" >
                                        <SearchComponent {...{ className: "search-input", maxLength: maxSearchLength, size: "large", name: "searchValue", placeholder: "Search for a user by email or full name...", onChange: onSearchChange, onSearch: onSearchSubmit }} />
                                    </span>
                                </Row>
                                <Row className="table-row">
                                    <Table
                                        className="table-item"
                                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
                                        pagination={{
                                            total: deviceIdsUsers.totalItemCount, current: deviceIdsUsers.pageNumber,
                                            pageSize: deviceIdsUsers.pageSize,
                                            showSizeChanger: false,
                                        }}

                                        rowKey="deviceId"
                                        onExpand={onTableRowExpand}
                                        expandedRowKeys={keyExpanded}
                                        expandable={{
                                            expandedRowRender: (record: DeviceUsersModel) => <>
                                                {expandedRowContent(record)}
                                            </>,
                                            rowExpandable: (record: DeviceUsersModel) => (record.count > 0),
                                        }}
                                        onChange={(onChangeEvent)}
                                        columns={columns} dataSource={deviceIdsUsers.items} />
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Row>
            </Spin>
        </Content>
    )
}
const mapStateToProps = ({ deviceIdsUsers, usersDevice }: AppState) =>
({
    deviceIdsUsers: deviceIdsUsers,
    usersDevice: usersDevice
});

export default connect(mapStateToProps, {
    updateDeviceIdsWithMoreUsersInStoreAction,
    updateListOfUsersByDeviceInStoreAction
})(DeviceUsersComponent);

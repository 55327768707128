import { Collapse, Empty, Table } from "antd"
import React from "react"
import amazonCommsionData from "../../../../common/resource/amazonCommsions";
import { AmazonCommisionsModel } from "../../../../../core/models/dashboard/marketplace/marketplaceCashbackRequestModel";
const { Panel } = Collapse;

const AmazonCommisions: React.FC<any> = (props: any) => {

    const columns = [
        {
            title: 'Product Category', dataIndex: 'productCategory',
            key: 'productCategory',
            render: (id: string, row: AmazonCommisionsModel) => (
                <>
                    {row.productCategory}
                </>
            )
        },
        {
            title: 'Commission', dataIndex: 'commission',
            key: 'commission',
            width: 100,
            render: (id: string, row: AmazonCommisionsModel) => (
                <>
                    {row.commission}
                </>
            )
        },
        {
            title: 'Special Condition', dataIndex: 'specialCondition',
            key: 'specialCondition',
            width: 100,
            render: (id: string, row: AmazonCommisionsModel) => (
                <>
                    {row.specialCondition}
                </>
            )
        },
    ]

    return (
        <>
            <Collapse className={"collapsed-panel"} defaultActiveKey={['1']} >
                <Panel header="Amazon associates commissions rate" key="1">
                <Table
                        locale={{
                            emptyText:
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                       description="No results for ths search. Try again..."/>
                        }}
                        sortDirections={['ascend', 'descend', 'ascend']}
                        scroll={{x: 750}}
                        columns={columns}
                        dataSource={amazonCommsionData.commsions}
                        rowKey="id"                       
                    />
                </Panel>
            </Collapse>
        </>
    )
}
export default AmazonCommisions
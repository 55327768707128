import { Badge } from "antd";
import React from "react";
import { PurchaseStatus } from "../../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";

export interface PurchaseStatusProps {
    purchaseStatus: PurchaseStatus
}


const PurchaseStatusComponent : React.FC<PurchaseStatusProps> = props => {
    return (
        <>
        {props.purchaseStatus == PurchaseStatus.Pending &&
            <Badge color={"#FA8C16"} text={"Pending"} />
        }
        {props.purchaseStatus == PurchaseStatus.Completed &&
            <Badge color={"#52C41A"} text={"Completed"} />
        }
        {props.purchaseStatus == PurchaseStatus.Reversed &&
            <Badge color={"#F5222D"} text={"Reversed"} />
        }
    </>
    )
} 
export default PurchaseStatusComponent;

import deleteMarketplace from "./deleteAffiliate";
import updateAffiliate from "./updateAffiliate";
import getMarketplaces from "./getMarketplaces";
import getMarketplaceStatistics from "./getAffiliateStatistics";
import addNewAffiliate from "./addNewAffiliate";
import getAffiliates from "./getAffiliates";
import getAllMarketplaces from "./getAllMarketplaces";

const affiliateServices = {
    getAffiliates,
    updateAffiliate,
    deleteMarketplace,
    getMarketplaceStatistics,
    getMarketplaces,
    addNewAffiliate,
    getAllMarketplaces
}

export default affiliateServices;
import { AllMarketplacesInStoreAction, GET_ALL_MARKETPLACES_ERROR, GET_ALL_MARKETPLACES_SUCCESS } from '../../../../actions/dashboard/marketplace/affiliates/updateAllMarketplaceInStore';
import { MarketplaceModel } from './../../../../models/dashboard/marketplace/affiliatesModel';

export const updateAllMarketplacesInStoreReducer = (state: Array<MarketplaceModel> | [], action:AllMarketplacesInStoreAction) => {
    switch(action.type) {
        case GET_ALL_MARKETPLACES_SUCCESS:
            return [...action.allMarketplaces] 
        case GET_ALL_MARKETPLACES_ERROR:
            return [...state];
            default:
                return [];
    }
}
import React from "react";
import { Breadcrumb, Row } from "antd";
import '../../../../styles/_base.less';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../routes';

export interface BreadcrumbChildItem {
    title: string;
    path: any;
}
export interface BreadcrumbProps {
    title: string;
    description: string;
    breadcrumbs: BreadcrumbChildItem[];
    className: string;
}

const BreadcrumbComponent: React.FC<BreadcrumbProps> = props => {
    let defualtStylesBreadCrumb = { margin: '3px 0', backgroundColor: "white", padding: '18px' };
    const history = useHistory();

    const navigateToPath = (path: string, event: React.MouseEvent): void => {
        event.preventDefault();
        history.push(path);
    }

    return (
        <>
            <Breadcrumb key={'breadcrumb'} style={defualtStylesBreadCrumb}>
                <Breadcrumb.Item key={0} href={props.breadcrumbs.length > 0 ? RoutePaths.HOME_PATH : ""} onClick={navigateToPath.bind(null, props.breadcrumbs.length > 0 ? RoutePaths.HOME_PATH : "")}>
                    <span className={props.breadcrumbs.length == 0 ? 'default-item' : ''}>Dashboard</span>
                </Breadcrumb.Item>

                {props.breadcrumbs.map((item: BreadcrumbChildItem, i: number) => (<>
                    <Breadcrumb.Item key={i + 1} href={item.path} onClick={navigateToPath.bind(null, item.path)}>
                        {item.title}
                    </Breadcrumb.Item>
                </>
                ))}
            </Breadcrumb>

            {props.breadcrumbs.length > 0 ?
                <Row key={'no-breadcrumb'} className="breadcrumb-subpage-title"> <span >{props.breadcrumbs[props.breadcrumbs.length -1].title}</span></Row>
                : ("")
            }

        </>
    );
};
export default BreadcrumbComponent;

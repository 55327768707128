import React, { useEffect, useState } from 'react';
import { Content } from "antd/lib/layout/layout";
import {Card, Col, Collapse, Empty, Row, Spin, Table, Tabs} from "antd";
import '../../../styles/_variables.less';
import 'antd/dist/antd.less';
import '../../../styles/layout/index.less'
import { DollarCircleOutlined, OrderedListOutlined, ShopOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Statistics } from '../../../core/models/dashboard/statistics';
import { AppState } from '../../../core/commons/reduxStore';
import { connect } from 'react-redux';
import { updateStatisticsInStoreAction } from "../../../core/actions/dashboard/updateStatisticsInStore";
import { DefualtPieChartColors, UserStatisticsChartColor } from '../../common/utils/ChartColors';
import PieChartComponent, { ChartData } from '../../common/components/dataDisplay/PieChart';
import StatisticsItemComponent from '../../common/components/dataDisplay/StatisticItem';
import { CurrencyNumberFormat } from '../../common/utils/Utils';
import BreadcrumbComponent, { BreadcrumbProps } from '../../common/components/dataDisplay/BreadcrumbComponent';
import TransactionCardWrapper from "./TransactionStatistics/TransactionCardWrapper";
import MarketplaceStatisticsWrapper from "./MarketplaceStatistics/MarketplaceStatisticsWrapper";

const { TabPane } = Tabs;

interface StatisticProps {
    updateStatisticsInStoreAction: (statistics: Statistics | null) => void;
}

const Home: React.FC<StatisticProps> = (props: any) => {
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(true);

    const getStatisticInfo = () => {
        props.updateStatisticsInStoreAction();
    }

    const currentBreadCrumbs: BreadcrumbProps = {
        title: "Dashboard",
        className: "breadcrumb",
        description: "",
        breadcrumbs: [ ]
    };

    useEffect(() => {
        if(props.statistics?.allAdminStatistics) {
            setLoading(false);
        }
    });

    if (isMounting) {
        setLoading(true);
        setMounting(false);
        getStatisticInfo();
    }

    const getUserTotalStatsPieChart = (): Array<ChartData> => {
        let userStatsData: Array<ChartData> = [];
        if (props.statistics?.allAdminStatistics.userStatistics) {
            userStatsData = userStatsData.concat([
                { title: 'Not Verified', value: props.statistics?.allAdminStatistics.userStatistics.usersNotVerified ?? 0, color: UserStatisticsChartColor.NotVerified },
                { title: 'Inactive', value: props.statistics?.allAdminStatistics.userStatistics.inActiveUsers ?? 0, color: UserStatisticsChartColor.BankDisconnected },
                { title: 'Onboarding', value: props.statistics?.allAdminStatistics.userStatistics.usersOnBoardingCompleted ?? 0, color: UserStatisticsChartColor.OnBoarding },
                { title: 'Active', value: props.statistics?.allAdminStatistics.userStatistics.activeUsers ?? 0, color: UserStatisticsChartColor.Active },
            ])
        }
        return userStatsData;
    }

    const getUserTodayStatsPieChart = (): Array<ChartData> => {
        let userStatsData: Array<ChartData> = [];
        if (props.statistics?.todayAdminStatistics.userStatistics) {
            userStatsData = userStatsData.concat([
                { title: 'Not Verified', value: props.statistics?.todayAdminStatistics.userStatistics.usersNotVerified ?? 0, color: UserStatisticsChartColor.NotVerified },
                { title: 'Inactive', value: props.statistics?.todayAdminStatistics.userStatistics.inActiveUsers ?? 0, color: UserStatisticsChartColor.BankDisconnected },
                { title: 'Onboarding', value: props.statistics?.todayAdminStatistics.userStatistics.usersOnBoardingCompleted ?? 0, color: UserStatisticsChartColor.OnBoarding },
                { title: 'Active', value: props.statistics?.todayAdminStatistics.userStatistics.activeUsers ?? 0, color: UserStatisticsChartColor.Active },
            ])
        }
        return userStatsData;
    }

    const getTotalGoalsStats = (): Array<ChartData> => {
        let userStatsData: Array<ChartData> = [];
        if (props.statistics?.goalStatistics) {
            userStatsData = userStatsData.concat([
                { title: 'Goals active', value: props.statistics?.goalStatistics?.totalActiveAndCompletedGoals ?? 0, color: DefualtPieChartColors.Primary },
                { title: 'Goals paused', value: props.statistics?.goalStatistics?.totalPausedGoals ?? 0, color: DefualtPieChartColors.Secondary }
            ])
        }
        return userStatsData;
    }

    return (
        <Content>
            <BreadcrumbComponent {...currentBreadCrumbs}/>
            <Spin spinning={isLoading}>
                <Row className="home-content">
                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item" bordered={false}>
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="All time" key="1">
                                        {
                                            props.statistics?.allAdminStatistics?.userStatistics?.totalUsers ?
                                                <Row className="row-tab-content home-row-item vcenter">
                                                    <Col md={10} className="col-divider-right">
                                                        <Row className="home-row-item center-flex">
                                                            <PieChartComponent {...{
                                                                data: getUserTotalStatsPieChart(),
                                                                className: "home-chart-content"
                                                            }} />
                                                        </Row>
                                                        <Row className="home-row-item home-row-item-total home-row-item-center">
                                                            <h6 className="title-item home-row-item home-row-item-center">Total
                                                                users</h6>
                                                            <h6 className="value-item-main value-item-center">{props.statistics?.allAdminStatistics.userStatistics.totalUsers}</h6>
                                                        </Row>
                                                    </Col>
                                                    <Col md={14} className="col-padding-left">
                                                        <Row className="row-tab-content home-row-item home-row-item-margin-top-main">
                                                            <Col md={12}>
                                                                <Row className="home-row-item home-row-item-margin-top statistics">
                                                                    <StatisticsItemComponent {...{
                                                                        title: 'Active',
                                                                        value: `${props.statistics?.allAdminStatistics.userStatistics.activeUsers}`,
                                                                        badgeColor: (UserStatisticsChartColor.Active),
                                                                        collapsible: null
                                                                    }}/>
                                                                </Row>
                                                            </Col>
                                                            <Col md={12}>

                                                                <Row className="home-row-item home-row-item-margin-top statistics">
                                                                    <StatisticsItemComponent {...{
                                                                        title: 'Not verified',
                                                                        value: `${props.statistics?.allAdminStatistics.userStatistics.usersNotVerified}`,
                                                                        badgeColor: (UserStatisticsChartColor.NotVerified),
                                                                        collapsible: null
                                                                    }}/>
                                                                </Row>

                                                                <Row className="home-row-item home-row-item-margin-top statistics">
                                                                    <StatisticsItemComponent {...{
                                                                        title: 'Onboarding',
                                                                        value: `${props.statistics?.allAdminStatistics.userStatistics.usersOnBoardingCompleted}`,
                                                                        badgeColor: (UserStatisticsChartColor.OnBoarding),
                                                                        collapsible: null
                                                                    }}/>
                                                                </Row>
                                                                <Row className="home-row-item home-row-item-margin-top statistics">
                                                                    <StatisticsItemComponent {...{
                                                                        title: 'Passive',
                                                                        value: `${props.statistics?.allAdminStatistics.userStatistics.inActiveUsers}`,
                                                                        badgeColor: (UserStatisticsChartColor.BankDisconnected),
                                                                        collapsible: `${props.statistics?.allAdminStatistics.userStatistics.usersWithBankDisconnected}`
                                                                    }}/>

                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row> :
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                       description="No user data found."/>
                                        }

                                    </TabPane>
                                    <TabPane tab="Today" key="2">
{/*                                        {
                                            props.statistics?.todayAdminStatistics?.userStatistics?.totalUsers ?
                                                <Row className="row-tab-content">
                                                    <Col md={10} className="col-divider-right">
                                                        <Row className="home-row-item center-flex">
                                                            <PieChartComponent {...{
                                                                data: getUserTodayStatsPieChart(),
                                                                className: "home-chart-content"
                                                            }} />
                                                        </Row>
                                                        <Row className="home-row-item home-row-item-total home-row-item-center">
                                                            <h6 className="title-item home-row-item home-row-item-center">Total
                                                                users</h6>
                                                            <h6 className="value-item-main value-item-center">{props.statistics?.todayAdminStatistics.userStatistics.totalUsers}</h6>
                                                        </Row>
                                                    </Col>
                                                    <Col md={14} className="col-padding-left">
                                                        <Row className="row-tab-content home-row-item home-row-item-margin-top-main">
                                                            <Col md={12}>

                                                                <Row className="home-row-item home-row-item-margin-top statistics">
                                                                    <StatisticsItemComponent {...{
                                                                        title: 'Active',
                                                                        value: `${props.statistics?.todayAdminStatistics.userStatistics.activeUsers}`,
                                                                        badgeColor: (UserStatisticsChartColor.Active),
                                                                        collapsible: null
                                                                    }}/>
                                                                </Row>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row className="home-row-item home-row-item-margin-top statistics">
                                                                    <StatisticsItemComponent {...{
                                                                        title: 'Not verified',
                                                                        value: `${props.statistics?.todayAdminStatistics.userStatistics.usersNotVerified}`,
                                                                        badgeColor: (UserStatisticsChartColor.NotVerified),
                                                                        collapsible: null
                                                                    }}/>
                                                                </Row>
                                                                <Row className="home-row-item home-row-item-margin-top statistics">
                                                                    <StatisticsItemComponent {...{
                                                                        title: 'Onboarding',
                                                                        value: `${props.statistics?.todayAdminStatistics.userStatistics.usersOnBoardingCompleted}`,
                                                                        badgeColor: (UserStatisticsChartColor.OnBoarding),
                                                                        collapsible: null
                                                                    }}/>
                                                                </Row>
                                                                <Row className="home-row-item home-row-item-margin-top statistics">
                                                                    <StatisticsItemComponent {...{
                                                                        title: 'Passive',
                                                                        value: `${props.statistics?.todayAdminStatistics.userStatistics.inActiveUsers}`,
                                                                        badgeColor: (UserStatisticsChartColor.BankDisconnected),
                                                                        collapsible: `${props.statistics?.todayAdminStatistics.userStatistics.usersWithBankDisconnected}`
                                                                    }}/>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row> :
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                       description="No user data found."/>
                                        }*/}

                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                               description="Coming soon..."/>
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>

                    </Row>

                    <MarketplaceStatisticsWrapper/>

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <TransactionCardWrapper
                            allTime={props.statistics?.allAdminStatistics?.transactionStatistics}
                            today={props.statistics?.todayAdminStatistics?.transactionStatistics}
                        />
                        <TransactionCardWrapper
                            allTime={props.statistics?.allAdminStatistics?.transactionStatistics}
                            today={props.statistics?.todayAdminStatistics?.transactionStatistics}
                            isAmount={true}
                        />
                    </Row>

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item" bordered={false}>
                                {props.statistics?.goalStatistics?.totalGoals ?
                                    <Row className="row-tab-content home-row-item vcenter">
                                        <Row className="home-row-item vcenter">
                                            <Col md={5}>
                                                <Row className="home-row-item home-row-item-margin-left home-row-item-total">
                                                    <h6 className="title-item home-row-item-center">Total goals</h6>
                                                    <h6 className="value-item-main">
                                                        {props.statistics?.goalStatistics?.totalGoals ?? 0}
                                                    </h6>
                                                </Row>
                                            </Col>
                                            <Col md={6} className="col-divider-right col-padding-right">
                                                <Row className="home-row-item-simple home-row-item-right-block">
                                                    <PieChartComponent {...{
                                                        data: getTotalGoalsStats(),
                                                        className: "chart-content-transaction"
                                                    }} />
                                                </Row>
                                            </Col>
                                            <Col md={7} className="col-padding-left">
                                                <Row className="home-row-item vcenter statistics" style={{marginRight: '25px'}}>
                                                    <StatisticsItemComponent {...{
                                                        title: 'Paused goals',
                                                        value: `${props.statistics?.goalStatistics?.totalPausedGoals ?? 0}`,
                                                        badgeColor: (DefualtPieChartColors.Secondary)
                                                    }}/>
                                                </Row>
                                            </Col>
                                            <Col md={5}>
                                                <Row className="home-row-item vcenter statistics">
                                                    <StatisticsItemComponent {...{
                                                        title: 'Active & Completed goals',
                                                        value: `${props.statistics?.goalStatistics?.totalActiveAndCompletedGoals ?? 0}`,
                                                        badgeColor: (DefualtPieChartColors.Primary)
                                                    }}/>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row> :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No goal data found."/>
                                }
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item inline" bordered={false}>
                                {props.statistics?.marketplaceStatistics?.totalAffiliates ?
                                    <Row className=" statistics">
                                        <div className="centered-column">
                                            <ShopOutlined className="icon-styles circle-icon"/>
                                        </div>
                                        <div className="stats-details margin-left">
                                            <h6 className="title-item">Total affiliates</h6>
                                            <span className="value-item inline-block">{props.statistics?.marketplaceStatistics.totalAffiliates}</span>
                                        </div>
                                    </Row> :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No affiliates found."/>
                                }
                            </Card>
                        </Col>
                        <Col className="card-column" span={24}>
                            <Card className="card-item inline" bordered={false}>
                                {props.statistics?.marketplaceStatistics?.totalMarketplacePurchases ?
                                    <Row className=" statistics ">
                                        <div className="centered-column">
                                            <ShoppingOutlined className="icon-styles circle-icon"/>
                                        </div>
                                        <div className="stats-details margin-left">
                                            <h6 className="title-item">Total marketplace purchases</h6>
                                            <span className="value-item">{props.statistics?.marketplaceStatistics?.totalMarketplacePurchases}</span>
                                        </div>
                                    </Row> :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                           description="No marketplace purchases found."/>
                                }
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item inline" bordered={false}>
                                {props.statistics?.marketplaceStatistics?.totalMarketplacePurchases ?
                                    <Row className=" statistics">
                                        <div className="centered-column">
                                            <OrderedListOutlined className="icon-styles circle-icon"/>
                                        </div>
                                        <div className="stats-details margin-left">
                                            <h6 className="title-item">Total purchases amount</h6>
                                            <span
                                                className="value-item">{CurrencyNumberFormat(props.statistics?.marketplaceStatistics?.totalPurchaseAmount)}</span>
                                        </div>
                                    </Row> :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                           description="No marketplace purchases found."/>
                                }

                            </Card>
                        </Col>
                        <Col className="card-column" span={24}>
                            <Card className="card-item inline" bordered={false}>
                                {props.statistics?.marketplaceStatistics?.totalCashBackAmount ?
                                    <Row className="statistics">
                                        <>
                                            <div className="centered-column">
                                                <DollarCircleOutlined className="icon-styles circle-icon"/>
                                            </div>
                                            <div className="stats-details margin-left">
                                                <h6 className="title-item">Total cashback</h6>
                                                <span
                                                    className="value-item inline-block">{CurrencyNumberFormat(props.statistics?.marketplaceStatistics?.totalCashBackAmount)}</span>
                                            </div>
                                        </>
                                    </Row> :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                           description="No cashback data found."/>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Row>
            </Spin>
        </Content>);

};
const mapStateToProps = ({ statistics }: AppState) =>
({
    statistics: statistics
});
export default connect(mapStateToProps, {
    updateStatisticsInStoreAction
})(Home);



import { adminHttpClient } from "../../commons/adminHttpClinet";


export interface User {
    id:string,
    fullName: string, 
    email: string,
    phone: string, 
    access: number
}

const getUserProfile = (): Promise<any> => {
  return adminHttpClient.get("/api/Admin/AdminUsers/getAdminProfile");
};

export default getUserProfile;

import { CheckOutlined, CloseOutlined, DeleteFilled, LinkOutlined, PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Row, Upload, Select, Divider, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { MarketplaceCategoriesModel } from "../../../../../core/models/dashboard/marketplace/marketplaceCategoryModel";
import { MarketplacePlatformModel } from "../../../../../core/models/dashboard/marketplace/marketplaceModels";
import "../../../../../styles/layout/dashboard/marketplace/affiliateDetails/editAffiliate.less"
import { ErrorMessage, FieldArray, FieldArrayRenderProps, useFormik } from "formik";
import * as Yup from "yup";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import ImgCrop from "antd-img-crop";
import {  AffiliateStatus, OtherPercentageCommissionModel } from "../../../../../core/models/dashboard/marketplace/affiliatesModel";
import { FileResponse } from "../../../../../core/models/files/fileModel";
import { commandRequest } from "../../../../common/requestUtils/requestUtils";
import services from "../../../../../core/services";
import AlertComponent from "../../../../common/components/dataDisplay/Alert";
import resource from "../../../../common/resource/resource";
import { acceptFileUpload, getUploadChangeFormData, handleKeypress, maxInputLength, s3Url } from "../../../../common/utils/Utils";
import { PercentageCommission } from "../../../../../core/models/dashboard/marketplace/affiliateDetails";
import { connect } from "react-redux";
import { AppState } from "../../../../../core/commons/reduxStore";
import { EditAffiliateModel } from "../../../../../core/models/dashboard/marketplace/editAffiliateModel";
import  marketplaceAction from "../../../../../core/actions/dashboard/marketplace/index"
import editAffiliateResource from "./Resource";

const { marketplace, uploadIcon } = services;
const {updateMarketplaceCategoriesInStoreAction,updateMarketplacePlatformInStoreAction } = marketplaceAction;
const { TextArea } = Input;
const { Option } = Select;

export interface EditNewAffiliateProps {
    isVisible: boolean;
    changeVisibility: (isVisible: boolean) => void;
}

interface PromotionPercentage {
    promotionName: string;
    cashBackPercentage: number | null;
    guacCashBackPercentage: number | null;
}

interface ErrorPromotionPercentage {
    promotionName: string | null;
    cashBackPercentage: string | null;
    guacCashBackPercentage: string | null;
}

interface EditAffiliateValues {
    id: string;
    name: string;
    iconUrl: string;
    accessUrl: string;
    description: string;
    secondAccessUrl: string;
    platformId: string;
    categoryId: string;
    status: AffiliateStatus
    percentages: Array<PromotionPercentage>;
}

let initialValues: EditAffiliateValues = {
    id: "",
    name: "",
    iconUrl: "",
    accessUrl: "",
    description: "",
    secondAccessUrl: "",
    platformId: "",
    categoryId: "",
    status: 0,
    percentages: [] as Array<PromotionPercentage>
}

const formValidator = () =>
    Yup.object({
        name: Yup.string().max(50, editAffiliateResource.validation.nameMaxCharacters).required(editAffiliateResource.validation.nameRequired),
        iconUrl: Yup.string().required(editAffiliateResource.validation.iconUrl),
        accessUrl: Yup.string().url(editAffiliateResource.validation.accessUrlValid).required(editAffiliateResource.validation.accessUrlRequired),
        secondAccessUrl: Yup.string().url(editAffiliateResource.validation.secondAccessUrlValid).nullable().optional(),
        platformId: Yup.string().required(editAffiliateResource.validation.platformRequired),
        categoryId: Yup.string().required(editAffiliateResource.validation.categoryRequired),
        percentages: Yup.array().of(
            Yup.object().shape({
                promotionName: Yup.string().notRequired(),
                cashBackPercentage: Yup.number().notRequired(),//.moreThan(0, 'Cashback percentage must be greater than 0')
                //.required("CashBack percentage is required"),
                guacCashBackPercentage: Yup.number().notRequired()//.moreThan(0, 'Cashback percentage must be greater than 0')
                //.required("Guac Cashback percentage is required"),

                // promotionName: Yup.string().required(editAffiliateResource.validation.promotionNameRequired),
                // cashBackPercentage: Yup.number().moreThan(0, editAffiliateResource.validation.cashbackPercentageValidation).required(editAffiliateResource.validation.cashbackPercentageRequired),
                // guacCashBackPercentage: Yup.number().moreThan(0, editAffiliateResource.validation.guacCashBackPercentageValidation).required(editAffiliateResource.validation.guacCashBackPercentageRequired),
            })
        )
    });

const EditAffiliateComponent: React.FC<EditNewAffiliateProps> = (props: any) => {

    const [promotions, setPromotions] = useState<Array<number>>([])
    const [uploadedFile, setUploadedFile] = useState<FormData | null>();
    const [imageUrl, setImageUrl] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [percentageErrors, setPercentageErrors] = useState<Array<ErrorPromotionPercentage>>([])
    const [acceptedFiles, setAcceptedFiles] = useState<Array<string>>([]);
    const [status, setStatus] = useState<boolean>(false);
    const [isAmazonSelected, setIfAmazonIsSelected] = useState<boolean>(false)

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img as any);
    }

    useEffect(() => {
        formik.values.percentages = [];
        formik.setValues({ ...formik.values });
        bindDetailsValue();
        setAcceptedFiles(acceptFileUpload.split(','));
        if(!props.affiliateCategory) {
            getAffiliateCategories();
        }
        if(!props.marketplacePlatform) {
            getMarketplacePlatform();
        }
    }, [promotions])

    const getAffiliateCategories = () => {
        props.updateMarketplaceCategoriesInStoreAction([]);
    }

    const getMarketplacePlatform = () => {
        props.updateMarketplacePlatformInStoreAction([]);
    }

    const bindDetailsValue = () => {
        initialValues.id = props.affiliateDetails.id;
        initialValues.name = props.affiliateDetails.name;
        initialValues.iconUrl = props.affiliateDetails.iconUrl;
        initialValues.accessUrl = props.affiliateDetails.accessUrl;
        initialValues.secondAccessUrl = props.affiliateDetails.secondAccessUrl;
        initialValues.status = props.affiliateDetails.status;
        initialValues.categoryId = props.affiliateDetails.category.id;
        initialValues.platformId = (props.affiliateDetails.platform) ? props.affiliateDetails.platform.id : null;

        if (initialValues.status == AffiliateStatus.Active)
            setStatus(true);
        else
            setStatus(false);

        setImageUrl(`${s3Url}${initialValues.iconUrl}`);

        initPromotionsForm();
    }

    const checkIfAmazonCategoryIsSelected = ()  => {
        var categories: Array<MarketplaceCategoriesModel>=[];
            props.affiliateCategory.map((item: MarketplaceCategoriesModel) => {
                categories.push(item)
            });

            if(categories.find(f=> f.id.match(formik.values.categoryId))?.name.trim().toLowerCase().match('amazon')) {
               setIfAmazonIsSelected(true);   
               formik.values.percentages[0].cashBackPercentage =0;
               formik.values.percentages[0].guacCashBackPercentage =0;
               formik.values.percentages[0].promotionName ='Default Promotion';
            } else {
                setIfAmazonIsSelected(false);
            }
    }

    const onStatusChanged = (checked: boolean) => {
        setStatus(checked)
    }

    const onEditAffiliateSubmit = () => {
        if (uploadedFile) {
            commandRequest(() => uploadIcon(uploadedFile))
                .then((res: FileResponse) => {
                    onEditAffiliate(res);
                }, (reason: any) => {
                    setErrorMessage(reason);
                }).catch((_error: any) => {
                })
        } else {
            onEditAffiliate();
        }
    }

    const onEditAffiliate = (uploadedIcon?: FileResponse) => {
        let _promotions: Array<OtherPercentageCommissionModel> = [];

        formik.values.percentages.forEach((item) => {
            _promotions.push({
                cashBackPercentage: item.cashBackPercentage ?? 0,
                guacCashBackPercentage: item.guacCashBackPercentage ?? 0,
                promotionName: item.promotionName ??  editAffiliateResource.defaultPromotionName
            })
        })

        const editAffiliate: EditAffiliateModel = {
            id: initialValues.id,
            name: formik.values.name,
            iconUrl: uploadedFile ? `${uploadedIcon?.fileName}` : `${initialValues.iconUrl}`,
            accessUrl: formik.values.accessUrl,
            secondAccessUrl: formik.values.secondAccessUrl,
            categoryId: formik.values.categoryId,
            platformId: formik.values.platformId,
            promotions: _promotions,
            status: (status) ? AffiliateStatus.Active : AffiliateStatus.Paused
        }

        if (editAffiliate) {
            try {
                commandRequest(() => marketplace.updateAffiliate(editAffiliate))
                    .then(() => {
                        onClose();
                    }, (reason: any) => {
                        setErrorMessage(reason)
                    })
                    .catch((error) => {
                    })
            }
            catch (error) {
                console.log("error: ", error)
            }
        }
    }

    const onUploadChange = (info: UploadChangeParam) => {
        if (info) {
            if (info.file && acceptedFiles.length > 0 && acceptedFiles.includes(info.file.type)) {
                let formData = getUploadChangeFormData(info);
                if (formData) {
                    formik.values.iconUrl = info.file.fileName ? info.file.fileName : "uploaded";
                    formik.setValues({ ...formik.values });
                }
                setUploadedFile(formData);
                getBase64(info.fileList.find(f => f.uid == info.file.uid)?.originFileObj as any, (imageUrl: any) => {
                    setImageUrl(imageUrl);
                });
            }
        }
    }

    const onBeforeCropImage = (file: File, fileList: File[]): boolean => {
        if (file && acceptedFiles.length > 0 && acceptedFiles.includes(file.type)) {
            return true;
        }
        return false;
    }

    const onPreview = async (info: UploadFile<any>) => {
        if (info && acceptedFiles.length > 0 && acceptedFiles.includes(info.type)) {
            let src = info?.url;
            if (!src) {
                src = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(info.originFileObj as any);
                    reader.onload = () => resolve(reader.result as any);
                });
            }
        }
    };

    const onClose = () => {
        formik.setValues({
            id: "'",
            name: "",
            iconUrl: "",
            accessUrl: "",
            description: "",
            secondAccessUrl: "",
            platformId: "",
            status: 0,
            categoryId: "",
            percentages: []
        });
        props.changeVisibility(props.isVisible);
    };

    const onPlatformSelected = (item: string) => {
        formik.values.platformId = item;
        formik.setValues({ ...formik.values });
    }

    const onCategorySelected = (item: any) => {
        formik.values.categoryId = item;
        formik.setValues({ ...formik.values });
        checkIfAmazonCategoryIsSelected();

    }

    const initPromotionsForm = () => {
        props.affiliateDetails.percentageCommissions.forEach((item: PercentageCommission) => {
            let newPromotion: PromotionPercentage = {
                cashBackPercentage: item.cashBackPercentage * 100,
                guacCashBackPercentage: item.guacCashBackPercentage * 100,
                promotionName: item.promotionName ? item.promotionName : editAffiliateResource.defaultPromotionName
            };

            formik.values.percentages.push(newPromotion);
            formik.setValues({ ...formik.values });
        });
    }

    const addNewPromotionForm = () => {
        let newPromotion: PromotionPercentage = {
            cashBackPercentage: null,
            guacCashBackPercentage: null,
            promotionName: editAffiliateResource.defaultPromotionName
        };
        formik.values.percentages.push(newPromotion);
        formik.setValues({ ...formik.values });
    }

    const removePromotionForm = (index: number) => {
        formik.values.percentages.splice(index, 1);
        formik.setValues({ ...formik.values });
        setPercentageErrors([...[], ...percentageErrors.splice(index, 1)])
    }

    const closeNotification = () => {
        setErrorMessage(null);
    }

    const getCategories = (
        <>
            {props.affiliateCategory?.map((item: MarketplaceCategoriesModel, i: any) => {
                return (<option key={i} value={item.id}>{item.name}</option>)
            })
            }
        </>
    )

    const getPlatforms = (
        <>

            {props.marketplacePlatform?.map((item: MarketplacePlatformModel, i: any) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            })
            }
        </>
    )

    const uploadButton = (
        <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>{editAffiliateResource.upload}</div>
        </div>
    );


    const getPromotionSection = (arrayHelpers: FieldArrayRenderProps, index: number) => (

        <>
            <Row className="row-item" key={index}>
                {index != 0 ?
                    (
                        <>
                            <span className="new-promotion-section">
                                <span className="item-title">{editAffiliateResource.promoName}</span>
                                <span className="remove-promotion">
                                    <Button onClick={() => { removePromotionForm(index) }} className="button-delete" icon={<DeleteFilled className="error-style" />} size={'large'} />
                                </span>
                            </span>
                        </>
                    ) :
                    (
                        <span className="item-title">{resource.dashboard.marketplace.affiliate.newAffiliate.promoName}</span>
                    )
                }

                <Input
                    maxLength={maxInputLength}
                    name={`percentages.${index}.promotionName`}
                    value={formik.values.percentages[index].promotionName ?? ''}
                    onChange={formik.handleChange} className="item-input" />


                <span className="percentage-items">
                    <span className="percentage first-item">
                        <span className="item-title">{editAffiliateResource.userCashBack}</span>

                        <Input
                            min={0} type="number"
                            name={`percentages.${index}.cashBackPercentage`}
                            value={formik.values.percentages[index].cashBackPercentage ?? ''}
                            onChange={formik.handleChange}
                            placeholder="Enter percentage..." className="item-input with-element-after" addonAfter="%" />
                        {/* {formik.errors.name && formik.touched.name && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.name}</div>
                            </div>
                        )} */}
                    </span>
                    <span className="percentage">
                        <span className="item-title">{editAffiliateResource.guacCashBack}</span>
                        <Input
                            min={0} type="number" name={`percentages.${index}.guacCashBackPercentage`}
                            value={formik.values.percentages[index].guacCashBackPercentage ?? ''}
                            onChange={formik.handleChange}
                            placeholder="Enter percentage..." className="item-input  with-element-after" addonAfter="%" />

                    </span>
                </span>
                <Divider />
            </Row>
        </>
    )

    const getFooter = (
        <>
            <div style={{ textAlign: 'right' }}>
                <Button className="cancel-button" onClick={onClose} style={{ marginRight: 8 }}>
                    {editAffiliateResource.cancel}
                </Button>
                <Button className="save-button" onClick={() => { return (formik.handleSubmit()) }} type="primary">
                    {editAffiliateResource.save}
                </Button>
            </div>
        </>
    )

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        onSubmit: onEditAffiliateSubmit
    })

    return (
        <Space>
            <Drawer
                title={
                    <>
                        <span className="modal-header-title">{editAffiliateResource.title}</span>
                        {errorMessage ? <AlertComponent {...{ type: 'error', closable: true, message: 'Error', description: errorMessage, showIcon: true, onClose: closeNotification }}></AlertComponent> : ("")}
                    </>
                }
                placement={"right"}
                closable={false}
                onClose={onClose}
                visible={props.isVisible}
                key={"right"}
                width={464}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <>
                        {getFooter}
                    </>
                }>

                <Row className="add-affiliate-row-item">
                    <Row className="row-item">
                        <span className="affiliate-name">
                            <span className="head-item">
                                <span className="affiliate-icon">
                                    <ImgCrop beforeCrop={onBeforeCropImage} aspect={1} grid>
                                        <Upload
                                            listType="picture-card"
                                            className={formik.errors.iconUrl && formik.touched.iconUrl ? "affiliate-icon error" : "affiliate-icon"}
                                            onChange={onUploadChange}
                                            onPreview={onPreview}
                                            accept={acceptFileUpload}
                                            showUploadList={false}>
                                            {imageUrl ? <img className="render-uploaded-image" src={imageUrl} alt="avatar" /> : uploadButton}
                                        </Upload>
                                    </ImgCrop>
                                </span>
                            </span>
                            <span className="head-item name">
                                <span className="item-title">{editAffiliateResource.name}</span>
                                <Input maxLength={maxInputLength} onChange={formik.handleChange} value={formik.values.name} name="name" className="item-input" placeholder="Please enter name" />
                                {formik.errors.name && formik.touched.name && (
                                    <div
                                        className="ant-form-item-explain ant-form-item-explain-error">
                                        <div role="alert">{formik.errors.name}</div>
                                    </div>
                                )}
                            </span>
                        </span>
                    </Row>

                    <Row className="row-item status-row">
                        <span className="status-field">
                            <span className="title-field">{editAffiliateResource.status} </span>
                            <span className="description-field">{editAffiliateResource.statusDescription}</span>
                        </span>
                        <Switch
                            onChange={onStatusChanged}
                            checked={status}
                            className="checkbox-status"
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked />
                    </Row>

                    <Row className="row-item">
                        <span className="relation-options">
                            <span className="platform-item first-item">
                                <span className="item-title">{editAffiliateResource.platform}</span>
                                <Select onChange={onPlatformSelected} value={formik.values.platformId} className="item-select" defaultValue="Select platform"  >
                                    {getPlatforms}
                                </Select>
                                {formik.errors.platformId && formik.touched.platformId && (
                                    <div
                                        className="ant-form-item-explain ant-form-item-explain-error">
                                        <div role="alert">{formik.errors.platformId}</div>
                                    </div>
                                )}
                            </span>
                            <span className="platform-item">
                                <span className="item-title">{editAffiliateResource.category}</span>
                                <Select onChange={onCategorySelected} value={formik.values.categoryId} className="item-select" defaultValue="Select category"  >
                                    {getCategories}
                                </Select>
                                {formik.errors.categoryId && formik.touched.categoryId && (
                                    <div
                                        className="ant-form-item-explain ant-form-item-explain-error">
                                        <div role="alert">{formik.errors.categoryId}</div>
                                    </div>
                                )}
                            </span>
                        </span>
                    </Row>

                    <Divider />

                    <Row className="row-item no-padding">
                        <span className="main-title">{editAffiliateResource.url}</span>
                        <Row className="row-item">
                            <span className="item-title">{editAffiliateResource.accessUrl}</span>
                            <Input onChange={formik.handleChange} name="accessUrl" value={formik.values.accessUrl} placeholder="https://placelink.com" className="item-input with-element" addonBefore={<LinkOutlined />} />
                            {formik.errors.accessUrl && formik.touched.accessUrl && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.accessUrl}</div>
                                </div>
                            )}
                        </Row>
                    </Row>

                    <Row className="row-item">
                        <span className="item-title">{editAffiliateResource.secondUrl}</span>
                        <Input onChange={formik.handleChange} name="secondAccessUrl" value={formik.values.secondAccessUrl} placeholder="https://placelink.com" className="item-input with-element" addonBefore={<LinkOutlined />} />
                        {formik.errors.secondAccessUrl && formik.touched.secondAccessUrl && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.secondAccessUrl}</div>
                            </div>
                        )}
                    </Row>

                    <Divider />

                    <Row className="row-item no-padding">
                        {/* <span className="main-title">{editAffiliateResource.promotions}</span> */}
                        <span className="main-title">Promotions {isAmazonSelected ?<i>(Optional)</i> : ("")} </span>

                        <Form>
                            <FieldArray
                                name="percentages"
                                validateOnChange={false}
                                render={arrayHelpers => (
                                    <div>
                                        {formik.values.percentages && formik.values.percentages.length > 0 ? (
                                            formik.values.percentages.map((item, index) => (
                                                <div key={index}>
                                                    {getPromotionSection(arrayHelpers, index)}
                                                </div>
                                            ))
                                        ) : (<> </>)}
                                    </div>
                                )}
                            />
                        </Form>
                        <Button className="addNewPromotion-button" onClick={addNewPromotionForm} type="link" shape="round" icon={<PlusCircleOutlined />} >
                            {editAffiliateResource.addNew}
                        </Button>
                    </Row>

                </Row>
            </Drawer>
        </Space>
    )
}

const mapStateToProps = ({ affiliateDetails, affiliateCategory, marketplacePlatform }: AppState) =>
({
    affiliateDetails: affiliateDetails,
    affiliateCategory: affiliateCategory,
    marketplacePlatform: marketplacePlatform
});

export default connect(mapStateToProps, {
    updateMarketplaceCategoriesInStoreAction,
    updateMarketplacePlatformInStoreAction
})(EditAffiliateComponent);
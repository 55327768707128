import {executeAction} from "../../../commons/baseAction";
import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import users from '../../../services/dashboard/users/index';
import {AppState} from '../../../commons/reduxStore';
import {queryRequest} from '../../../../app/common/requestUtils/requestUtils';

import {PlaidDetailsBankAccount} from '../../../models/dashboard/users/plaidDetails';

export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_ERROR = "GET_BANK_ACCOUNT_ERROR";

const {getPlaidDetails} = users;

export interface BankAccountDetailsInStoreAction{
    type: typeof GET_BANK_ACCOUNT_SUCCESS
        | typeof GET_BANK_ACCOUNT_ERROR,
    details: PlaidDetailsBankAccount,
    selectedUser: string
}

export const initialDetails : PlaidDetailsBankAccount = {
    item: null,
    requestId: "",
    status: null
};

export const updateBankAccountInStoreAction =
    (selectedUser: string,
     onSuccess: Function,
     onFailure: Function)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        executeAction(
            queryRequest(() => getPlaidDetails(selectedUser, false)),
            (res: any) => {
                dispatch({
                    type: GET_BANK_ACCOUNT_SUCCESS,
                    details: res as PlaidDetailsBankAccount,
                    selectedUser: selectedUser
                } as BankAccountDetailsInStoreAction);
                onSuccess();
            },
            (_: any) =>{
                dispatch({
                    type: GET_BANK_ACCOUNT_ERROR,
                    details: initialDetails,
                    selectedUser: selectedUser
                } as BankAccountDetailsInStoreAction);
                onFailure();
            }
        );
    };
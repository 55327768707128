import { Alert } from "antd"
import React from "react"
import "../../../../styles/components/alertMessage.less";

export interface AlertItemProps {
    type: 'success' | 'info' | 'warning' | 'error';
    closable: boolean;
    message: string;
    description: string;
    onClose: () => void;
    showIcon: boolean;
}

const AlertComponent: React.FC<AlertItemProps> = props => {
    return (
        <>
            <Alert
                message={props.message}
                description={props.description}
                type={props.type}
                closable={props.closable}
                showIcon={props.showIcon}
                onClose={props.onClose}
            />
        </>
    )
}
export default AlertComponent;
import getCategories from "./platform/getMarketplacePlatform";
import addNewCategory from "./categories/addNewCategory";
import deleteCategory from "./categories/deleteCategory";
import updateCategory from "./categories/updateCategory";
import updateOrderCategories from "./categories/updateOrderCategories";

import getMarketplacePlatform from "./platform/getMarketplacePlatform";
import addNewPlatform from "./platform/addNewPlatform";
import updatePlatform from "./platform/updatePlatform";
import deletePlatform from "./platform/deletePlatform";
import getSuggestedAffiliates from "./suggestedAffiliates/getSuggestedAffiliates";
import updateOrder from "./updateOrder";
import affiliateServices from "./affiliates/index";
import  amazonCashbackRequests from "./amazon/index"
const marketplace = {

    getMarketplacePlatform,
    getCategories, 
    addNewCategory,
    deleteCategory,
    updateCategory,
    updateOrderCategories,
    deletePlatform,
    addNewPlatform,
    updatePlatform,
    getSuggestedAffiliates,
    updateOrder,
    ...affiliateServices,
    ...amazonCashbackRequests

}

export default marketplace;
import { GET_TRANSACTIONSBYUSER_ERROR, GET_TRANSACTIONSBYUSER_SUCCES, TransactionsByUserInStoreAction } from "../../../actions/dashboard/transactions/updateTransactionsListByUserInStore";
import { TransactionsModel } from "../../../models/dashboard/transactions/transation";


export const updateTransactionsByUserInStoreReducer 
=(state:TransactionsModel| null= null, action: TransactionsByUserInStoreAction) => {
    switch (action.type) {
        case GET_TRANSACTIONSBYUSER_SUCCES:
            return { ...state, ...action.userTransactions }
        case GET_TRANSACTIONSBYUSER_ERROR:
            return {...state};
        default:
            return {...state};
    }
}
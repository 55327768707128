import getMarketplacePurchaseByMarketplace from "./getMarketplacePurchasesByMarketplace";
import getAllMarketplacePurchaseByMarketplace from "./getAllMarketplacePurchasesByMarketplace";
import getMarketplacePurchaseByUser from "./getMarketplacePurchasesByUser";
import addNewMarketplaceTransaction from "./addNewMarketplaceTransaction";
import getMarketplaceProgramReserve from "./getMarketplaceProgramReserve";

const marketplacePurchase   = {
    getMarketplacePurchaseByMarketplace,
    getAllMarketplacePurchaseByMarketplace,
    getMarketplacePurchaseByUser,
    addNewMarketplaceTransaction,
    getMarketplaceProgramReserve
}

export default marketplacePurchase;
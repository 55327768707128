import getMarketplaceCashbackRequest from "./getMarketplaceCashbackRequest";
import matchCashbackRequestTransaction from "./matchCashbackRequestTransations";
import rejectCashbackRequestTransaction from "./rejectCashbackRequestTransaction";
import sendCashbackRequestMoreInformation from "./sendCashbackRequestMoreInformation";
import getRejectedCashbackRequest from "./getRejectedCashbackRequest";
import getApprovedCashbackRequest from "./getApprovedCashbackRequest";

const amazonCashbackRequests   = {
    getMarketplaceCashbackRequest,
    matchCashbackRequestTransaction,
    rejectCashbackRequestTransaction,
    sendCashbackRequestMoreInformation,
    getRejectedCashbackRequest,
    getApprovedCashbackRequest
    
}

export default amazonCashbackRequests;
import { Badge } from "antd";
import React from "react";

export enum Status {
    Active,
    Paused,
    Deleted,
    Pending,
    Completed,
    Reversed,
}

export interface StatusItemProps {
    status: Status
}

const StatusItemComponent: React.FC<StatusItemProps> = props => {


    return (
        <>
           {props.status == Status.Active &&
                <Badge color={"#3AB100"} text={"Active"} />
            }
            {props.status == Status.Paused &&
                <Badge color={"#F5222D"} text={"Paused"} />
            }
            {props.status == Status.Pending &&
                <Badge color={"#FA8C16"} text={"Pending"} />
            }
            {props.status == Status.Completed &&
                <Badge color={"#52C41A"} text={"Completed"} />
            }
            {props.status == Status.Reversed &&
                <Badge color={"#F5222D"} text={"Reversed"} />
            }
              {props.status == Status.Deleted &&
                <Badge color={"#F5222D"} text={"Deleted"} />
            }
        </>
    )
}
export default StatusItemComponent;
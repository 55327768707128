import { Badge, Card, Empty, Row, Spin, Table, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../core/commons/reduxStore";
import { CashBackStatus, CSVMarketplacePurchase, MarketplacePurchase, MarketplacePurchaseModel, MarketplacePurchaseSearch, MarketplacePurchaseType, PurchaseStatus } from "../../../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import CashbackStatusComponent from "../../../../common/components/dashboard/CashbackStatusComponent";
import resource from "../../../../common/resource/resource";
import { error, getCashbackStatusName, getPlatformName, getPurchaseStatusName, success } from "../../../../common/utils/Utils";
import CashbackDetailsMarketplacePurchaseComponent from "../../../marketplacePurchase/CashbackDetailsMarketplacePurchaseComponent";
import { updateMarketplacePurchaseByMarketplaceInStoreAction } from "../../../../../core/actions/dashboard/marketplacePurchase/updateMarketplacePurchaseByMarketplaceInStore";
import "../../../../../styles/layout/dashboard/marketplace/affiliateDetails/affiliatePurchase.less";
import affiliatePurchasesResource from "./Resource";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import ButtonComponent from "../../../../common/components/dataDisplay/Button";
import marketplacePurchaseActions from "../../../../../core/actions/dashboard/marketplacePurchase/index";
import NewTransactionComponent from "./newTransaction/NewTransactionComponent";
import PurchaseStatusComponent from "../../../../common/components/dashboard/PurchaseStatusComponent";
const { updateAllMarketplacePurchaseByMarketplaceInStoreAction } = marketplacePurchaseActions;

const { TabPane } = Tabs;


export interface AffiliateDetailsProps {
    affiliateId: string
}

const initSearchParams: MarketplacePurchaseSearch = {
    affiliateId: null,
    orderDescending: true,
    pageNumber: 1,
    pageSize: 5,
    searchValue: "",
    sortColumn: "CreatedAt",
    userId: null
}

const AffiliatePurchaseComponent: React.FC<AffiliateDetailsProps> = (props: any) => {

    const [isLoading, setLoading] = useState(false);

    const [addNewTransactionMode, setAddNewTransactionMode] = useState(false);
    
    const [keyExpanded, setKeyExpanded] = useState<Array<string>>([]);
   
    const [CSVMarketplacePurchases, setCSVMarketplacePurchases] = useState<Array<CSVMarketplacePurchase>>([]);
   
    const defaultSortColumnt = "CreatedAt";

    useEffect(() => {
        initSearchParams.affiliateId = props.affiliateId;
        props.updateMarketplacePurchaseByMarketplaceInStoreAction(null, initSearchParams, onGetAffiliateDetailsSuccess, onGetAffiliateDetailsFailure);
        props.updateAllMarketplacePurchaseByMarketplaceInStoreAction([], props.affiliateId);
    }, []);

    const closeAddNewModal = () => {
        setAddNewTransactionMode(false);       
        initSearchParams.affiliateId = props.affiliateId;
        props.updateMarketplacePurchaseByMarketplaceInStoreAction(null, initSearchParams, onGetAffiliateDetailsSuccess, onGetAffiliateDetailsFailure);
        props.updateAllMarketplacePurchaseByMarketplaceInStoreAction([], props.affiliateId);
    };

    const openAddNewTransactionModal = () => {
        setAddNewTransactionMode(true);
    }

    const onGetAffiliateDetailsSuccess = () => {
        success(affiliatePurchasesResource.success);
        setLoading(false);
    }

    const onGetAffiliateDetailsFailure = () => {
        error(affiliatePurchasesResource.error);
        setLoading(false);
    }

    const columns = [
        {
            title: `${affiliatePurchasesResource.tableColumns.purchaseDate}`, dataIndex: 'purchaseDate',
            width: 220,
            sorter: true,
            fixed: true,
            key: 3, render: (purchaseDate: Date, row: MarketplacePurchase) => (
                <> { purchaseDate ? `${moment(new Date(purchaseDate)).format("MM-DD-YYYY")}` : ("")} </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.user}`, dataIndex: 'user.fullName', width: 170, key: 5, sorter: true,
            render: (lastModifiedAt: Date, row: MarketplacePurchase) => (
                <> { row.user ? `${row.user.fullName}` : ("")} </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.status}`, dataIndex: 'status', width: 220, key: 6, sorter: true,
            render: (status: PurchaseStatus, row: MarketplacePurchase) => (
                <>
                 <PurchaseStatusComponent {...{purchaseStatus: status}}></PurchaseStatusComponent>
                </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.cashBackStatus}`, dataIndex: 'cashBackStatus', sorter: true, width: 220, key: 7, render: (cashBackStatus: number, row: any) => (
                <>
                    <CashbackStatusComponent {...{ status: cashBackStatus }}></CashbackStatusComponent>
                </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.totalCashBackPercentage}`,
            dataIndex: 'marketplace.totalCashBackPercentage',
            width: 220,
            key: 8,
            render: (cashBackPercentage: number, row: MarketplacePurchase) => (
                <>
                    { row.marketplace ? `${row.marketplace.totalCashBackPercentage * 100}%` : ("")}
                </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.guacCashBackPercentage}`,
            dataIndex: 'marketplace.guacCashBackPercentage',
            width: 220,
            key: 8,
            render: (guacCashBackPercentage: number, row: MarketplacePurchase) => (
                <>
                    { row.marketplace ? `${row.marketplace.guacCashBackPercentage * 100}%` : ("")}
                </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.userCashBackPercentage}`,
            dataIndex: 'marketplace.cashBackPercentage',
            width: 220,
            key: 8,
            render: (cashBackPercentage: number, row: MarketplacePurchase) => (
                <>
                    { row.marketplace ? `${row.marketplace.cashBackPercentage * 100}%` : ("")}
                </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.totalCommission}`, dataIndex: 'totalCommission', key: 9,
            width: 220,
            render: (totalCommission: number, row: MarketplacePurchase) => (
                <>
                    { row.totalCommission ? `$${row.totalCommission}` : ("")}
                </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.guacCashBack}`, dataIndex: 'guacCashBack', key: 9,
            width: 220,
            render: (guacCashBack: number, row: MarketplacePurchase) => (
                <>
                    { row.guacCashBack ? `$${row.guacCashBack}` : ("")}
                </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.userCashBack}`, dataIndex: 'cashBack', key: 9,
            width: 220,
            render: (cashBack: number, row: MarketplacePurchase) => (
                <>
                    { row.cashBack ?  `$${row.cashBack}` : ("")}
                </>
            )
        },
        {
            title: `${affiliatePurchasesResource.tableColumns.amount}`, dataIndex: 'amount', key: 10,
            sorter: true,
            width: 220,
            render: (amount: number, row: MarketplacePurchase) => (
                <>
                    { row.amount ? `$${row.amount}` : ("")}

                </>
            )
        }
    ];

    const onTableRowExpand = (expanded: boolean, record: any) => {
        let keys: Array<string> = [];
        if (expanded) {
            keys.push(record.id);
        }
        setKeyExpanded(keys);
    }


    if (props.allMarketplacePurchases.length && CSVMarketplacePurchases.length == 0) {
        let exportedData: Array<CSVMarketplacePurchase> = [];

        props.allMarketplacePurchases.forEach((item: MarketplacePurchase) => {

            exportedData.push({
                PurchaseDate: item.purchaseDate,
                OrderNumber: item.associatedPurchase,
                Platform: getPlatformName(item.type),
                Marketplace: item.marketplace.name,
                User: item.user.fullName,
                PurchaseStatus: getPurchaseStatusName(item.status),
                CashBackStatus: getCashbackStatusName(item.cashBackStatus),
                Amount: item.amount,
                TotalCommission: item.totalCommission,
                CashBack: item.cashBack,
                GuacCashBack: item.guacCashBack,
                IsCollected: item.isCollected,
                CollectedDate: item.collectedDate,
                TrackedOn: item.cashBackCalculated,
                ClaimedDate: item.claimedDate,
                ExpiredOn: item.expiredOn,
                AvailableOn: item.availableOn,
                CanceledOn: item.canceledOn
            })
        });
        setCSVMarketplacePurchases(exportedData);
    }

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        setLoading(true);
        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;
            } else {
                initSearchParams.sortColumn = defaultSortColumnt;
                initSearchParams.orderDescending = true;
            }
        }

        if (pagination) {
            if (props.marketplaces?.pageNumber != pagination.current) {
                initSearchParams.pageNumber = pagination.current;
            }
        };

        props.updateMarketplacePurchaseByMarketplaceInStoreAction(null, initSearchParams, onGetAffiliateDetailsSuccess, onGetAffiliateDetailsFailure);
    }

    return (<>
        <Spin spinning={isLoading}>
            <Card>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={`${affiliatePurchasesResource.transactions}`} key="1">
                       <Row className="row-header-actions">
                            <Row className="header-actions">
                                <ButtonComponent  name="Add Transaction" {...{ type: "primary", shape: "round", onClick:openAddNewTransactionModal, icon: <PlusOutlined /> }} />
                            </Row>
                            <Row className="header-actions">
                                <CSVLink
                                    filename={`${affiliatePurchasesResource.export.fileName}`}
                                    title={`${affiliatePurchasesResource.export.title}`}
                                    data={CSVMarketplacePurchases} >
                                    <ButtonComponent shape="round"
                                        disabled={props.marketplacePurchases?.items?.length == 0}
                                        icon={<DownloadOutlined />}
                                        name={`${affiliatePurchasesResource.export.name}`} />
                                </CSVLink>
                            </Row>                        
                       </Row>
                        <Table
                            columns={columns}
                            rowKey="id"
                            onExpand={onTableRowExpand}
                            expandedRowKeys={keyExpanded}
                            expandable={{
                                expandedRowRender: (record: MarketplacePurchase) => <>
                                    <CashbackDetailsMarketplacePurchaseComponent {...{ marketplacePurchase: record }}>
                                    </CashbackDetailsMarketplacePurchaseComponent>
                                </>,
                                rowExpandable: (record: MarketplacePurchase) => (record.cashBackStatus != CashBackStatus.Processing),
                            }}

                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`${resource.generic.tableEmpty}`} /> }}

                            pagination={{
                                total: props.marketplacePurchases?.totalItemCount, current: props.marketplacePurchases?.pageNumber,
                                pageSize: props.marketplacePurchases?.pageSize,
                                showSizeChanger: false,
                            }}

                            scroll={{ x: 1200 }}
                            onChange={onChangeEvent}
                            dataSource={props.marketplacePurchases?.items}
                        />
                    </TabPane>
                </Tabs>
            </Card>
            {addNewTransactionMode && 
            <NewTransactionComponent {...{isVisible:addNewTransactionMode,changeVisibility:closeAddNewModal}}></NewTransactionComponent>
            }
        </Spin>
    </>)
}
const mapStateToProps = ({ marketplacePurchases, allMarketplacePurchases }: AppState) =>
({
    marketplacePurchases: marketplacePurchases,
    allMarketplacePurchases: allMarketplacePurchases
});

export default connect(mapStateToProps, {
    updateMarketplacePurchaseByMarketplaceInStoreAction,
    updateAllMarketplacePurchaseByMarketplaceInStoreAction
})(AffiliatePurchaseComponent);
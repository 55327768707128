import React, {useState} from 'react';
import NotificationJobLogListComponent from "../notificationJob/NotificationJobLogListComponent";
import NotificationExecutionLogListComponent from "../notificationExecution/NotificationExecutionLogListComponent";

export interface LogState{
    areLogsOpen: boolean,
    isSub: boolean,
    selectedId: string
}

const NotificationLogListComponentWrapper: React.FC<any> = (props: any) => {
    const [state, setState] = useState<LogState>({
        areLogsOpen: false, isSub: false, selectedId: ""
    });


    const openLogs = (id: string, sub: boolean) => {
        setState({
            selectedId: id,
            isSub: sub,
            areLogsOpen: true
        });
    }

    const closeLogs = () => {
        setState({
            selectedId: '',
            isSub: false,
            areLogsOpen: false
        });
    }

    return (
        <>
            {
                state.areLogsOpen ? <NotificationExecutionLogListComponent
                    isSubJob={state.isSub}
                    jobId={state.selectedId}
                    isLog={true}
                    title={'(Monitoring) Notification Logs'}
                    close={closeLogs}
                /> : <NotificationJobLogListComponent openLogs={openLogs}/>
            }
        </>
       );
}


export default NotificationLogListComponentWrapper;
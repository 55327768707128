export interface MarketplacePlatformModel {
    id: string;
    name:string;
    iconUrl:string;
    platformStatus:number;
    order:number;
}
export interface AddMarketplacePlatformModel {
    name:string; 
    iconUrl:string;
}
export interface UpdateMarketplacePlatformModel {
    id: string;
    name:string;
    iconUrl:string;
    platformStatus:number;
    order:number;
}
export  enum PlatformStatus
{
    Active, 
    Paused
}
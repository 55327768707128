import {updateAllMarketplacePurchaseByMarketplaceInStoreReducer} from "./updateAllMarketplacePurchaseByMarketplaceInStoreReducer";
import {updateMarketplacePurchaseByMarketplaceInStoreReducer} from "./updateMarketplacePurchaseByMarketplaceInStoreReducer";
import {updateMarketplacePurchaseByUserInStoreReducer} from "./updateMarketplacePurchaseByUserInStoreReducer";
import {getCashbackStatisticsInStoreReducer} from "./getCashbackStatisticsInStoreReducer";
import {getMarketplaceProgramReserveInStoreReducer} from "./getMarketplaceProgramReserveInStoreReducer";
import {getTotalMarketplacePurchasesInStoreReducer} from "./getTotalMarketplacePurchasesInStoreReducer";

const marketplacePurchaseReducer = {
    updateAllMarketplacePurchaseByMarketplaceInStoreReducer,
    updateMarketplacePurchaseByMarketplaceInStoreReducer,
    updateMarketplacePurchaseByUserInStoreReducer,
    getCashbackStatisticsInStoreReducer,
    getMarketplaceProgramReserveInStoreReducer,
    getTotalMarketplacePurchasesInStoreReducer
}
export default marketplacePurchaseReducer;
import {login,passwordRevery,resetPassword, confirmCode, resendCode} from "./auth";
import getAuthenticatedUser from "./getAuthenticatedUser";


const auth = {
    login,
    confirmCode,
    resendCode,
    passwordRevery,
    resetPassword,
    getAuthenticatedUser
}

export default auth;
import auth from './auth';
import accounts from './accounts';
import dashboard from './dashboard';
import uploadIcon from './files/uploadIcons';

const services = {
    ...auth,
    ...accounts,
    ...dashboard, 
    uploadIcon
};

export default services;
import React, {useState} from 'react';
import {Button, Empty, message, Spin, Table} from "antd";
import {
    AdminNotificationSendJobModel, JobRole,
    JobStatus,
    JobType
} from "../../../../core/models/dashboard/notification/notification";
import {GetJobRole, GetJobStatus, getJobType} from "../../../common/components/dashboard/NotificationHelpers";
import {ArrowRightOutlined} from "@ant-design/icons";
import {updateNotificationSubJobs} from "../../../../core/actions/dashboard/notification/updateNotifcationJobsInStore";


const NotificationSubJobLogListComponent: React.FC<any> = (props: any) => {
    const [isLoading, setLoading] = useState(false);
    const [isMounting, setMounting] = useState(true);
    const [notificationJobs, setNotificationJobs] = useState<AdminNotificationSendJobModel>({
        items: [],
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        totalItemCount: 0
    });

    const onGetJobsSuccess = (res: AdminNotificationSendJobModel) => {
        setLoading(false);
        setNotificationJobs(res);
    }

    const onGetJobsFailure = (err: any) => {
        message.error(err.message)
        setLoading(false);
    }

    const loadJobs = (pageNumber: number) => {
        updateNotificationSubJobs({
            pageNumber: pageNumber,
            pageSize: 7,
            parentId: props.parentId
        },  onGetJobsSuccess, onGetJobsFailure);
    }

    if (isMounting) {
        setLoading(true);
        setMounting(false);
        loadJobs(1);
    }

    const onChangeEvent = (pagination: any, _: any, _1: any, _2: any) => {
        if (pagination) {
            if (notificationJobs?.pageNumber !== pagination.current) {
                loadJobs(pagination.current);
                setLoading(true);
            }
        }
    }

    const columns = [
        {
            title: 'Tag',
            dataIndex: 'tag',
            width: 100,
            key: 1,
            fixed: true,
            sorter: false,
            render: (tag: string) => (
                <>
                    <span>{tag}</span>
                </>
            )
        },
        {
            title: 'Type',
            dataIndex: 'jobType',
            width: 100,
            key: 2,
            fixed: false,
            sorter: false,
            render: (type: JobType) => (
                <>
                    <span>{getJobType(type)}</span>
                </>
            )
        },
        {
            title: 'Status',
            dataIndex: 'jobStatus',
            width: 100,
            key: 3,
            fixed: false,
            sorter: false,
            render: (status: JobStatus) => {
                return (
                    <>
                        <GetJobStatus status={status}/>
                    </>
                )
            }
        },
        {
            title: 'Layer',
            dataIndex: 'jobRole',
            width: 100,
            key: 4,
            fixed: false,
            sorter: false,
            render: (role: JobRole) => (
                <>
                    <GetJobRole role={role}/>
                </>
            )
        },
        {
            title: 'Action',
            key: 9,
            fixed: false,
            sorter: false,
            dataIndex: 'id',
            width: 80,
            render: (id: string | null, _: any) =>
                <>
                    <Button onClick={() => {
                        props.openLogs(id, true);
                    }} type="link">
                        <ArrowRightOutlined/>
                    </Button>
                </>
        },
    ];


    return (
        <Spin spinning={isLoading}>
            <Table
                locale={{
                    emptyText: <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No results for ths search. Try again..."/>
                }}
                pagination={{
                    total: notificationJobs?.totalItemCount,
                    current: notificationJobs?.pageNumber,
                    pageSize: notificationJobs?.pageSize,
                    showSizeChanger: false,
                }}
                className=""
                sortDirections={['ascend', 'descend', 'ascend']}
                scroll={{x: 800, y: 600}}
                onChange={(onChangeEvent)}
                columns={columns}
                dataSource={
                    notificationJobs?.items?.map((e: any, key: number) => ({
                        key: key,
                        ...e
                    }))}/>
        </Spin>
    );
}

export default NotificationSubJobLogListComponent;
import {
    CashbackStatisticsInStoreAction,
    GET_CASHBACK_STATISTICS_FAILURE,
    GET_CASHBACK_STATISTICS_SUCCESS
} from "../../../actions/dashboard/marketplacePurchase/getCashbackStatisticsInStore";
import {CashbackStatisticsModel} from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";

const initialCashbacks = {
    cashbacks : []
} as CashbackStatisticsModel

export const getCashbackStatisticsInStoreReducer = (state : CashbackStatisticsModel | null = initialCashbacks, action : CashbackStatisticsInStoreAction) => {
    switch (action.type) {
        case GET_CASHBACK_STATISTICS_SUCCESS:
            return action.cashbackStatistics;
        case GET_CASHBACK_STATISTICS_FAILURE:
            return {...state};
        default:
            return {...state};
    }
}
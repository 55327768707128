import { Col, Divider, Row } from "antd";
import moment from "moment";
import React from "react";
import { CashBackStatus, MarketplacePurchase } from "../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import "../../../styles/layout/dashboard/marketplacePurchase/cashbackDetailsMarketplacePurchase.less";

export interface CashbackDetailsMarketplacePurchaseProps {
    marketplacePurchase: MarketplacePurchase
}

const CashbackDetailsMarketplacePurchaseComponent: React.FC<CashbackDetailsMarketplacePurchaseProps> = props => {

    const getCashbackActionDateType = (marketplacePurchase: MarketplacePurchase) => {
        return (
            <>
                {marketplacePurchase.cashBackStatus == CashBackStatus.Available &&
                    <>
                        <span className="item-title">Available date</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.availableOn ? `${moment(new Date(props?.marketplacePurchase.availableOn)).format("MM-DD-YYYY")}` : ("--")} </></span>
                    </>

                }
                {marketplacePurchase.cashBackStatus == CashBackStatus.Canceled &&

                    <>  <span className="item-title">Canceled date</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.canceledOn ? `${moment(new Date(props?.marketplacePurchase.canceledOn)).format("MM-DD-YYYY")}` : ("--")} </></span>
                    </>

                }
                {marketplacePurchase.cashBackStatus == CashBackStatus.Claimed &&
                    <>  <span className="item-title">Claimed date</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.claimedDate ? `${moment(new Date(props?.marketplacePurchase.claimedDate)).format("MM-DD-YYYY")}` : ("--")} </></span>
                    </>

                }
                {marketplacePurchase.cashBackStatus == CashBackStatus.Collected &&
                    <> <span className="item-title">Collected date</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.collectedDate ? `${moment(new Date(props?.marketplacePurchase.collectedDate)).format("MM-DD-YYYY")}` : ("--")} </></span>
                    </>

                }
                {marketplacePurchase.cashBackStatus == CashBackStatus.Expired &&
                    <> <span className="item-title">Expired date</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.expiredOn ? `${moment(new Date(props?.marketplacePurchase.expiredOn)).format("MM-DD-YYYY")}` : ("--")} </></span>
                    </>
                }
            </>
        );
    }

    return (
        <>
            <Row className="cashback-details" gutter={[32, 24]}>
                <span className="transfer-type-header">
                    <span className="transfer-type">
                        <span className="title">CASHBACK DETAILS</span>
                    </span>
                </span>
                <Divider />

                <Row className="cashback-item" gutter={[16, 16]}>
                    <Col className="item-details" span={8}>
                        <span className="item-title">Transaction ID</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.associatedPurchase ? `#${props.marketplacePurchase.associatedPurchase}` : ("--")} </></span>
                    </Col>

                    <Col className="item-details" span={8}>
                        <span className="item-title">Tracked date</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.cashBackCalculated ? `${moment(new Date(props.marketplacePurchase.cashBackCalculated)).format("MM-DD-YYYY")}` : ("--")} </></span>
                    </Col>
                    <Col className="item-details" span={8}>
                        {getCashbackActionDateType(props.marketplacePurchase)}
                    </Col>
                </Row>

                <Row className="cashback-item" gutter={[16, 16]}>
                    <Col className="item-details" span={8}>
                        <span className="item-title">User</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.user ? <>{props.marketplacePurchase.user.fullName}</> : ("--")} </></span>
                    </Col>
                    <Col className="item-details" span={8} >
                        <span className="item-title">Total cashback amount</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.totalCommission ? `$${props.marketplacePurchase.totalCommission} (${props.marketplacePurchase.marketplace.totalCashBackPercentage*100}%)` : ("--")} </></span>

                    </Col>
                    <Col className="item-details" span={8} >
                        <span className="item-title">Guac Cashback amount</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.guacCashBack ? `$${props.marketplacePurchase.guacCashBack} (${props.marketplacePurchase.marketplace.guacCashBackPercentage*100}%)` : ("--")} </></span>

                    </Col>
                </Row>
                <Row className="cashback-item" gutter={[16, 16]}>
                    <Col className="item-details" span={8}>
                    </Col>
                    <Col className="item-details" span={8} >
                        <span className="item-title">Total user cashback amount</span>
                        <span className="item-value"> <> {props?.marketplacePurchase.cashBack ? `$${props.marketplacePurchase.cashBack} (${props.marketplacePurchase.marketplace.cashBackPercentage*100}%)` : ("--")} </></span>

                    </Col>
                    <Col className="item-details" span={8} >
                        <span className="item-title">To</span>
                        <span className="item-value">{props?.marketplacePurchase.goals && props?.marketplacePurchase.goals.length > 0 ?
                            <>
                                {props.marketplacePurchase.goals.map(item => {
                                    return (
                                        <> {
                                            item.name
                                        }<br></br>
                                        </>
                                    )
                                })}
                            </>
                            : ("--")}</span>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default CashbackDetailsMarketplacePurchaseComponent;
import { Statistics } from '../../models/dashboard/statistics';
import { GET_STATISTICS_ERROR, GET_STATISTICS_SUCCESS, StatisticsInStoreAction } from './../../actions/dashboard/updateStatisticsInStore';

const initValuesModel: Statistics = {
    allAdminStatistics: {
        transactionStatistics: {
            transactions: [],
            amount: 0,
            count: 0
        },
        userStatistics: {
            activeUsers: 0,
            totalUsers: 0,
            usersDeletionRequest: 0,
            usersNotVerified: 0,
            usersOnBoardingCompleted: 0,
            usersWithBankConnected: 0,
            usersWithBankDisconnected: 0,
            inActiveUsers:0
        }
    },
    marketplaceStatistics: {
        totalAffiliates: 0,
        totalCashBackAmount: 0,
        totalMarketplacePurchases: 0,
        totalPurchaseAmount: 0
    },
    todayAdminStatistics: {
        transactionStatistics: {
            transactions: [],
            amount: 0,
            count: 0
        },
        userStatistics: {
            activeUsers: 0,
            totalUsers: 0,
            usersDeletionRequest: 0,
            usersNotVerified: 0,
            usersOnBoardingCompleted: 0,
            usersWithBankConnected: 0,
            usersWithBankDisconnected: 0,
            inActiveUsers:0

        }
    },
    goalStatistics :{
        totalActiveAndCompletedGoals:0,
        totalPausedGoals:0,
        totalGoals:0,
    }
}
export const updateStatisticsInfoReducer = (state = null, action: StatisticsInStoreAction) => {
    switch (action.type) {

        case GET_STATISTICS_SUCCESS:
            return { ...action.statistics };
        case GET_STATISTICS_ERROR:
            return initValuesModel;
        default:
            return state;
    }
}
import { Empty, Table } from "antd";
import React from "react";
import { SavingsDistributionActivity } from "../../../../core/models/dashboard/transactions/transation";
import { CurrencyNumberFormat } from "../../../common/utils/Utils";

export interface SavingsDepositComponentProps {
    savingsDistributionActivities: SavingsDistributionActivity[];
}


const SavingDepositComponent: React.FC<SavingsDepositComponentProps> = props => {

    const columns = [
        {
            title: 'Savings goal distributed to', dataIndex: 'name', key: '0',
            render: (name: any, record: SavingsDistributionActivity) => (
                <> { record.goalSaving ? record.goalSaving.name : ("")} </>
            )
        },
        {
            title: 'Distribution percentage', dataIndex: 'percentage', key: '1',
            render: (percentage: any, record: SavingsDistributionActivity) => (
                <> { record.goalSaving ? `${record.goalSaving.percentage}%` : ("")} </>
            )
        },
        {
            title: 'Deposit amount', dataIndex: 'amountSaved', key: 'amountSaved',
            render: (amountSaved: any) => (
                <> { amountSaved ? CurrencyNumberFormat(amountSaved) : ("")} </>
            )
        },
    ]

    return <>
        <Table
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
            pagination={false}
            columns={columns} dataSource={props.savingsDistributionActivities} />
    </>
}
export default SavingDepositComponent;

import {
    AdminNotificationExecutionLogData,
    AdminNotificationExecutionLogModel,
    BaseRequestParams,
    GetNotificationLogsModel
} from "../../../models/dashboard/notification/notification";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../../../commons/reduxStore";
import {Action} from "redux";
import {executeAction} from "../../../commons/baseAction";
import {queryRequest} from "../../../../app/common/requestUtils/requestUtils";
import {getAdminNotificationExecutionLogs} from "../../../services/dashboard/notification/handleAdminNotifications";

export const GET_NOTIFICATION_LOGS_SUCCESS = "GET_NOTIFICATION_LOGS_SUCCESS";
export const GET_NOTIFICATION_LOGS_FAILURE = "GET_NOTIFICATION_LOGS_FAILURE";

export interface NotificationLogsInStoreAction {
    type: typeof GET_NOTIFICATION_LOGS_SUCCESS
        | typeof GET_NOTIFICATION_LOGS_FAILURE,
    model: AdminNotificationExecutionLogModel | null,
    searchParams: BaseRequestParams,
}

export const updateNotificationLogsInStore = (
    model: GetNotificationLogsModel,
    currentParams: BaseRequestParams | null,
    onSuccess: Function,
    onFailure: Function
):
    ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(() => getAdminNotificationExecutionLogs(model)),
        (res : AdminNotificationExecutionLogData) => {
            dispatch({
                type: GET_NOTIFICATION_LOGS_SUCCESS,
                model: res,
                searchParams: {
                    pageNumber: model.pageNumber,
                    pageSize: model.pageSize
                }
            } as unknown as NotificationLogsInStoreAction);
            onSuccess();
        },
        (err : any) => {
            dispatch({
                type: GET_NOTIFICATION_LOGS_FAILURE,
                model: null,
                searchParams: currentParams
            } as unknown as NotificationLogsInStoreAction);
            onFailure(err);
        }
    );
}
import React, {useState} from 'react';
import '../../../../styles/layout/index.less';
import {Modal, Button, message} from "antd";
import {useHistory} from 'react-router-dom';
import {connect} from "react-redux";
import {
    updateUserDeletionInStoreAction,
    cancelUserDeletionInStoreAction
} from "../../../../core/actions/dashboard/users/updateUserDetailsInStore";
import {DeleteFilled} from '@ant-design/icons';


const UserDetailsDelete: React.FC = (props: any) => {
    const history = useHistory();
    const isScheduled = props.userDetails?.userDeletionModel?.isScheduledForDeletion;
    const isEligible = props.userDetails?.userDeletionModel?.isEligibleForDeletion;
    const userId = props.userDetails?.userDetails?.id;
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDeletionLoading, setDeletionLoading] = useState(false);
    const [isCancelLoading, setCancelLoading] = useState(false);

    const finishState = () => {
        setDeletionLoading(false)
        setCancelLoading(false);
        setModalOpen(false);
    }

    const onScheduling = () => {
        finishState();
        message.success('User scheduled for deletion successfully!');
    }

    const onDeletionFailure = (errors: string[]) => {
        finishState();
        message.error('User deletion, or deletion scheduling failed!');
        // for(let error of errors){
        //     message.error(error);
        // }

    }

    const onDeletion = () => {
        history.push('/admin/users');
        message.success('User deleted successfully!');
    }

    const onCancellation = () => {
        finishState();
        message.success('User deletion cancelled successfully!');
    }

    const onCancellationFailure = (errors: string[]) => {
        finishState();
        message.error('User deletion cancellation failed!');
        for(let error of errors){
            message.error(error);
        }
    }

    const scheduleForDeletion = () => {
        setDeletionLoading(true);
        props.updateUserDeletionInStoreAction(props.userDetails, userId, isScheduled, onScheduling, onDeletion, onDeletionFailure);
    }

    const cancelDeletion = () => {
        setCancelLoading(true);
        props.cancelUserDeletionInStoreAction(props.userDetails, userId, onCancellation, onCancellationFailure);
    }

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const scheduledDoc = (
        <>
            <Button className="cancel-request-deletion" type="primary"
                    onClick={cancelDeletion}
                    loading={isCancelLoading}
            >Cancel request for deletion</Button>

            <Button className={isEligible ? 'delete-button' : 'delete-button-disabled'} type="primary"
                    disabled={!isEligible}
                    loading={isDeletionLoading}
                    onClick={scheduleForDeletion}
            >Delete</Button>
        </>
    );

    const normalDoc = (
        <>
            {
                isEligible
                    ? <Button className="delete-button" type="primary"
                              disabled={!userId}
                              onClick={openModal}
                              loading={isDeletionLoading}
                        >Delete</Button>
                    : <Button
                        disabled={!userId}
                        loading={isDeletionLoading}
                        onClick={scheduleForDeletion} className="initiate-deletion-button" type="primary">Initiate account
                        deletion</Button>
            }
        </>);

    return (
        <span className="user-status-buttons">
            <Modal
                visible={isModalOpen}
                footer={null}
                width={423}
                closable={false}
                centered
            >
                <div className="delete-modal-wrapper">
                    <div className="delete-modal-text-wrapper-left">
                        <DeleteFilled className="delete-modal-icon"/>
                    </div>

                    <div className="delete-modal-text-wrapper-right">
                        Are you sure you want to delete {props.userDetails?.userDetails?.fullName}?
                    </div>
                </div>
                <div className="delete-modal-button-wrapper">
                    <Button className="cancel-deletion" type="primary"
                            onClick={closeModal}
                    >Cancel</Button>
                    <Button className="delete-button" type="primary"
                            loading={isDeletionLoading}
                                  onClick={scheduleForDeletion}
                    >Delete</Button>
                </div>
            </Modal>
            {isScheduled ? scheduledDoc : normalDoc}
        </span>);
};


export default connect(null, {
    updateUserDeletionInStoreAction,
    cancelUserDeletionInStoreAction
})(UserDetailsDelete);
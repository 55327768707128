import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { queryRequest } from '../../../../app/common/requestUtils/requestUtils';
import { AppState } from '../../../commons/reduxStore';

import { TransactionWithdrawalSearch, WithdrawalTransactionModel } from "../../../models/dashboard/transactions/transation";
import transactions from '../../../services/dashboard/transactions/index';

const { getTransactionWithdrawalByTransfer } = transactions;

export const GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_SUCCES = "GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_SUCCES";
export const GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_ERROR = "GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_ERROR";

export interface TransactionWithdrawalByTransferInStoreAction {
    type: typeof GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_SUCCES | typeof GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_ERROR,
    userTransactionsWithdrawal: WithdrawalTransactionModel | null,
    searchParams: TransactionWithdrawalSearch
}


export const updateTransactionWithdrawalByTransferInStoreAction =
    (userTransactionsWithdrawal: WithdrawalTransactionModel | null, searchParams: TransactionWithdrawalSearch)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {

            queryRequest(() => getTransactionWithdrawalByTransfer(searchParams))
                .then((res) => {
                    dispatch({
                        type: GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_SUCCES,
                        userTransactionsWithdrawal: res
                    } as TransactionWithdrawalByTransferInStoreAction)
                })
                .catch((err) => {
                    dispatch({
                        type: GET_TRANSACTIONSWITHDRAWALSBYTRANSFER_ERROR,
                        userTransactionsWithdrawal: userTransactionsWithdrawal
                    } as TransactionWithdrawalByTransferInStoreAction)
                });
        }

import {
    CashbackRequestsCheckedPaginated,
    GetPaginatedData
} from "../../../../models/dashboard/marketplace/marketplaceCashbackRequestModel";
import {adminHttpClient} from "../../../../commons/adminHttpClinet";

const getApprovedCashbackRequest = (model: GetPaginatedData): Promise<CashbackRequestsCheckedPaginated> => {
    return adminHttpClient.get(`/api/Admin/AdminMarketplacePurchase/get-approved-cashback-requests?skip=${model.pageNumber}&take=${model.pageSize}`)
}

export default getApprovedCashbackRequest ;

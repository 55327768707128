const editAffiliateResource = {
    upload:'Upload',
    cancel:'Cancel',
    save:'Save',
    addNew:'Add new',
    title:'Edit affiliate',
    name:'Affiliate name',
    status:'Status',
    statusDescription:'Decide if this category is active or not.',
    platform:'Platform',
    category:'Category',
    url:'URL',
    accessUrl:'Access URL',
    secondUrl:'Second access URL (Optional)',
    promotions:'Promotions',
    promoName:'Promotion name',
    userCashBack:'User cashback percentage',
    guacCashBack:'Guac cashback percentage',
    defaultPromotionName:'Default Promotion',
    validation:{
        nameMaxCharacters:'You have reached your maximum limit of characters allowed',
        nameRequired:'Affilite name is required',
        iconUrl:'Image is required',
        accessUrlValid:'Must be a valid URL',
        accessUrlRequired:'Must be a valid URL',
        secondAccessUrlValid:'Access Url is required',
        platformRequired:'Platform is required',
        categoryRequired:'Category is required',
        promotionNameRequired:'Promotion name is required',
        cashbackPercentageValidation:'Cashback percentage must be greater than 0',
        cashbackPercentageRequired:'CashBack percentage is required',        
        guacCashBackPercentageValidation:'Cashback percentage must be greater than 0',
        guacCashBackPercentageRequired:'Guac Cashback percentage is required',
    }
}

export default editAffiliateResource;
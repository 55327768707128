import { GET_WITHDRAWLIST_ERROR, GET_WITHDRAWLIST_SUCCESS, WithdrawListInStoreAction } from "../../../actions/dashboard/moveMoney/updateWithdrawListInStoreAction";
import { PaginatedWithdrawals, WithdrawStatisticsModel } from "../../../models/dashboard/withdraw/withdraw";

export const updateWithdrawListInStoreReducer = (state: PaginatedWithdrawals | null = null,
    action: WithdrawListInStoreAction) => {
    switch (action.type) {
        case GET_WITHDRAWLIST_SUCCESS:
            return { ...state, ...action.withdrawList }
        case GET_WITHDRAWLIST_ERROR:
            return { ...state };
        default:
            return { ...state };
    }
}
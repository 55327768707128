import {AdminNotificationSendJobModel} from "../../../models/dashboard/notification/notification";
import {
    GET_NOTIFICATION_JOBS_FAILURE,
    GET_NOTIFICATION_JOBS_SUCCESS,
    NotificationJobsInStoreAction
} from "../../../actions/dashboard/notification/updateNotifcationJobsInStore";


const jobInitialState = {
    totalItemCount : 0,
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    items: []
} as AdminNotificationSendJobModel;


export const updateNotificationJobsInStoreReducer = (
    state: AdminNotificationSendJobModel | null = jobInitialState,
    action: NotificationJobsInStoreAction) => {
    switch (action.type) {
        case GET_NOTIFICATION_JOBS_SUCCESS:
            return {
                ...state,
                ...action.model
            };
        case GET_NOTIFICATION_JOBS_FAILURE:
            return {...state};
        default:
            return {...state};
    }
}
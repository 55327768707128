import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { AppState } from "../../commons/reduxStore";
import { Statistics } from "../../models/dashboard/statistics";
import { queryRequest } from "../../../app/common/requestUtils/requestUtils";
import getStatistics from "../../services/dashboard/getStatistics";

export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
export const GET_STATISTICS_ERROR = "GET_STATISTICS_ERROR";

export interface StatisticsInStoreAction {
  type: typeof GET_STATISTICS_SUCCESS | typeof GET_STATISTICS_ERROR;
  statistics: Statistics | null;
}



export const updateStatisticsInStoreAction = (statistics: Statistics | null):
  ThunkAction<void, AppState, unknown, Action> => dispatch => {
    queryRequest(() => getStatistics()).then((statistics) => {
      dispatch({
        type: GET_STATISTICS_SUCCESS,
        statistics: statistics
      } as StatisticsInStoreAction)
    })
    .catch((err) => {
      dispatch({
        type: GET_STATISTICS_ERROR,
        statistics: statistics
      } as StatisticsInStoreAction)
    });
  };


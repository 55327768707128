import { Badge, Statistic } from "antd"
import { StatisticProps } from "antd/lib/statistic/Statistic"
import React from "react"
 export interface StatisticsComponentProps extends StatisticProps {
     title: string,
     badgeColor: string,
 }

const StatisticComponent : React.FC<StatisticsComponentProps> = props => {
    return (
        <>
          <Statistic title={props.title} suffix={<Badge color={props.badgeColor} />}  value={props.value} />
        </>
    )
}
export default StatisticComponent;



import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../../../core/commons/reduxStore";

import "../../../../../styles/layout/dashboard/marketplace/amazon/amazonCashbackRequest.less"
import { AmazonItemOrdersModel, CashbackRequestModel, CashbackRequestsCheckedPaginated, GetPaginatedData} from "../../../../../core/models/dashboard/marketplace/marketplaceCashbackRequestModel";
import {updateApprovedCashbackRequestInStoreAction} from "../../../../../core/actions/dashboard/marketplace/amazon/updateApprovedCashbackRequestsInStore";
import moment from "moment";
import {Button, Card, Empty, Image, Spin, Table} from "antd";
import {FullscreenOutlined} from "@ant-design/icons";
import { newGuid } from "../../../../common/utils/Utils";
import ImagePreviewGroupComponent from "../../../../common/components/dashboard/ImagePreviewGroupComponent";

export interface ApprovedCashbackRequestsProps {
    approvedCashbackRequest: CashbackRequestsCheckedPaginated | null,
    updateApprovedCashbackRequestInStoreAction: (approvedCashbackRequest: CashbackRequestsCheckedPaginated | null,model: GetPaginatedData) => void;
}

let initSearchParams: GetPaginatedData = {
    pageNumber:1,
    pageSize: 10
}

const ApprovedCashbackRequestsComponent: React.FC<ApprovedCashbackRequestsProps> = (props: ApprovedCashbackRequestsProps) => {

    const [visible, setImageVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [approvedCashbackRequests, setApprovedCashbackRequests] = useState<CashbackRequestsCheckedPaginated | null>(null)

    const [keyExpanded, setKeyExpanded] = useState<Array<string>>([]);

    useEffect(()=>{
        setIsLoading(true);
        props.updateApprovedCashbackRequestInStoreAction(null,initSearchParams)
    },[])
    
    useEffect(()=>{
        setIsLoading(false);
        setApprovedCashbackRequests(props.approvedCashbackRequest);
    },[props.approvedCashbackRequest]);

    const setFixedColumn = (value: boolean) =>{        
    }

    const getInvoiceUrls = (invoicesUrls: Array<string>) => {
        return (
            <>
                {invoicesUrls.length > 0 &&
                <>
                      <ImagePreviewGroupComponent key={newGuid()} imageUrls={invoicesUrls} isOpen={visible} isParent={true}
                       changeVisibility={setFixedColumn}  />
                </>
                }
            </>
        );
    }

    const columns = [
        {
            title: 'User', dataIndex: 'user.fullName',
            key: 'user.fullName',
            width: 220,
            render: (id: string, row: CashbackRequestModel) => (
                <>
                    {row.user.fullName}
                </>
            )
        },
        {
            title: 'Screenshot',
            dataIndex: 'invoicesUrls',
            width: 270,
            key: 2,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    {getInvoiceUrls(row.invoicesUrls)}
                </>
            )
        },
        {
            title: 'Guac affiliate click date',
            dataIndex: 'marketplaceClickDate',
            width: 270,
            key: 2,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.amazonDetails.marketplaceClickDate ? `${moment(new Date(row.purchaseDetails.amazonDetails.marketplaceClickDate)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Request date',
            dataIndex: 'createdAt',
            width: 270,
            key: 2,
            render: (createdAt: any, row: CashbackRequestModel) => (
                <>
                    <> {row.createdAt ? `${moment(new Date(row.createdAt)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Transaction date (Plaid)',
            dataIndex: 'transactionDate',
            width: 270,
            key: 2,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.amazonDetails.authorizedDate ? `${moment(new Date(row.purchaseDetails.amazonDetails.authorizedDate)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        } ,
        {
            title: 'Approved at',
            dataIndex: 'lastModifiedAt',
            width: 270,
            key: 2,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.lastModifiedAt ? `${moment(new Date(row.lastModifiedAt)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Is Claimed',
            dataIndex: 'isClaimed',
            width: 270,
            key: 2,
            render: (isClaimed: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.isClaimed ? 'True' : 'False' }</>
                </>
            )
        },
        {
            title: 'Claimed Date',
            dataIndex: 'claimedDate',
            width: 270,
            key: 2,
            render: (claimedDate: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.claimedDate ? `${moment(new Date(row.purchaseDetails.claimedDate)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        }       
    ]

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
     
        if (pagination) {
            if (approvedCashbackRequests?.pageNumber != pagination.current) {
                initSearchParams.pageNumber = pagination.current;
            }
        };

        props.updateApprovedCashbackRequestInStoreAction(null, initSearchParams);
        setIsLoading(true);
    }

    const onTableRowExpand = (expanded: boolean, record: CashbackRequestModel) => {
        if (record.purchaseDetails.amazonDetails.orders?.length == 0) {
        
            setKeyExpanded([]);

        } else {
            let keys: Array<string> = [];
            if (expanded) {
                keys.push(record.id);
            }
            setKeyExpanded(keys);
        }
    }

    const nestedColumns = [
            {
                title: 'Category',
                dataIndex: 'category',
                width: 270,
                key: 'orderReport-1',
                render: (category: any, row: AmazonItemOrdersModel) => (
                    <>
                        {row.category}
                    </>
                )
            },
            {
                title: 'Product name',
                dataIndex: 'name',
                key: 'orderReport-2',
                render: (name: any, row: AmazonItemOrdersModel) => (
                    <>
                        {row.name}
                    </>
                )
            },
            {
                title: 'ASIN',
                dataIndex: 'asin',
                key: 'orderReport-3',
                render: (asin: any, row: AmazonItemOrdersModel) => (
                    <>
                        {row.asin}
                    </>
                )
            },
    
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'orderReport-4',
                render: (quantity: any, row: AmazonItemOrdersModel) => (
                    <>
                        {row.quantity}
                    </>
                )
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'orderReport-5',
                render: (price: any, row: AmazonItemOrdersModel) => (
                    <>
                        {row.price}
                    </>
                )
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'orderReport-6',
    
                render: (date: any, row: AmazonItemOrdersModel) => (
                    <>
                        <> {row.orderDate ? `${moment(new Date(row.orderDate)).format("MM-DD-YYYY")}` : ("")} </>
                    </>
                )
            }        
    ]

    return (
     <>
         <Spin spinning={isLoading}>
         <Table
             locale={{
                 emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..."/>
             }}
             pagination={{
                 total: approvedCashbackRequests?.totalItemCount, current:approvedCashbackRequests?.pageNumber,
                 pageSize: approvedCashbackRequests?.pageSize,
                 showSizeChanger: false,
             }}
             onRow={(record: any) => ({})}
             sortDirections={['ascend', 'descend', 'ascend']}
             scroll={{x: 1200}}
             onChange={(onChangeEvent)}
             columns={columns}
             dataSource={approvedCashbackRequests?.items}
             rowKey="id"

            onExpand={onTableRowExpand}
            expandedRowKeys={keyExpanded}
             expandable={{
                 expandedRowRender: (record: CashbackRequestModel) => <>
                     <Card>
                         <Table
                             pagination={false}
                             rowKey="index"   
                             scroll={{x: 1200}}                     
                             dataSource={record.purchaseDetails.amazonDetails.orders}
                             columns={nestedColumns}
                         />
            
                     </Card>
            
                 </>,
                 rowExpandable: (record: CashbackRequestModel) => ((record.purchaseDetails.amazonDetails.orders && record.purchaseDetails.amazonDetails.orders.length > 0) ? true: false),
             }}

         />
         </Spin>
     </>
    )
}

const mapStateToProps = ({approvedCashbackRequest}: AppState) => ({
    approvedCashbackRequest:approvedCashbackRequest,
});

export default connect(mapStateToProps, {
    updateApprovedCashbackRequestInStoreAction,

})
(ApprovedCashbackRequestsComponent);
import {
    BankAccountDetailsInStoreAction, initialDetails, GET_BANK_ACCOUNT_ERROR,
    GET_BANK_ACCOUNT_SUCCESS
} from "../../../actions/dashboard/users/updateBankAccountDetailsInStore";
import {PlaidDetailsBankAccount} from "../../../models/dashboard/users/plaidDetails";

export const updateBankAccountDetailsInStoreReducer = (state: PlaidDetailsBankAccount = initialDetails, action: BankAccountDetailsInStoreAction) =>{
    switch (action.type){
        case GET_BANK_ACCOUNT_SUCCESS:
            return {...state, ...action.details};
        case GET_BANK_ACCOUNT_ERROR:
            return {...state, ...initialDetails};
        default:
            return {...state};
    }
}
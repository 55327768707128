import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../commons/reduxStore";
import { WithdrawStatisticsModel } from "../../../models/dashboard/withdraw/withdraw";
import getWithdrawalStatistics from "../../../services/dashboard/moveMoney/getWithdrawalStatistics";


export const GET_WITHDRAWAL_STATISTICS_SUCCESS = "GET_WITHDRAWAL_STATISTICS_SUCCESS";
export const GET_WITHDRAWAL_STATISTICS_ERROR = "GET_WITHDRAWAL_STATISTICS_ERROR";

const initiateStatistics: WithdrawStatisticsModel = {
    withdrawStatisticsLastMonth: {
        standardWithdrawStatisticsModel: {
            totalStandardWithdraw: 0,
            totalAmountStandardWithdraw: 0,
        },
        rapidWithdrawStatisticsModel: {
            totalAmountRapidWithdraw: 0,
            totalRapidWithdraw: 0,
            totalFeeRapidWithdraw:0,
            rapidWithdrawStatisticsRulesModels: []
        }
    },
    withdrawStatisticsTotal: {
        standardWithdrawStatisticsModel: {
            totalStandardWithdraw: 0,
            totalAmountStandardWithdraw: 0,
        },
        rapidWithdrawStatisticsModel: {
            totalAmountRapidWithdraw: 0,
            totalRapidWithdraw: 0,
            totalFeeRapidWithdraw:0,
            rapidWithdrawStatisticsRulesModels: []
        }
    }
}
export interface WithdrawalStatisticsInStoreAction {
    type: typeof GET_WITHDRAWAL_STATISTICS_SUCCESS | typeof GET_WITHDRAWAL_STATISTICS_ERROR,
    withdrawalStatisticsData: WithdrawStatisticsModel | null,
}
export const updateWithdrawalStatisticsInStoreAction = (
    onSuccess: Function,
    onFailure: Function
)
    : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        queryRequest(() => getWithdrawalStatistics())
            .then((res: WithdrawStatisticsModel) => {
                dispatch({
                    type: GET_WITHDRAWAL_STATISTICS_SUCCESS,
                    withdrawalStatisticsData: res
                } as WithdrawalStatisticsInStoreAction);
                onSuccess();
            })
            .catch((err) => {
                dispatch({
                    type: GET_WITHDRAWAL_STATISTICS_ERROR,
                    withdrawalStatisticsData: initiateStatistics
                } as WithdrawalStatisticsInStoreAction);
                onFailure();
            });
    }
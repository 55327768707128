
const config: Map<string, any> = new Map<string, any>();

config.set("local", {
  // apiEndpoint: "http://localhost:7500",

  apiEndpoint: "https://api-prod-dashboard.guacapp.com",
  // apiEndpoint: "https://api-dev-dashboard.guacapp.com",
  env: 'local'
});

config.set("development", {
  //apiEndpoint: "https://api-qa-dashboard.guacapp.com",
  apiEndpoint: "https://api-dev-dashboard.guacapp.com",
  env: 'development'
});

config.set("production", {
  apiEndpoint: "https://api-prod-dashboard.guacapp.com",
  env: 'production'
});

config.set("internal", {
  apiEndpoint: "https://api-prod-internal-dashboard.guacapp.com",
  env: 'internal'
});

const env = config.get(process.env.REACT_APP_ENV as string);

export default {
  apiEndpoint: env.apiEndpoint,
  env: env.env
}
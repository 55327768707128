import {TotalMarketplacePurchases} from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import {
    GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_FAILURE, GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_SUCCESS,
    GetTotalMarketplacePurchasesInStoreAction
} from "../../../actions/dashboard/marketplacePurchase/getTotalMarketplacePurchasesInStore";


const initialAmount = {
    amount : 0,
    count: 0
} as TotalMarketplacePurchases;

export const getTotalMarketplacePurchasesInStoreReducer = (state: TotalMarketplacePurchases | null = initialAmount, action: GetTotalMarketplacePurchasesInStoreAction) => {
    switch (action.type){
        case GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_SUCCESS:
            return {...action.total};
        case GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_FAILURE:
            return {...state};
        default:
            return {...state};
    }
}
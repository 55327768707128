import {message} from "antd";

const queryRequest = async (func: () => {}, reload = true): Promise<any> => {
    try {
        return await func();
    } catch (err) {
        if (!err || !err.status) {
            message.error("There was an error while processing your request");
            return;
        }
        if (err.status === 401) {

            if (reload) window.location.replace("/login");
        } else if (err.status && err.status === 400 && err.data) {
            return {
                errors: err.data?.Message
            }
        } else {
            message.error("There was an error while processing your request");
        }
    }
};

const commandRequest = async (func: () => {}, reload = false): Promise<any> => {
    try {
        return await func();
    } catch (err) {
        if (!err || !err.status) {
            return {
                errors: [err?.data?.Message]
            };
        }

        if (err.status === 401 && localStorage.getImpersonatorToken()) {
            window.location.replace("/login");
            return {
                errors: ["You are not allowed to make actions while impersonating"]
            };
        }

        if (err.status === 401) {
            if (reload) window.location.replace("/login");
        } else if (err.status && err.status === 400 && err.data) {
            // throw new Error(  err.data?.Message);
            throw err.data?.Message;
            // return {
            //   errors: err.data?.Message
            // }
            // return {
            //   errors: err.data.map((err: Error) => err.message)
            // };
        } else {
            return {
                errors: [err?.data?.Message]
            };
        }
    }
};

export {queryRequest, commandRequest};

import React, { useState } from "react";
import {Deposits, DirectDeposits, TransferType} from "../../../../core/models/dashboard/transactions/transation";
import {Col, Empty, Spin, Table} from "antd";
import TransactionTypeDetailsComponent from "../../../common/components/dashboard/TransactionTypeDetailsComponent";
import {ReactComponent as DefaultBank} from "../../../../assets/svg/default-bank.svg";
import {CurrencyNumberFormat} from "../../../common/utils/Utils";
import {number} from "yup";
import SavingDepositComponent from "../savingsActivity/SavingsDepositComponent";

export interface TransactionsDirectDepositComponentProps {
    userId: string,
    transferId: string,
    directDeposits: Array<DirectDeposits>
}


const TransactionsDirectDepositComponent: React.FC<TransactionsDirectDepositComponentProps> = props => {

    const columns = [
        {
            title: 'From',
            key: '0',
            render: (data: any) => (
                <Col span={8}>

                    <span className="item-value">
                        <img alt="Bank account" width="24" height="24" style={{borderRadius: '100%'}}
                            src={data.iconUrl}
                        />
                    </span>
                    <span style={{paddingLeft: '6px', fontSize:'1.07em', verticalAlign: 'text-top'}}>
                         {data?.bankName}
                    </span>
                </Col>
            )
        },
        {
            title: 'To',
            dataIndex: 'goalName',
            key: '1',
            render: (goal: string) => (
                <span>{goal}</span>
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: '2',
            render: (amount: any) => (
                <span>{amount ? CurrencyNumberFormat(amount) : ("-")}</span>
            )
        },
        {
            title: 'Percentage',
            dataIndex: 'percentage',
            key: '3',
            render: (percentage: number) => (
                <span>{parseInt(`${percentage * 100}`)}%</span>
            )
        }];


    return (<>
        <Spin spinning={false}>
            <TransactionTypeDetailsComponent {...{transferType:TransferType.DirectDeposit}}/>
            <Table
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
                rowKey="id"
                columns={columns}
                dataSource={props.directDeposits} />
        </Spin>
    </>);
}


export default TransactionsDirectDepositComponent;



import { GetListOfUserSubscriptions, UserSubscriptions, SubscriptionPlans } from '../../../../models/dashboard/subscriptions/subscriptions';
import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../../commons/reduxStore";
import getSubscriptionPlans from '../../../../services/dashboard/subscriptions/getSubscriptionPlans';

export const GET_SUBSCRIPTIONPLANS_SUCCESS = "GET_SUBSCRIPTIONPLANS_SUCCESS";
export const GET_SUBSCRIPTIONPLANS_ERROR = "GET_SUBSCRIPTIONPLANS_ERROR";
var initData : Array<SubscriptionPlans> = [];

export interface SubscriptionPlansInStoreAction {
    type: typeof GET_SUBSCRIPTIONPLANS_SUCCESS | typeof GET_SUBSCRIPTIONPLANS_ERROR,
    subscriptionPlans: Array<SubscriptionPlans>
}

export const updateSubscriptionPlansInStoreAction = ()
    : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        queryRequest(() => getSubscriptionPlans())
            .then((res: Array<SubscriptionPlans>) => {
                dispatch({
                    type: GET_SUBSCRIPTIONPLANS_SUCCESS,
                    subscriptionPlans: res
                } as SubscriptionPlansInStoreAction);
            })
            .catch((err) => {
                dispatch({
                    type: GET_SUBSCRIPTIONPLANS_ERROR,
                    subscriptionPlans: initData
                } as SubscriptionPlansInStoreAction);
            })
    }
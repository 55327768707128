import { Alert, Button, Checkbox, Col, Input, Layout, Row } from "antd";
import { CheckCircleFilled, LockOutlined, UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Content } from "antd/lib/layout/layout";

import { commandRequest } from "../../common/requestUtils/requestUtils";
import store from "../../../core/commons/localstorage";

import GuacHeader from "../GuacHeader";
import GuacFooter from "../GuacFooter";
import '../../../styles/layout/index.less';

import { User } from "../../../core/services/accounts/getUserProfile";
import services from "../../../core/services";

const { passwordRevery } = services;

interface ForgotPasswordProps {
    updateUserInStore: (user: User | null) => void;
}

interface FormValues {
    email: string;
}

const initialValues: FormValues = {
    email: "",
};

const formValidator = () =>
    Yup.object({
        email: Yup.string().email('Invalid email address').max(50).required('Email is required')
    });


const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
    let history = useHistory();
    const [isSubmitting, setSubmiting] = useState(false);
    const [isEmailSent, setSendingEmail] = useState(false);
    const [isEmailResent, setReSendingEmail] = useState(false);
    const [formValue, setFormValue] = useState<FormValues>()
    const [errors, setErrors] = useState<string[]>([]);
    const maxImputSize = 50;

    const onSubmit = async (values: FormValues) => {
        setErrors([]);
        setSubmiting(true);
        const res = await commandRequest(() => passwordRevery(values.email));
        if (!res.errors) {
            setSubmiting(false);
            setSendingEmail(true);
            setFormValue(values);

        } else {
            setSubmiting(false);
            setSendingEmail(false);
            setErrors(res.errors);
        }
    };

    const reSendEmailRecovery = async () => {
        if (formValue) {
            const res = await commandRequest(() => passwordRevery(formValue.email));
            if (!res.errors) {
                setReSendingEmail(true);

            } else {
                setErrors(res.errors);
            }
        }
    }

    return (
        <Layout>
            <Content>
                <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                 
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={formValidator}
                            onSubmit={onSubmit}>

                            {formik => (
                                <form onSubmit={formik.handleSubmit} autoComplete="off">
                                    <Row className="reset-password-widget">
                                        <GuacHeader />
                                        {(!isSubmitting && !isEmailSent) &&

                                            <Row className="forgot-password-form" >
                                                <Row className="content">
                                                    <p className="header-title">Forgot password?</p>
                                                    <p className="subheader-title">Enter your email to receive password reset instructions.</p>
                                                            {errors.length > 0 && (
                                                                <Alert type="error" className="error-alert" closable message={errors[0]} showIcon />
                                                            )}
                                                </Row>
                                                <Row className="form">
                                                    <Input size="large" id="email" name="email"
                                                        className="email-input"
                                                        placeholder="Email"
                                                        maxLength={maxImputSize}
                                                        onChange={formik.handleChange}
                                                        prefix={<UserOutlined className="icon-styles" />} />
                                                    {formik.errors.email && formik.touched.email && (
                                                        <div className="ant-form-item-explain ant-form-item-explain-error">
                                                            <div role="alert">{formik.errors.email}</div>
                                                        </div>
                                                    )}
                                                </Row>
                                                <Row className="form">
                                                    <Button htmlType="submit" type="primary"
                                                        disabled={!formValidator().isValidSync(formik.values)}
                                                        className={(formValidator().isValidSync(formik.values)) ? "forgot-submit-button active" : "forgot-submit-button disabled"}
                                                        block title="Log In" shape="round"
                                                        size="large" loading={isSubmitting}>Send instructions</Button>
                                                </Row>
                                            </Row>
                                        }

                                        {isEmailSent &&

                                            <Row className="forgot-password-form" >
                                                <Row className="instruction-sent-form">
                                                    <Col >
                                                        <CheckCircleFilled className="circle-check" />
                                                    </Col>
                                                    <Row className="content">
                                                        {isEmailResent ?
                                                            (<p className="header-title">Instructions <span className="resent">resent</span></p>) : (<p className="header-title">Instructions sent</p>)
                                                        }
                                                        <p className="subheader-title">
                                                            We just sent an email to <b>{formik.values.email}</b> with the instructions for the password reset. Be sure to check your spam folder, too.
                                                        </p>
                                                    </Row>
                                                    <Row className="resend-link" >
                                                        <p> Didn’t get the email?</p>
                                                        <Button onClick={reSendEmailRecovery} className="resend-anchor-link" type="link">
                                                            Resend
                                                        </Button>
                                                    </Row>
                                                </Row>
                                            </Row>
                                        }
                                        <GuacFooter />
                                    </Row>
                                </form>
                            )}
                        </Formik>
                  
                </Row>
            </Content>
        </Layout>
    )
}
export default connect(null, null)(ForgotPassword);
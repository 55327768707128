import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import {CashbackRequestModel} from "../../../../models/dashboard/marketplace/marketplaceCashbackRequestModel";
import {AppState} from "../../../../commons/reduxStore";
import {executeAction} from "../../../../commons/baseAction";
import {queryRequest} from "../../../../../app/common/requestUtils/requestUtils";
import getMarketplaceCashbackRequest  from "../../../../services/dashboard/marketplace/amazon/getMarketplaceCashbackRequest";



export const GET_MARKETPLACECASHBACK_REQUEST_SUCCESS ="GET_MARKETPLACECASHBACK_REQUEST_SUCCESS";
export const GET_MARKETPLACECASHBACK_REQUEST_ERROR ="GET_MARKETPLACECASHBACK_REQUEST_ERROR";

export interface MarketplaceCashbackRequestInStoreAction {
    type: typeof GET_MARKETPLACECASHBACK_REQUEST_SUCCESS | typeof GET_MARKETPLACECASHBACK_REQUEST_ERROR,
    marketplaceCashbackRequest: Array<CashbackRequestModel> | []
}

export const updateMarketplaceCashbackRequestInStoreAction =
(marketplaceCashbackRequest: Array<CashbackRequestModel> | [])
: ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(()=> getMarketplaceCashbackRequest()),
        (res: any) =>{
            dispatch({
                type: GET_MARKETPLACECASHBACK_REQUEST_SUCCESS,
                marketplaceCashbackRequest: res
            } as MarketplaceCashbackRequestInStoreAction);
        },
        (_: any) => {
            dispatch({
                type: GET_MARKETPLACECASHBACK_REQUEST_ERROR,
                marketplaceCashbackRequest: marketplaceCashbackRequest
            } as MarketplaceCashbackRequestInStoreAction);
        }
    );
    
}
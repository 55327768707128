import store from "../../../core/commons/localstorage";
import jwt_decode from "jwt-decode";
export class UserContext {
    userId: string;
    username: string;
    isAuthenticated: boolean;
    token: string | null;
    expiryToken?: Date | null;

    constructor() {
        this.userId = '';
        this.username = '';
        this.isAuthenticated = false;
        this.token = null;
        this.expiryToken = null;
        let token = store.getToken();

        if (token) {
            let expDate = this.getExpirationDate(token);
            if (expDate) {
                this.isAuthenticated = !this.isExpired(expDate)
            } else
                this.isAuthenticated = false;
        }

    }

    getExpirationDate = (jwtToken?: string): number | null => {
        if (!jwtToken) {
            return null;
        }

        const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

        // multiply by 1000 to convert seconds into milliseconds
        return jwt && jwt.exp && jwt.exp * 1000 || null;
    };
    isExpired = (exp: number) => {
        if (!exp) {
            return false;
        }

        return new Date().getTime() > exp;
    };

}
import getAnnualRapidWithdrawals from "./getAnnualRapidWithdrawStatistics";
import getMoveMoneyByUser from "./getMoveMoneyByUser";
import getWithdrawalStatistics from "./getWithdrawalStatistics";
import getWithdrawList from "./getWithdrawList";

const moveMoney = {
    getMoveMoneyByUser,
    getWithdrawalStatistics,
    getAnnualRapidWithdrawals,
    getWithdrawList
}

export default moveMoney;
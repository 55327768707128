import {
    PaymentOptionDetailsInStoreAction,
    GET_PAYMENT_OPTION_SUCCESS,
    GET_PAYMENT_OPTION_ERROR, initialDetails
} from "../../../actions/dashboard/users/updatePaymentOptionDetailsInStore";
import {PlaidDetailsPaymentOption} from "../../../models/dashboard/users/plaidDetails";

export const updatePaymentOptionDetailsInStoreReducer = (state: PlaidDetailsPaymentOption  = initialDetails, action: PaymentOptionDetailsInStoreAction) =>{
    switch (action.type){
        case GET_PAYMENT_OPTION_SUCCESS:
            return {...state, ...action.details};
        case GET_PAYMENT_OPTION_ERROR:
            return {...state, ...initialDetails};
        default:
            return {...state};
    }
}
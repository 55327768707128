import {MarketplaceProgramReserve} from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../../../commons/reduxStore";
import {Action} from "redux";
import {executeAction} from "../../../commons/baseAction";
import {queryRequest} from "../../../../app/common/requestUtils/requestUtils";
import getMarketplaceProgramReserve from "../../../services/dashboard/marketplacePurchase/getMarketplaceProgramReserve";

export const GET_PROGRAM_RESERVE_SUCCESS = "GET_PROGRAM_RESERVE_SUCCESS";
export const GET_PROGRAM_RESERVE_FAILURE = "GET_PROGRAM_RESERVE_FAILURE";

export interface GetMarketplaceProgramReserveInStoreAction {
    type: typeof GET_PROGRAM_RESERVE_SUCCESS | typeof GET_PROGRAM_RESERVE_FAILURE,
    programReserve: MarketplaceProgramReserve | null
}

export const getMarketplaceProgramReserveInStoreAction
    = (programReserve: MarketplaceProgramReserve): ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(() => getMarketplaceProgramReserve()),
        (res: MarketplaceProgramReserve) => {
            dispatch({
                type: GET_PROGRAM_RESERVE_SUCCESS,
                programReserve: res
                } as GetMarketplaceProgramReserveInStoreAction)
        },
        (_: any) => {
            dispatch({
                type: GET_PROGRAM_RESERVE_FAILURE,
                programReserve: programReserve
            } as GetMarketplaceProgramReserveInStoreAction)
        }
    );
}
import {JobRole, JobStatus, JobType} from "../../../../core/models/dashboard/notification/notification";
import React from "react";

export const getJobType = (type: JobType): string => {
    if(type?.valueOf() === JobType.AdminNotifications.valueOf())
        return "Admin Notification";
    return  "";
}

export const GetJobStatus: React.FC<any> =  (props: any) => {
    let color = '#525252';
    let text = 'UNKNOWN';
    switch (props.status?.valueOf()){
        case JobStatus.Enqueued.valueOf():
            color='#5fa4b8';
            text='ENQUEUED';
            break;
        case JobStatus.Scheduled.valueOf():
            color='#12b1d6';
            text='SCHEDULED';
            break;
        case JobStatus.Awaiting.valueOf():
            color='#59c8e2';
            text='AWAITING';
            break;
        case JobStatus.Processing.valueOf():
            color='#FA8C16';
            text='PROCESSING';
            break;
        case JobStatus.Failed.valueOf():
            color='#F5222D';
            text='FAILED';
            break;
        case JobStatus.Succeeded.valueOf():
            color='#3AB100';
            text='SUCCEEDED';
            break;
        case JobStatus.Deleted.valueOf():
            color='#850808';
            text='DELETED';
            break;
        default:
            break;
    }

    return (<span
        style={{
            fontWeight: 'bold',
            color: color
        }}
    >
        {text}
    </span>);
}

export const GetJobRole: React.FC<any> =  (props: any) => {
    let color = '#525252';
    let text = 'UNKNOWN';
    switch (props.role?.valueOf()){
        case JobRole.Root.valueOf():
            color='#FA8C16';
            text='ROOT JOB';
            break;
        case JobRole.Node.valueOf():
            color='#5fa4b8';
            text='NODE';
            break;
        case JobRole.Leaf.valueOf():
            color='#3AB100';
            text='SUB JOB';
            break;
        default:
            break;
    }

    return (<span
        style={{
            fontWeight: 'bold',
            color: color
        }}
    >
        {text}
    </span>);
}
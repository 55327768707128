import {
    GET_NOTIFICATION_TEMPLATES_SUCCESS,
    GET_NOTIFICATION_TEMPLATES_FAILURE,
    NotificationTemplatesInStoreAction,
} from '../../../actions/dashboard/notification/updateNotificationTemplatesInStore';
import {
    NotificationTemplateModel
} from "../../../models/dashboard/notification/notification";

const initialState = {
    totalItemCount : 0,
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    items: []
} as NotificationTemplateModel;


export const updateNotificationInStoreReducer = (
    state: NotificationTemplateModel | null = initialState,
    action: NotificationTemplatesInStoreAction) => {
    switch (action.type) {
        case GET_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                ...action.model
            };
        case GET_NOTIFICATION_TEMPLATES_FAILURE:
            return {...state};
        default:
            return {...state};
    }
}




import {executeAction} from "../../../commons/baseAction";
import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import users from '../../../services/dashboard/users/index';
import {AppState} from '../../../commons/reduxStore';
import {queryRequest} from '../../../../app/common/requestUtils/requestUtils';

import { PlaidDetailsPaymentOption} from '../../../models/dashboard/users/plaidDetails';

export const GET_PAYMENT_OPTION_SUCCESS = "GET_PAYMENT_OPTION_SUCCESS";
export const GET_PAYMENT_OPTION_ERROR = "GET_PAYMENT_OPTION_ERROR";

const {getPlaidDetails} = users;

export interface PaymentOptionDetailsInStoreAction{
    type: typeof GET_PAYMENT_OPTION_SUCCESS
        | typeof GET_PAYMENT_OPTION_ERROR,
    details: PlaidDetailsPaymentOption,
    selectedUser: string
}

export const initialDetails: PlaidDetailsPaymentOption = {
    item: null,
    status: null,
    requestId: ""
};

export const updatePaymentOptionInStoreAction =
    (selectedUser: string,
     onSuccess: Function,
     onFailure: Function)
     : ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(() => getPlaidDetails(selectedUser, true)),
        (res: any) => {
            dispatch({
                type: GET_PAYMENT_OPTION_SUCCESS,
                details: res as PlaidDetailsPaymentOption,
                selectedUser: selectedUser
            } as PaymentOptionDetailsInStoreAction);
            onSuccess();
        },
        (_: any) =>{
            dispatch({
                type: GET_PAYMENT_OPTION_ERROR,
                details: initialDetails,
                selectedUser: selectedUser
            } as PaymentOptionDetailsInStoreAction);
            onFailure();
        }
    );
};
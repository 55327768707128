import { Col, Row } from "antd";
import React from "react";
import { MoneyTransferActivity, MoveMoneyType, TransferType } from "../../../../core/models/dashboard/transactions/transation";
import "../../../../styles/layout/dashboard/transactions/moveMoneyTransactionActivity.less";
import MoveMoneyTypeComponent from "../../../common/components/dashboard/MoveMoneyTypeComponent";
import { ReactComponent as Withdrawal } from "../../../../assets/svg/money-withdrawal.svg";
import { ReactComponent as MarketplacePurchase } from "../../../../assets/svg/marketplace-purchase.svg";
import { ReactComponent as DefaultBank } from "../../../../assets/svg/default-bank.svg";
import TransactionTypeDetailsComponent from "../../../common/components/dashboard/TransactionTypeDetailsComponent";


export interface MoveMoneyTransactionActivityComponentProps {
    moneyTransferActivity: MoneyTransferActivity;
}

const MoveMoneyTransactionActivityComponent: React.FC<MoveMoneyTransactionActivityComponentProps> = props => {

    return <>
        {props.moneyTransferActivity?.type == MoveMoneyType.Withdrawal &&
            <>
            <TransactionTypeDetailsComponent {...{transferType:TransferType.Withdraw}}></TransactionTypeDetailsComponent>

                <Row className="movemoneyTransations-details" gutter={[16, 16]}>
                    <Col span={8} >
                        <span className="item-title  block">Move money</span>
                        <MoveMoneyTypeComponent {...{ type: MoveMoneyType.Withdrawal }}></MoveMoneyTypeComponent>
                    </Col>
                    <Col span={8} >
                        <span className="item-title">From</span>
                        <span className="item-value">
                            {props.moneyTransferActivity?.nameOfFrom}
                        </span>
                    </Col>
                    <Col span={8} >
                        <span className="item-title"> To</span>
                        <span className="item-value">
                        <DefaultBank className="bank-account-icon"/>  {props?.moneyTransferActivity?.nameOfDestination}
                        </span>
                    </Col>
                </Row>
            </>
        }
        {props.moneyTransferActivity?.type == MoveMoneyType.MarketplacePurchase &&
            <>
            <TransactionTypeDetailsComponent {...{transferType:TransferType.MarketplacePurchase}}></TransactionTypeDetailsComponent>

                <Row className="movemoneyTransations-details" gutter={[16, 16]}>
                    <Col span={8} >
                        <span className="item-title block">Move money</span>
                        <MoveMoneyTypeComponent {...{ type: MoveMoneyType.MarketplacePurchase }}></MoveMoneyTypeComponent>
                        <span className="badge-status block"><MarketplacePurchase />
                            <span className="title">Marketplace purchase</span>
                        </span>

                    </Col>
                    <Col span={8} >
                        <span className="item-title">From</span>
                        <span className="item-value">
                            {props.moneyTransferActivity?.nameOfFrom}
                        </span>
                    </Col>
                    <Col span={8} >
                        <span className="item-title">To</span>
                        <span className="item-value"><DefaultBank className="bank-account-icon"/>  {props?.moneyTransferActivity?.nameOfDestination} </span>
                    </Col>
                </Row>
                <Row className="movemoneyTransations-details" gutter={[16, 16]}>
                    <Col span={12} >
                        <span className="item-title">Associated purchase</span>
                        <span className="item-value">

                        </span>
                    </Col>
                    <Col span={12} >
                        <span className="item-title">Associated affiliate</span>
                        <span className="item-value">
                            {props.moneyTransferActivity?.marketplaceDetails.name}
                        </span>
                    </Col>
                </Row>
            </>
        }
    </>
}

export default MoveMoneyTransactionActivityComponent;
import { UserGoalListInStoreAction, GET_GOALS_LIST_SUCCESS, GET_GOALS_LIST_ERROR } from './../../../actions/dashboard/goals/updateGoalListInStore';
import { Goals } from "../../../models/dashboard/goals/goal";


export const updateUserGoalsListInStoreReducer = (state: Goals | null = null, action: UserGoalListInStoreAction) => {
    switch (action.type) {
        case GET_GOALS_LIST_SUCCESS:
            return {
                ...state,
                ...action?.userGoals
            }
        case GET_GOALS_LIST_ERROR:
            return {...state};
        default :
            return {...state};
    }
}
import { Badge, Empty, Spin, Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { DepositModel, Deposits, SavingStatus, TransactionsDepositSearch, TransferType } from "../../../../core/models/dashboard/transactions/transation";
import { updateTransactionsDepositByTransferInStoreAction } from "../../../../core/actions/dashboard/transactions/updateTransactionsDepositByTransferInStore";
import SavingDepositComponent from "../savingsActivity/SavingsDepositComponent";
import { CurrencyNumberFormat } from "../../../common/utils/Utils";
import TransactionTypeDetailsComponent from "../../../common/components/dashboard/TransactionTypeDetailsComponent";

export interface TransactionsDepositComponentProps {
    userId: string,
    transferId: string,
    userTransactionsDeposit: Array<Deposits>
    // updateTransactionsDepositByTransferInStoreAction: (transactions: DepositModel | null, searchParams: TransactionsDepositSearch) => void;
}

const initSearchParams: TransactionsDepositSearch = {
    pageNumber: 1,
    pageSize: 4,
    searchValue: "",
    orderDescending: true,
    transferId: "",
    userId: ""
};

const TransactionsDepositComponent: React.FC<TransactionsDepositComponentProps> = props => {

    const [keyExpanded, setKeyExpanded] = useState<Array<string>>([]);
    const [isLoading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const getDepositTransactions = () => {
        initSearchParams.userId = props.userId;
        initSearchParams.transferId = props.transferId;
      
    }

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    }

    const columns = [
        {
            title: 'Activity',
            dataIndex: 'name',
            key: '0',
        },
        {
            title: 'Total transaction amount',
            dataIndex: 'amount',
            key: '1',
            render: (amount: any) => (
                <span>{amount ? CurrencyNumberFormat(amount) : ("-")}</span>
            )
        },
        {
            title: 'Saving percentage',
            dataIndex: 'savingPercentage',
            key: '2',
            render: (savingPercentage: any) => (
                <span>{savingPercentage ? `${savingPercentage}%` : ("-")}</span>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: '3',
            render: (status: SavingStatus, row: Deposits) => (
                <>
                    {status == SavingStatus.Completed &&
                        <span>   <Badge color={"#3AB100"} text={"Completed"} /></span>}
                    {status == SavingStatus.Pending &&
                        <span>   <Badge color={"#A6A6A6"} text={"Pending"} /></span>}
                    {status == SavingStatus.Cancelled &&
                        <span>   <Badge color={"#F5222D"} text={"Cancelled"} /></span>}
                </>
            )
        },
        {
            title: 'Deposit amount', dataIndex: 'totalAmountSaved', key: '4',
            render: (totalAmountSaved: any) => (
                <span>{totalAmountSaved ? `$${totalAmountSaved}` : ("-")}</span>
            )
        }
    ]

    const onTableRowExpand = (expanded: boolean, record: Deposits) => {
        let keys: Array<string> = [];
        if (expanded) {
            keys.push(record.id);
        }
        setKeyExpanded(keys);
    }

    return <>
        <Spin spinning={isLoading}>
        <TransactionTypeDetailsComponent {...{transferType:TransferType.Deposit}}/>

            <Table
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
                pagination={{
                    total: props.userTransactionsDeposit.length, current:  currentPage,
                    pageSize:  initSearchParams.pageSize,
                    showSizeChanger: false,
                    onChange: (onPageChange)
                }}
                rowKey="id"
                onExpand={onTableRowExpand}
                expandedRowKeys={keyExpanded}
                expandable={{
                    expandedRowRender: (record: Deposits) => (
                        <>
                            <SavingDepositComponent {...{ savingsDistributionActivities: record.savingsDistributionActivities }}>
                            </SavingDepositComponent>
                        </>
                    ),
                    rowExpandable: (record: Deposits) => (record.savingsDistributionActivities != null),
                }}
                columns={columns} 
                dataSource={props.userTransactionsDeposit} />
        </Spin>
    </>
}
export default TransactionsDepositComponent;
import {isNil} from "../../app/common/utils/Utils";

export const executeAction = (
    action: Promise<any>,
    onSuccess: Function,
    onFailure: Function
) : void => {
    action
        .then((res) => {
            if(!isNil(res) && !res?.errors) {
                onSuccess(res);
            }
            else {
                onFailure(res.errors);
            }
        })
        .catch(err => {
            onFailure(err);
        });
}

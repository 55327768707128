import { DownloadOutlined, DownOutlined, MailOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Col, List, Row, Typography } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { SuggestedAdvertiserExport, SuggestedAdvertisers } from "../../../../core/models/dashboard/marketplace/suggestedAdvertisers";
import "../../../../styles/layout/dashboard/marketplace/suggestedAffiliates.less";
import { updateSuggestedAffiliatesInStoreAction } from "../../../../core/actions/dashboard/marketplace/suggestedAffiliates/updateSuggestedAffiliatesInStore";
import { Spin, Alert } from 'antd';
import { CSVLink } from "react-csv";

export interface SuggestedAffiliatesProps {
    suggestAdvertiser: SuggestedAdvertisers,
    updateSuggestedAffiliatesInStoreAction: (suggestAdvertiser: SuggestedAdvertisers | null) => void;
}

const SuggestedAffiliatesComponent: React.FC<SuggestedAffiliatesProps> = props => {

    const [isMounting, setMounting] = useState(true);
    const [isCollapsed, setCollapsed] = useState(false);

    const collapse = () => {
        setCollapsed(!isCollapsed);
    }

    const getListOfSuggestedAdvertisers = () => {
        props.updateSuggestedAffiliatesInStoreAction(null);
    }

    if (isMounting) {
        getListOfSuggestedAdvertisers();
        setMounting(false);
    }

    const getExportedData = (): Array<SuggestedAdvertiserExport> => {
        let exportedData: Array<SuggestedAdvertiserExport> = [];
        if (props.suggestAdvertiser && props.suggestAdvertiser.topSuggestedAffiliates) {
            props.suggestAdvertiser.topSuggestedAffiliates.forEach(item => {
                
                let categories = (item.advertiser.categoryIds == null) ? [] : item.advertiser.categoryIds.split(',');
                    exportedData.push({
                        Name: item.advertiser.name.replace(/(\r\n|\n|\r)/gm, ""),
                        Description: item.advertiser.description.replace(/(\r\n|\n|\r)/gm, "").replaceAll(",", "."),
                        Payout: item.advertiser.payout.replace(/(\r\n|\n|\r)/gm, "").replaceAll(",", "."),
                        DomainUrl: item.advertiser.domainUrl.replace(/(\r\n|\n|\r)/gm, ""),
                        Category: props.suggestAdvertiser.topAdvertiserCategories.filter(f => categories.includes(f.id.toString())).map(f => f.name).join(","),
                        Country: item.advertiser.country,
                    })
                
            });
        }
        return exportedData;
    }

    const exportButton = () => {
        return (
            <> <span className="export-button">
                <CSVLink filename={"suggested-affiliates.csv"} title="suggested-affiliates" data={getExportedData()} >
                    <Button shape="round" icon={<DownloadOutlined />} >
                    Download CSV
                </Button>
                </CSVLink>
            </span>
            </>
        )
    }

    const cardHeaderTitle = () => {
        return (
            <>{isCollapsed ?
                <><DownOutlined onClick={collapse} /> {`Suggestions`}
                </>
                :
                <>
                    <RightOutlined onClick={collapse} /> {`Suggestions`}
                </>
            }
            </>
        )
    }
    return (
        <>
            <Spin spinning={!props.suggestAdvertiser} tip="Loading...">
                <Card onClick={collapse} className={isCollapsed ? "collapsed-off" : "collapsed-in"} title={cardHeaderTitle()} extra={exportButton()}>

                    <Row className="suggestions-affiliates" gutter={[24, 24]}>

                        <Col className="suggestion-item total" span={8}>
                            <Row className="total-suggestions-box">
                                <span className="icon-center">
                                    <span className="icon-round"><MailOutlined className="icon-styles" /></span>
                                </span>
                                <span className="suggestion-details">
                                    <span className="title">Total suggetions received</span>
                                    <span className="value">{props.suggestAdvertiser?.totalSuggestionsReceived}</span>
                                </span>
                            </Row>
                        </Col>

                        <Col className="suggestion-item" span={8} >
                            <List className="list-items"
                                header={<div className="list-header"><span className="header-title">Top 10 categories suggested</span></div>}
                                dataSource={props.suggestAdvertiser?.topAdvertiserCategories?.slice(0, 10)}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <Typography.Text className="index-item">{index + 1}.
                                        <span className="item-name">{`${item.name}`}
                                                <span className="item-count">{`(${item.totalSuggested})`}</span>
                                            </span>
                                        </Typography.Text>
                                    </List.Item>
                                )} />
                        </Col>

                        <Col className="suggestion-item" span={8} >
                            <List className="list-items"
                                header={<div className="list-header"><span className="header-title">Top 10 affiliates suggested</span></div>}
                                dataSource={props.suggestAdvertiser?.topSuggestedAffiliates?.slice(0, 10)}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <Typography.Text className="index-item">{index + 1}.
                                        <span className="item-name">{`${item.advertiser.name}`}
                                                <span className="item-count">{`(${item.totalSuggested})`}</span>
                                            </span>
                                        </Typography.Text>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </>
    )
}
const mapStateToProps = ({ suggestAdvertiser }: AppState) =>
({
    suggestAdvertiser: suggestAdvertiser
});
export default connect(mapStateToProps, {
    updateSuggestedAffiliatesInStoreAction
})(SuggestedAffiliatesComponent);
import { TransferStatus } from "../transactions/transation";
/************************************************/
/// Statistics

export interface StandardWithdrawStatisticsModel {
    totalStandardWithdraw: number;
    totalAmountStandardWithdraw: number;
}

export interface RapidWithdrawStatisticsModel {
    totalAmountRapidWithdraw: number;
    totalRapidWithdraw: number;
    totalFeeRapidWithdraw:number;
    rapidWithdrawStatisticsRulesModels: Array<RapidWithdrawStatisticsRulesModels>
}
export interface RapidWithdrawStatisticsRulesModels {
    title:string;
    totalCount:number;
    totalAmount:number;
}
export interface WithdrawStatisticsModel {
    withdrawStatisticsLastMonth: WithdrawStatisticsLastMonth;
    withdrawStatisticsTotal: WithdrawStatisticsTotal;
}
export interface WithdrawStatisticsLastMonth {
    standardWithdrawStatisticsModel: StandardWithdrawStatisticsModel;
    rapidWithdrawStatisticsModel: RapidWithdrawStatisticsModel;
}
export interface WithdrawStatisticsTotal {
    standardWithdrawStatisticsModel: StandardWithdrawStatisticsModel;
    rapidWithdrawStatisticsModel: RapidWithdrawStatisticsModel;
}
/**************************************************/

export interface GetListOfWithdrawals {
    pageNumber: number;
    pageSize: number;
    searchValue: string;
    sortColumn: string;
    orderDescending: boolean;
}



export interface WithdrawalsModel {
    withdrawType: WithdrawType,
    transferStatus: TransferStatus
    id: string;
    fullName: string;
    email: string;
    type: number;
    from: string;
    nameOfFrom: string;
    destination: string;
    nameOfDestination: string;
    createdAt: Date;
    status: number;
    amount: number;
    withdrawFee: number;
    mask:string;
}

export interface PaginatedWithdrawals {
    items: WithdrawalsModel[];
    totalItemCount: number;
    pageCount: number;
    pageSize: number;
    pageNumber: number;
}

export enum WithdrawType {
    Standard,
    Rapid
}
/******************************************************************/
export interface AnnualRapidWithdrawModel {
    year:number;
    month:number;
    monthName: string;
    withdrawFees: number;
}
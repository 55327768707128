import getAllUserSubscriptions from "./getAllUsersSubscriptions";
import getListOfUserSubscriptions from "./getListOfUserSubscriptions";
import getSubscriptionPlans from "./getSubscriptionPlans";
import getSubscriptionStatistics from "./getSubscriptionStatistics";
import updateSubscriptionPlan from "./updateSubscriptionPlan";

const subscriptions = {
    getListOfUserSubscriptions,
    getSubscriptionStatistics,
    getAllUserSubscriptions,
    getSubscriptionPlans,
    updateSubscriptionPlan
};

export default subscriptions;
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import store from "../core/commons/localstorage";
import { UserContext } from "./common/userContext/userContext";
import { RoutePaths } from "./routes";


const RedirectRouter: React.FC = () => {
    const history = useHistory();
    useEffect(() => {        
        const actualToken = store.getToken();
        let userContext = new UserContext();
        if (actualToken && userContext.isAuthenticated) {
            window.scroll(0,0);
            history.push(RoutePaths.DASHBOARD_PATH);
        }
        else{
            localStorage.clear();
            history.push(RoutePaths.LOGIN_PATH);
        }
    },[]);

    return <div>loading...</div>;
}

export default RedirectRouter;

import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {commandRequest, queryRequest} from '../../../../app/common/requestUtils/requestUtils';
import {AppState} from '../../../commons/reduxStore';
import {UpdateUsersLegalNameModel, UserDetailsModel} from '../../../models/dashboard/users/userDetails';
import users from '../../../services/dashboard/users/index';
import {isNil} from "../../../../app/common/utils/Utils";
import {executeAction} from "../../../commons/baseAction";
import {updateUsersLegalName} from "../../../services/dashboard/users/userDetails";

export const GET_USER_DETAILS_SUCCES = "GET_USER_DETAILS_SUCCES";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";
export const USER_SCHEDULED_FOR_DELETION_SUCCESS = "USER_SCHEDULED_FOR_DELETION_SUCCESS";
export const USER_DELETED_SUCCESS = "USER_DELETED_SUCCESS";
export const USER_DELETED_FAILURE = "USER_DELETED_FAILURE";
export const USER_DELETION_CANCELLED_SUCCESS = "USER_DELETION_CANCELLED_SUCCESS";
export const USER_DELETION_CANCELLED_FAILURE = "USER_DELETION_CANCELLED_FAILURE";
export const UPDATE_USERS_LEGAL_NAME_SUCCESS = "UPDATE_USERS_LEGAL_NAME_SUCCESS";
export const UPDATE_USERS_LEGAL_NAME_FAILURE = "UPDATE_USERS_LEGAL_NAME_FAILURE";

const {cancelUserDeletion, deleteUser, getUsersDetails} = users;

export interface UserDetailsInStoreAction {
    type: typeof GET_USER_DETAILS_SUCCES
        | typeof GET_USER_DETAILS_ERROR
        | typeof USER_SCHEDULED_FOR_DELETION_SUCCESS
        | typeof USER_DELETED_SUCCESS
        | typeof USER_DELETED_FAILURE
        | typeof USER_DELETION_CANCELLED_SUCCESS
        | typeof USER_DELETION_CANCELLED_FAILURE
        | typeof UPDATE_USERS_LEGAL_NAME_SUCCESS
        | typeof UPDATE_USERS_LEGAL_NAME_FAILURE,
    details: UserDetailsModel | null,
    selectedUser: string
}

export const updateUserDetailsInStoreAction =
    (details: UserDetailsModel | null,
     selectedUser: string,
     onSuccess: Function,
     onFailure: Function)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {

        executeAction(
            queryRequest(() => getUsersDetails(selectedUser)),
            (res: any) => {
                dispatch({
                    type: GET_USER_DETAILS_SUCCES,
                    details: res
                } as UserDetailsInStoreAction);
                onSuccess();
            },
            (_: any) => {
                dispatch({
                    type: GET_USER_DETAILS_ERROR,
                    details: null
                } as UserDetailsInStoreAction);
                onFailure();
            }
        )
    };

export const updateUserDeletionInStoreAction =
    (details: UserDetailsModel | null, userId: string, isScheduled: boolean, onScheduling: Function, onDeletion: Function, onFailure: Function)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        commandRequest(() => deleteUser(userId, isScheduled))
            .then((res) => {
                if (!isNil(res) && !res.errors) {
                    dispatch({
                        type: USER_SCHEDULED_FOR_DELETION_SUCCESS,
                        details: res
                    } as UserDetailsInStoreAction);
                    onScheduling();
                } else if (res.errors) {
                    dispatch({
                        type: USER_DELETED_FAILURE,
                        details: details
                    } as UserDetailsInStoreAction)
                    onFailure(res.errors);
                } else {
                    dispatch({
                        type: USER_DELETED_SUCCESS,
                        details: null
                    } as UserDetailsInStoreAction);
                    onDeletion();
                }
            })
            .catch(_ => {
                dispatch({
                    type: USER_DELETED_FAILURE,
                    details: details
                } as UserDetailsInStoreAction)
                onFailure();
            });
    };

export const cancelUserDeletionInStoreAction =
    (details: UserDetailsModel | null, userId: string, onCancellation: Function, onFailure: Function)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        commandRequest(() => cancelUserDeletion(userId))
            .then((res) => {
                if (!isNil(res) && !res.errors) {
                    dispatch({
                        type: USER_DELETION_CANCELLED_SUCCESS,
                        details: res
                    } as UserDetailsInStoreAction);
                    onCancellation();
                } else {
                    dispatch({
                        type: USER_DELETION_CANCELLED_FAILURE,
                        details: details
                    } as UserDetailsInStoreAction)
                    onFailure(res.errors);
                }
            })
            .catch(_ => {
                dispatch({
                    type: USER_DELETION_CANCELLED_FAILURE,
                    details: details
                } as UserDetailsInStoreAction)
                onFailure();
            });
    };


export const updateLegalNameInStoreAction =
    (details: UserDetailsModel | null, model: UpdateUsersLegalNameModel, onSuccess: Function, onFailure: Function)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        commandRequest(() => updateUsersLegalName(model))
            .then((res) => {
                if (!isNil(res) && !res.errors) {
                    dispatch({
                        type: UPDATE_USERS_LEGAL_NAME_SUCCESS,
                        details: res
                    } as UserDetailsInStoreAction);
                    onSuccess();
                } else {
                    dispatch({
                        type: UPDATE_USERS_LEGAL_NAME_FAILURE,
                        details: details
                    } as UserDetailsInStoreAction)
                    onFailure(res.errors);
                }
            })
            .catch(_ => {
                dispatch({
                    type: UPDATE_USERS_LEGAL_NAME_FAILURE,
                    details: details
                } as UserDetailsInStoreAction)
                onFailure();
            });
    };

export interface Statistics {
    allAdminStatistics: AllAdminStatistics;
    todayAdminStatistics: TodayAdminStatistics;
    marketplaceStatistics: MarketplaceStatistics;
    goalStatistics: GoalStatistics
}

export interface AllAdminStatistics {
    userStatistics: UserStatistics;
    transactionStatistics: TransactionStatistics;
}

export interface TodayAdminStatistics {
    userStatistics: UserStatistics;
    transactionStatistics: TransactionStatistics;
}

export interface UserStatistics {
    totalUsers: number;
    usersNotVerified: number;
    usersOnBoardingCompleted: number;
    activeUsers: number;
    usersDeletionRequest: number;
    usersWithBankDisconnected: number;
    usersWithBankConnected: number;
    inActiveUsers: number;

}

export interface TransactionStatistics {
    transactions: Array<TransactionTypeWrapper>;
    amount: number;
    count: number;
}

export interface TransactionTypeWrapper{
    entries: Array<TransactionModel>;
    type: TransactionTypeEnum;
    amount: number;
    count: number;
}

export interface TransactionModel{
    type: TransactionTypeEnum;
    status: TransactionStatusEnum;
    amount: number;
    count: number;
}

export enum TransactionTypeEnum{
    Deposit,
    MarketplacePurchase,
    Referral,
    Withdrawal,
    Cashback,
    DirectDeposit
}

export enum TransactionStatusEnum{
    Pending,
    Initiated,
    Processing,
    Cancelled,
    Completed,
    Returned
}

export interface MarketplaceStatistics {
    totalAffiliates: number;
    totalMarketplacePurchases: number;
    totalPurchaseAmount: number;
    totalCashBackAmount: number;
}

export interface GoalStatistics {
    totalActiveAndCompletedGoals: number;
    totalPausedGoals: number;
    totalGoals: number;
}




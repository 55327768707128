class Keys {
  static TOKEN: string = "token";
  static PAGE: string = "PAGE";
}

export const store = {
  set: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },

  get: (key: string): string | null => {
    return localStorage.getItem(key);
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  }
}
const exports = {
  setToken: (token: string) => {
    store.set(Keys.TOKEN, token);
  },
  removeToken: () => {
    store.remove(Keys.TOKEN)
  },
  getToken: (): string | null => store.get(Keys.TOKEN),
  setPage: (page: string) => {
    store.set(Keys.PAGE, page);
  },
  getPage: (): string | null => store.get(Keys.PAGE)
}

export default exports;
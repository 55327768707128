import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { TransactionWithdrawalSearch, WithdrawalTransactionModel } from "../../../../core/models/dashboard/transactions/transation";
import { updateTransactionWithdrawalByTransferInStoreAction } from "../../../../core/actions/dashboard/transactions/updateTransactionWithdrawalByTransferInStore";
import { Row } from "antd";
import MoveMoneyTransactionActivityComponent from "../moveMoneyActivity/MoveMoneyTransactionActivityComponent";
import CashbackActivityComponent from "../cashbackActivity/CashbackActivityComponent";

export interface WithdrawalActivityComponentProps {
    userId: string;
    transferId: string;
    userTransactionsWithdrawal: WithdrawalTransactionModel | null;
}

const initSearchParams: TransactionWithdrawalSearch = {
    transferId: "",
    userId: ""
};

const WithdrawalActivityComponent: React.FC<WithdrawalActivityComponentProps> = props => {

    return <>
        {props.userTransactionsWithdrawal?.moneyTransferActivity &&
            <MoveMoneyTransactionActivityComponent {...{ moneyTransferActivity: props.userTransactionsWithdrawal?.moneyTransferActivity }}></MoveMoneyTransactionActivityComponent>
        }
        {/* {props.userTransactionsWithdrawal?.cashBackActivity &&
            <CashbackActivityComponent {...{ cashBackActivity: props.userTransactionsWithdrawal?.cashBackActivity }}></CashbackActivityComponent>
        } */}
    </>
}
export default WithdrawalActivityComponent;
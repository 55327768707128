import React, { useEffect, useState } from 'react';
import { Card, Modal, Row, Table, Button, Upload, Input, Switch, Popover, Spin } from "antd";
import {
    PlusCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    UploadOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteFilled,
    MenuOutlined,
    DownOutlined,
    RightOutlined
} from "@ant-design/icons";


import "../../../../styles/layout/dashboard/marketplace/affiliateHomePlatform.less"
import { AddMarketplacePlatformModel, MarketplacePlatformModel, PlatformStatus, UpdateMarketplacePlatformModel } from "../../../../core/models/dashboard/marketplace/marketplaceModels";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { commandRequest } from '../../../common/requestUtils/requestUtils';
import services from "../../../../core/services";
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { FileModel, FileResponse } from '../../../../core/models/files/fileModel';
import { AppState } from '../../../../core/commons/reduxStore';
import { connect } from 'react-redux';
import { updateMarketplacePlatformInStoreAction } from "../../../../core/actions/dashboard/marketplace/platform/updateMarketplacePlatformInStore";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { OrderType, UpdateMarketplaceOrderModel } from '../../../../core/models/dashboard/marketplace/marketplaceCategoryModel';
import { OrderIndex } from '../../../../core/models/dashboard/marketplace/affiliatesModel';
import { acceptFileUpload, error, maxInputLength } from '../../../common/utils/Utils';

const { marketplace, uploadIcon } = services;

export interface AffiliateHomePlatformsProps {
    marketplacePlatform: Array<MarketplacePlatformModel>
    updateMarketplacePlatformInStoreAction: (marketplacePlatform: Array<MarketplacePlatformModel> | []) => void;
}

interface PlatformValues {
    id: string;
    name: string;
    fileUrl: string | null;
    platformStatus: boolean,
}

let initialValues: PlatformValues = {
    id: "",
    name: "",
    fileUrl: "",
    platformStatus: true
}


const formValidator = () =>
    Yup.object({
        name: Yup.string().max(50, 'You have reached your maximum limit of characters allowed').required("name is required"),
        fileUrl: Yup.string().min(1).required('A file is required')
    });

const ASortableItem = SortableElement((props: any) => <tr {...props} />);
const ASortableContainer = SortableContainer((props: any) => <tbody {...props} />);

const AffiliateHomePlatformsComponent: React.FC<AffiliateHomePlatformsProps> = props => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(true)
    const [isCollapsed, setCollapsed] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [platformStatus, setPlatformStatus] = useState<boolean>(true);
    const [uploadedFile, setUploadedFile] = useState<FormData>();
    const [editFileUploaded, setEditFileUploaded] = useState<Array<UploadFile>>([]);
    const [fileListUpload, setFileListUpload] = useState<Array<UploadFile>>([]);
    const [acceptedFiles, setAcceptedFiles] = useState<Array<string>>([]);
    const [marketplacePlatform, setMarketplacePlatform] = useState<Array<MarketplacePlatformModel>>([]);

    const collapse = () => {
        setCollapsed(!isCollapsed);
    }

    useEffect(() => {

        if (!Object.is(props.marketplacePlatform, marketplacePlatform)) {
            setMarketplacePlatform(props.marketplacePlatform);
            setLoading(false);
        }
    })

    if (acceptedFiles.length == 0) {
        setAcceptedFiles(acceptFileUpload.split(','));
    }

    const uploadProps = {
        name: 'file',
        action: '',
        beforeUpload: (file: any) => {
            if (file.type !== 'image/png') {
                error(`${file.name} is not a png file`);
            }
            return file.type === 'image/png' ? true : false;
        },
        onChange(info: UploadChangeParam) {
            if (info) {
                if (info.file && acceptedFiles.length > 0 && acceptedFiles.includes(info.file.type)) {
                    if (info.fileList.length > 0) {
                        setFileListUpload([]);

                        let formData = new FormData();
                        formData.append("file", info.file as any);
                        setUploadedFile(formData)
                        setFileListUpload(info.fileList.filter(f => acceptedFiles.includes(f.type)));

                        formik.values.fileUrl = info.file.fileName ? info.file.fileName : "uploaded";
                        formik.setValues({ ...formik.values });
                    }
                }
            } else {
                setFileListUpload([]);
                setEditFileUploaded([]);
                setUploadedFile(undefined)
                formik.values.fileUrl = "";
                formik.setValues({ ...formik.values });
            }
        },

        onRemove() {
            setFileListUpload([]);
            setEditFileUploaded([]);
            setUploadedFile(undefined)
            formik.values.fileUrl = "";
            formik.setValues({ ...formik.values });
        }
    };

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
    ));

    const onSortEnd = (index: OrderIndex) => {
        if (index.oldIndex !== index.newIndex) {
            const newData = arrayMove([].concat([...props.marketplacePlatform] as never[]), index.oldIndex, index.newIndex).filter(el => !!el);
            (newData as Array<MarketplacePlatformModel>).forEach((item, index) => {
                item.order = index + 1;
            });
            let orderdAffiliate: Array<UpdateMarketplaceOrderModel> = [];
            orderdAffiliate = newData.map((f: MarketplacePlatformModel) =>
            ({
                id: f.id,
                order: f.order
            } as UpdateMarketplaceOrderModel));

            if (orderdAffiliate.length > 0) {
                updateOrderNumberOfCategories(orderdAffiliate);
            }
        }
    };

    const updateOrderNumberOfCategories = (platforms: Array<UpdateMarketplaceOrderModel>) => {
        commandRequest(() => marketplace.updateOrder(OrderType.Platform, platforms))
            .then((res: any) => {
                props.updateMarketplacePlatformInStoreAction([]);
            }).catch((error: any) => {
                // todo :: show alert;
            })
    }

    const DraggableContainer = (props: any) => {
        return (
            <ASortableContainer
                useDragHandle
                disableAutoscroll={false}
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />
        )
    }

    const DraggableBodyRow = (dragable: any) => {
        const index = props.marketplacePlatform.findIndex(x => x.order === dragable['data-row-key']);
        return <ASortableItem index={index} {...dragable} />;
    };

    const showModal = () => {
        initialValues.name = '';
        initialValues.fileUrl = '';

        formik.setValues({ ...initialValues })
        formik.resetForm();
        setFileListUpload([]);
        setEditFileUploaded([]);
        setUploadedFile(undefined)
        setIsEditMode(false);
        setIsModalVisible(true);
    };

    const handleOk = () => {

        if (isEditMode) {
            let updatePlatformModel: UpdateMarketplacePlatformModel = {
                id: formik.values.id,
                iconUrl: ``,
                name: formik.values.name,
                platformStatus: (platformStatus) ? PlatformStatus.Active : PlatformStatus.Paused,
                order: 0
            }

            if (JSON.stringify(editFileUploaded[0]) == JSON.stringify(fileListUpload[0])) {
                updatePlatformModel.iconUrl = `${formik.values.fileUrl}`;
                updatePlatform(updatePlatformModel);
            } else {
                if (uploadedFile) {
                    commandRequest(() => uploadIcon(uploadedFile))
                        .then((res: FileResponse) => {
                            updatePlatformModel.iconUrl = `${res.fileName}`;
                            updatePlatform(updatePlatformModel);
                        }, (reason: any) => {
                            error(reason);
                        })
                        .catch((error: any) => {
                            // todo :: show alert;
                        })
                }
            }
        } else {
            if (uploadedFile) {
                commandRequest(() => uploadIcon(uploadedFile))
                    .then((res: FileResponse) => {
                        addNewPlatform(res);
                    }, (reason: any) => {
                        error(reason);
                    })
                    .catch((error: any) => {
                        // todo :: show alert;
                    })
            } else {

            }
        }
        setIsModalVisible(false);
    };

    const updatePlatform = (model: UpdateMarketplacePlatformModel) => {
        try {
            commandRequest(() => marketplace.updatePlatform(model))
                .then(() => {
                    setLoading(true);
                    props.updateMarketplacePlatformInStoreAction([]);
                })
                .catch((error) => {
                    console.log("error: ", error)
                })
        }
        catch (error) {
            console.log("error: ", error)
        }
    }

    const addNewPlatform = (uploadedIcon: FileResponse) => {
        let newPlatform: AddMarketplacePlatformModel = {
            iconUrl: `${uploadedIcon.fileName}`,
            name: formik.values.name
        }
        if (newPlatform) {
            try {
                commandRequest(() => marketplace.addNewPlatform(newPlatform))
                    .then(() => {
                        setLoading(true);
                        props.updateMarketplacePlatformInStoreAction([]);
                    }, (reason: any) => {
                        error(reason);
                    })
                    .catch((error) => {
                        console.log("error: ", error)
                    })
            }
            catch (error) {
                console.log("error: ", error)
            }
        }
    }

    const handleCancel = () => {
        formik.resetForm();
        setFileListUpload([]);
        setIsModalVisible(false);
    };

    const deletePlatform = (row: MarketplacePlatformModel) => {
        return (
            Modal.confirm({
                title: `Are you sure you want to delete "${row.name}"?`,
                icon: <DeleteFilled className={"delete-icon-modal"} />,
                content: `All affiliates that are currently assigned to this platform will be temporary paused, until assigned another category. 
                Assigning a new category can be done by going into the affiliate details page and select one of the existing categories.`,
                okText: 'Delete',
                cancelText: 'Cancel',
                className: "delete-modal",
                onOk() {
                    commandRequest(() => marketplace.deletePlatform(row.id))
                        .then(() => {
                            props.updateMarketplacePlatformInStoreAction([]);
                        }, (reason: any) => {
                            error(reason);
                        })
                        .catch((error) => {
                            console.log("error: ", error)
                        })
                },
                onCancel() {
                    console.log('Cancel: ', row);
                },
            })
        )
    }

    const getFileNameFromUrl = (url: string) => {
        if (url.trim().length > 0) {
            var urlSplited = url.split('/');
            return (urlSplited[urlSplited.length - 1])
        }
        return "";
    }

    const onEditRow = (model: MarketplacePlatformModel) => {
        // formik.setValues({ ...initialValues })
        setFileListUpload([]);
        setEditFileUploaded([]);

        initialValues.id = model.id;
        initialValues.name = model.name;
        initialValues.fileUrl = getFileNameFromUrl(model.iconUrl);
        initialValues.platformStatus = (model.platformStatus == PlatformStatus.Active) ? true : false;

        (model.platformStatus == PlatformStatus.Active) ? setPlatformStatus(true) : setPlatformStatus(false)

        formik.setValues({ ...initialValues })

        const modelFileEdit: Array<UploadFile> = mapModelEditFile(model);
        setEditFileUploaded(modelFileEdit);
        setFileListUpload(modelFileEdit);
        setIsEditMode(true);
        setIsModalVisible(true);
        //setSelectedItem(model);
    }

    const mapModelEditFile = (model: MarketplacePlatformModel): Array<UploadFile> => {
        const newUploadFileModel: Array<UploadFile> = [
            {
                uid: model.id,
                name: getFileNameFromUrl(model.iconUrl),
                status: 'done',
                type: 'image',
                size: 1,
                url: model.iconUrl,
                thumbUrl: model.iconUrl,
            }
        ];
        return newUploadFileModel;
    }
    const onEditStatusChanged = (checked: boolean) => {
        setPlatformStatus(checked)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        validateOnChange: true,
        onSubmit: handleOk
    })

    const columns = [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: '',
            dataIndex: 'iconUrl',
            width: 30,
            render: (iconUrl: string, row: any) => (
                <>
                    <img className="platform-icon" src={`${iconUrl}`}></img>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'name',
            render: (name: string, row: any) => (
                <>
                    <span className="title">
                        {name.length > 30 ?
                            <>
                                <Popover content={row.name} title="Name" trigger="hover">
                                    {`${name.substring(0, 30)}...`}
                                </Popover>
                            </> :
                            <>  {name} </>
                        }
                    </span>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id: string, row: any) => (
                <>
                    <span className="action-icons">
                        <span className="icon-circle">
                            <EditOutlined onClick={() => onEditRow(row)} />
                        </span>
                        <span className="icon-circle">
                            <DeleteOutlined onClick={() => deletePlatform(row)} />
                        </span>
                    </span>
                </>
            )
        },
    ];

    const getHeaderTitle = () => {
        return (
            <>
                {isCollapsed ? <><DownOutlined onClick={collapse} /> {`Platform (${props?.marketplacePlatform?.length})`}</> : <><RightOutlined onClick={collapse} /> {`Platform (${props.marketplacePlatform.length})`}</>}
            </>
        )
    }

    return (
        <>
            <Card onClick={collapse} className={isCollapsed ? "collapsed-off" : "collapsed-in"} title={getHeaderTitle()}
                extra={<a className="add-new-platform" href="#" onClick={showModal}><PlusCircleOutlined
                    className="add-new-icon" />New</a>}>
                <Spin tip="Loading..." spinning={isLoading}>

                    <Table
                        className="platform-table"
                        pagination={false}
                        dataSource={props.marketplacePlatform}
                        columns={columns}
                        rowClassName={(record, index) => record.platformStatus == PlatformStatus.Paused ? 'disabled-row ' : ''}
                        rowKey="order"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    />
                </Spin>

            </Card>

            <Modal className="modal" title="Add new platform" visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button className="footer-cancel-button" key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button className="footer-submit-button"
                        key="submit"
                        type="primary"
                        disabled={!formValidator().isValidSync(formik.values)}
                        onClick={handleOk}>
                        Save
                    </Button>,
                ]}>

                {!isEditMode &&
                    <>
                        <Row className="row-modal">
                            <span className="title-field">Platform name </span>
                            <Input className="name"
                                maxLength={maxInputLength}
                                onChange={formik.handleChange}
                                value={formik.values.name || ''}
                                size="large"
                                name="name"
                            />
                            {formik.errors.name && formik.touched.name && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.name}</div>
                                </div>
                            )}
                        </Row>

                        <Row className="row-modal upload-row">
                            <span className="title-field">Platform image</span>
                            <Upload listType="picture"
                                {...uploadProps}
                                fileList={fileListUpload}
                                beforeUpload={() => false}
                                name="fileUrl"
                                accept={acceptFileUpload}
                                defaultFileList={[]}>
                                <Button className="upload-button" icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                            {formik.errors.fileUrl && formik.touched.fileUrl && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.fileUrl}</div>
                                </div>
                            )}
                        </Row>
                        <Row>
                            <p className="image-description">Image format should be .png and minimum size 24px x 24px</p>
                        </Row>
                    </>
                }

                {isEditMode &&
                    <>
                        <Row className="row-modal">
                            <span className="title-field">Platform name </span>
                            <Input className="name"
                                maxLength={maxInputLength}
                                onChange={formik.handleChange}
                                value={formik.values.name || ''}
                                size="large" name="name" />
                            {formik.errors.name && formik.touched.name && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.name}</div>
                                </div>
                            )}
                        </Row>

                        <Row className="row-modal upload-row">
                            <span className="title-field">Platform image</span>
                            <Upload
                                {...uploadProps}
                                listType="picture"
                                name="fileUrl"
                                beforeUpload={() => false}
                                accept={acceptFileUpload}
                                fileList={fileListUpload}>
                                <Button className="upload-button" icon={<UploadOutlined />}>Upload</Button>
                                <p className="image-description">Image format should be .png and minimum size 24px x 24px</p>
                            </Upload>
                            {formik.errors.fileUrl && formik.touched.fileUrl && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.fileUrl}</div>
                                </div>
                            )}
                        </Row>

                        <Row className="row-modal status-row">
                            <span className="status-field">
                                <span className="title-field">Status </span>
                                <span className="description-field">Decide if this platform is active or not.</span>
                            </span>

                            <Switch
                                onChange={onEditStatusChanged}
                                checked={platformStatus}
                                className="checkbox-status"
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked />
                        </Row>
                    </>
                }
            </Modal>
        </>
    )
}
const mapStateToProps = ({ marketplacePlatform }: AppState) =>
({
    marketplacePlatform: marketplacePlatform
});


export default connect(mapStateToProps, {
    updateMarketplacePlatformInStoreAction
})(AffiliateHomePlatformsComponent);
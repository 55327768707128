import { Content } from 'antd/lib/layout/layout';
import {Button, Card, Col, Divider, Drawer, Input, message, Modal, Row, Space, Spin, Tabs, Tooltip} from 'antd';
import {useEffect, useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
    updateLegalNameInStoreAction,
    updateUserDetailsInStoreAction
} from "../../../../core/actions/dashboard/users/updateUserDetailsInStore";
import { connect } from 'react-redux';
import '../../../../styles/layout/index.less';
import moment from 'moment';
import UserDetailsDelete from './UserDetailsDelete';
import { UserStatus } from '../../../../core/models/dashboard/users/user';
import { AppState } from '../../../../core/commons/reduxStore';
import UserMoveMoneyTableComponent from "../../movemoney/UserMoveMoneyTableComponent";
import UserGoalsTableComponent from "../../goals/UserGoalsTableComponent";
import UserMarketplacePurchaseTableComponent from "../../marketplacePurchase/UserMarketplacePurchaseTableComponent";
import TagStatusComponent, { TagStatusProps } from '../../../common/components/dashboard/UserTagStatusComponent';
import BreadcrumbComponent, { BreadcrumbProps } from '../../../common/components/dataDisplay/BreadcrumbComponent';
import TransactionsComponent from '../../transactions/TransactionComponent';
import { CurrencyNumberFormat, setAcronymValue } from '../../../common/utils/Utils';
import * as Scroll from 'react-scroll';
import  {RoutePaths}  from '../../../routes';
import BankAccountPlaid from "./plaidDetails/BankAccountPlaid";
import PaymentOptionPlaid from "./plaidDetails/PaymentOptionPlaid";
import {FileOutlined} from "@ant-design/icons";
import {useFormik} from "formik";
import * as Yup from "yup";
import {UpdateUsersLegalNameModel} from "../../../../core/models/dashboard/users/userDetails";
 

const { TabPane } = Tabs;

interface UserSelected {
    id: string
}


const UserDetails: React.FC = (props: any) => {
    let user = useParams<UserSelected>();
    const [isLoading, setLoading] = useState(false);
    const [isMounting, setMounting] = useState(true);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const userStatus: TagStatusProps = { status: 0 };
    let scroll = Scroll.animateScroll;

    const currentBreadCrumbs: BreadcrumbProps = {
        title: "Dashboard",
        className: "breadcrumb",
        description: "",
        breadcrumbs: [
            {
                title: "Users",
                path: RoutePaths.USER_PATH,
            },
            {
                title: "Details",
                path: "",
            }
        ]
    };

    const formValidator = () =>
        Yup.object({
            firstname: Yup.string().required("Firstname is required"),
            lastname: Yup.string().required("Lastname is required"),
        });

    const onGetUpdateUsersLegalNameSuccess = () => {
        message.success(`Users's legal name updated successfully!`);
        setLoading(false);
    }

    const onGetUpdateUsersLegalNameFailure = () => {
        message.error(`Users's legal name failed to update!`);
        setLoading(false);
    }

    const updateLegalNames = () => {
        const model: UpdateUsersLegalNameModel = {
            legalFirstName: formik.values.firstname,
            legalLastName: formik.values.lastname,
            userId: user.id
        };

        props.updateLegalNameInStoreAction(props.userDetails, model,onGetUpdateUsersLegalNameSuccess, onGetUpdateUsersLegalNameFailure);
        setLoading(true);
        setDrawerOpen(false);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: props.userDetails?.userDetails?.firstName,
            lastname: props.userDetails?.userDetails?.lastName
        },
        validationSchema: formValidator,
        onSubmit: updateLegalNames,
    });

    const onClose = () => {
        setDrawerOpen(false);
    }

    const onOpen = () => {
        setDrawerOpen(true);
    }

    const scrollToTop = () => {
        scroll.scrollToTop();
        scroll.scrollTo(0);
    }

    useEffect(() => {
        scroll.scrollToTop();
    }, [])

    const onGetUserDetailsSuccess = () => {
        message.success('User details loaded successfully!');
        setLoading(false);
    }

    const onGetUserDetailsFailure = () => {
        message.error('User details failed to load!');
        setLoading(false);
    }

    const getUserDetails = () => {
        props.updateUserDetailsInStoreAction(null, user.id, onGetUserDetailsSuccess, onGetUserDetailsFailure);
        setLoading(true);
    }

    if (isMounting) {
        scrollToTop.bind(this);
        scrollToTop();
        getUserDetails();
        setMounting(false);
    }


    if (props.userDetails) {
        userStatus.status = props.userDetails?.userDetails?.userStatus;
    }

    return (
        <Content>
            <BreadcrumbComponent {...currentBreadCrumbs} />
            <Space>
                <Drawer
                    title={
                        <span className="modal-header-title">Edit legal names</span>
                    }
                    placement={"right"}
                    closable={false}
                    onClose={onClose}
                    visible={isDrawerOpen}
                    key={"right"}
                    width={600}
                    bodyStyle={{paddingBottom: 80}}
                >

                    <Spin tip="Loading..." spinning={isLoading}>
                        <Row className="row-item">
                            <span className="item-title">Legal firstname </span>
                            <Input type="text" onChange={formik.handleChange} value={formik.values.firstname} name="firstname"
                                   placeholder="Type something..."
                                   className="item-input" maxLength={100}/>
                            {formik.errors.firstname && formik.touched.firstname && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.firstname}</div>
                                </div>
                            )}
                        </Row>

                        <Divider/>

                        <Row className="row-item">
                            <span className="item-title">Legal lastname</span>
                            <Input type="text" onChange={formik.handleChange}  value={formik.values.lastname} name="lastname"
                                      placeholder="Type something..."
                                      className="item-input" maxLength={100} />
                            {formik.errors.lastname && formik.touched.lastname && (
                                <div
                                    className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{formik.errors.lastname}</div>
                                </div>
                            )}
                        </Row>

                        <Row className="row-item" style={{justifyContent: 'flex-end'}}>
                            <Button onClick={() => formik.handleSubmit()} className="addNewPromotion-button" type="primary"
                                    shape="round" icon={<FileOutlined/>}>
                                Save
                            </Button>
                        </Row>
                    </Spin>
                </Drawer>
            </Space>

            <Spin spinning={isLoading} >
                <Row className="dashboard-content">
                    <Row className="row-card">
                        <Card className="user-card-item">
                            <Row className="row-card-item">
                                <Col span={12} >
                                    <span className="user-name-row">
                                        <span className="initials-item">{setAcronymValue(props.userDetails?.userDetails?.fullName)}</span>
                                        <span className="name-item">
                                            <span className="fullname-item"> {props.userDetails?.userDetails?.fullName} <br />    <span className="email-item"> {props.userDetails?.userDetails?.email}</span></span>
                                        </span>
                                    </span>
                                </Col>
                                <Col span={12} >
                                    <Row className="user-status">
                                        <span className="user-status-tag">
                                            <TagStatusComponent {...userStatus} />
                                        </span>
                                        <UserDetailsDelete {...props} />
                                    </Row>
                                </Col>
                            </Row>
                            <Divider />
                            <Row className="details-row-card-item">
                                <Row className="gutter-row" gutter={[48, 32]}>
                                    <Col span={8}>
                                        <h5 className="user-field-item">Full name</h5>
                                        <h6 className="user-field-value">{props.userDetails?.userDetails?.fullName ? props.userDetails?.userDetails?.fullName : ("-")} </h6>
                                        <h5 className="user-field-item">Corepro customer ID</h5>
                                        <h6 className="user-field-value">{props.userDetails?.bankAccountDetails?.coreProAccountId} </h6>
                                        <h5 className="user-field-item">Device</h5>
                                        <h6 className="user-field-value">{props.userDetails?.userDetails?.deviceType ? <>{props.userDetails?.userDetails?.deviceType}</>
                                            : ("-")
                                        }</h6>

                                        <Tooltip title="click to edit">
                                            <div onClick={() => onOpen()}>
                                                <h5 className="user-field-item">Legal firstname</h5>
                                                <h6 className="user-field-value">{
                                                    props.userDetails?.userDetails?.firstName ?? "-"
                                                }</h6>
                                            </div>
                                        </Tooltip>


                                    </Col>
                                    <Col span={8}>
                                        <h5 className="user-field-item">Email</h5>
                                        <h6 className="user-field-value">{props.userDetails?.userDetails?.email}</h6>
                                        <h5 className="user-field-item">Plaid account</h5>
                                        <h6 className="user-field-value">{props.userDetails?.bankAccountDetails?.name ?
                                            <> {props.userDetails?.bankAccountDetails?.mask ?
                                                `${props.userDetails?.bankAccountDetails?.name}(****${props.userDetails?.bankAccountDetails?.mask})`
                                                : `${props.userDetails?.bankAccountDetails?.name}`}

                                            </>
                                            : ("-")}</h6>
                                        <h5 className="user-field-item">App version</h5>
                                        <h6 className="user-field-value">{props.userDetails?.userDetails?.appVersion ?? ("-")}</h6>
                                        <Tooltip title="click to edit">
                                            <div onClick={()=> onOpen()}>
                                                <h5 className="user-field-item">Legal lastname</h5>
                                                <h6 className="user-field-value">{
                                                    props.userDetails?.userDetails?.lastName ?? "-"
                                                }</h6>
                                            </div>
                                        </Tooltip>
                                    </Col>
                                    <Col span={8}>
                                        <h5 className="user-field-item">Phone number</h5>
                                        <h6 className="user-field-value">{props.userDetails?.userDetails?.phone}</h6>
                                        <h5 className="user-field-item">Plaid account status</h5>
                                        <h6 className="user-field-value">{props.userDetails?.bankAccountDetails?.isDisconnected ?
                                            <TagStatusComponent {...{ status: UserStatus.BankDisconnected }} />
                                            :
                                            <TagStatusComponent {...{ status: UserStatus.BankConnected }} />
                                        }
                                        </h6>
                                        <Row>
                                            <Col span={12}>
                                                <h5 className="user-field-item date">Created on</h5>
                                                <h6 className="user-field-value">{moment(new Date(props.userDetails?.userDetails?.createdAt)).format("MM-DD-YYYY")}</h6>
                                            </Col>
                                            <Col span={12}>
                                                <h5 className="user-field-item date">Last login</h5>
                                                <h6 className="user-field-value">{props.userDetails?.userDetails?.lastModifiedAt ? moment(new Date(props.userDetails?.userDetails?.lastModifiedAt)).format("MM-DD-YYYY") : ("-")}</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                        </Card>
                    </Row>
                    
                    <Row className="row-card balance-row" gutter={[16, 16]}>
                        <Col span={6} >
                            <Card className="card-item">
                                <span className="balance-item">
                                    <span className="balance-value"> {CurrencyNumberFormat(props.userDetails?.balanceDetails?.totalBalance)}<br />    <span className="balance-name"> Total balance</span></span>
                                </span>
                            </Card>
                        </Col>
                        <Col span={6} >
                            <Card className="card-item">
                                <span className="balance-item">
                                    <span className="balance-value"> {CurrencyNumberFormat(props.userDetails?.balanceDetails?.availableBalance)} <br />    <span className="balance-name"> Available balance</span></span>
                                </span>
                            </Card>
                        </Col>
                        <Col span={6} >
                            <Card className="card-item">
                                <span className="balance-item">
                                    <span className="balance-value"> {CurrencyNumberFormat(props.userDetails?.balanceDetails?.pendingDeposit)} <br />    <span className="balance-name"> Pending deposits</span></span>
                                </span>
                            </Card>
                        </Col>
                        <Col span={6} >
                            <Card className="card-item">
                                <span className="balance-item">
                                    <span className="balance-value"> {CurrencyNumberFormat(props.userDetails?.balanceDetails?.pendingWithdrawals)} <br />    <span className="balance-name"> Pending withdrawals</span></span>
                                </span>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="row-card">
                        <Card className="card-item">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Savings goals" key="1">
                                    <Row className="saving-rules">
                                        <span className="title">Saving Rule</span>
                                        <Row className="saving-rules-details">
                                            <span className="saving-percentage">
                                                Saving Percentage: {
                                                props.userDetails?.userDetails?.savingRule?.percentage
                                            }%</span>
                                            <span className="spending-limit">
                                                Spending limit: {
                                                (props.userDetails?.userDetails?.savingRule?.spendingLimit != null
                                                    && props.userDetails?.userDetails?.savingRule.spendingLimit > 0)
                                                    ? CurrencyNumberFormat(props.userDetails?.userDetails?.savingRule.spendingLimit) : "-"}</span>
                                            <span className="spending-limit">
                                                Daily savings limit:
                                                {(props.userDetails?.userDetails?.savingRule?.dailySavingsLimit != null
                                                    && props.userDetails?.userDetails?.savingRule.dailySavingsLimit > 0)
                                                    ? CurrencyNumberFormat(props.userDetails?.userDetails?.savingRule.dailySavingsLimit) : "-"}</span>
                                        </Row>
                                    </Row>
                                    <Divider />
                                    <UserGoalsTableComponent userId={user.id} />
                                </TabPane>
                                <TabPane tab="Transactions" key="2">
                                    <TransactionsComponent userId={user.id} />
                                </TabPane>
                                <TabPane tab="Move money" key="3">
                                    <UserMoveMoneyTableComponent userId={user.id} />
                                </TabPane>
                                <TabPane tab="Marketplace" key="4">
                                    <UserMarketplacePurchaseTableComponent userId={user.id} />
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Row>

                    <BankAccountPlaid userId={user.id}/>
                    <PaymentOptionPlaid userId={user.id}/>
                </Row>
            </Spin>
        </Content>
    )
}

const mapStateToProps = ({ userDetails }: AppState) =>
({
    userDetails: userDetails
});

export default connect(mapStateToProps, {
    updateUserDetailsInStoreAction,
    updateLegalNameInStoreAction
})(UserDetails);
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import {
    CashbackRequestsCheckedPaginated, GetPaginatedData
} from "../../../../models/dashboard/marketplace/marketplaceCashbackRequestModel";
import {AppState} from "../../../../commons/reduxStore";
import {executeAction} from "../../../../commons/baseAction";
import {queryRequest} from "../../../../../app/common/requestUtils/requestUtils";
import getApprovedCashbackRequest from "../../../../services/dashboard/marketplace/amazon/getApprovedCashbackRequest";



export const GET_APPROVED_CASHBACK_REQUEST_SUCCESS ="GET_APPROVED_CASHBACK_REQUEST_SUCCESS";
export const GET_APPROVED_CASHBACK_REQUEST_ERROR ="GET_APPROVED_CASHBACK_REQUEST_ERROR";

export interface ApprovedCashbackRequestInStoreAction {
    type: typeof GET_APPROVED_CASHBACK_REQUEST_SUCCESS | typeof GET_APPROVED_CASHBACK_REQUEST_ERROR,
    approvedCashbackRequest: CashbackRequestsCheckedPaginated | null
}

export const updateApprovedCashbackRequestInStoreAction =
    (approvedCashbackRequest: CashbackRequestsCheckedPaginated | null,
     model: GetPaginatedData
     )
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        executeAction(
            queryRequest(()=> getApprovedCashbackRequest(model)),
            (res: any) =>{
                dispatch({
                    type: GET_APPROVED_CASHBACK_REQUEST_SUCCESS,
                    approvedCashbackRequest: res
                } as ApprovedCashbackRequestInStoreAction);
            },
            (_: any) => {
                dispatch({
                    type: GET_APPROVED_CASHBACK_REQUEST_ERROR,
                    approvedCashbackRequest: approvedCashbackRequest
                } as ApprovedCashbackRequestInStoreAction);
            }
        );

    }
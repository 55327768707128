
import { combineReducers, applyMiddleware, createStore } from "redux";

import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";

import reducers from '../../reducers';

const {
    updateUserInfoReducer,
    updateStatisticsInfoReducer,
    updateUserListInStoreReducer,
    updateUserDetailsInStoreReducer,
    updateUserGoalsListInStoreReducer,
    updateMoveMoneyByUserInStoreReducer,
    updateTransactionsByUserInStoreReducer,
    updateTransactionsDepositByTransferInStoreReducer,
    updateTransactionWithdrawalByTransferInStoreReducer,
    updateSuggestedAffiliatesInStoreReducer,
    updateNotificationInStoreReducer,
    updateNotificationLogsInStoreReducer,
    updateNotificationJobsInStoreReducer,
    updateMarketplacePurchaseByUserInStoreReducer, 
    updateMarketplacePurchaseByMarketplaceInStoreReducer,
    updateAllMarketplacePurchaseByMarketplaceInStoreReducer,
    updateAffiliatesInStoreReducer,
    updateMarketplaceCategoriesInStoreReducer,
    updateNotificationLogsInStoreSimpleReducer,
    updateMarketplacePlatformInStoreReducer,
    updateMarketplacesInStoreReducer,
    updateAffiliateDetailsInStoreReducer,
    updateAffiliatePurchaseStatisticsInStoreReducer,
    updateAllMarketplacesInStoreReducer,
    updateBankAccountDetailsInStoreReducer,
    updatePaymentOptionDetailsInStoreReducer,
    updateUserSubscriptionsInStoreReducer,
    updateSubscriptionStatisticsInStoreReducer,
    updateAllUserSubscriptionsInStoreReducer,
    udpateAnnualRapidWithdrawInStoreReducer,
    updateWithdrawalStatisticsInStoreReducer,
    updateWithdrawListInStoreReducer,
    updateDeviceIdsWithMoreUsersInStoreReducer,
    updateListOfUsersByDeviceInStoreReducer,
    updateCashbackRequestInStoreReducer,
    updateApprovedRequestInStoreReducer,
    updateRejectedRequestInStoreReducer,
    updateSubscriptionPlansInStoreReducer,
    getCashbackStatisticsInStoreReducer,
    getMarketplaceProgramReserveInStoreReducer,
    getTotalMarketplacePurchasesInStoreReducer
} = reducers;

const mainReducer = combineReducers(
    {
        user: updateUserInfoReducer,
        statistics: updateStatisticsInfoReducer,
        users: updateUserListInStoreReducer,
        userDetails: updateUserDetailsInStoreReducer,
        userGoals:updateUserGoalsListInStoreReducer,
        userMoveMoney:updateMoveMoneyByUserInStoreReducer,
        userTransactions: updateTransactionsByUserInStoreReducer,
        userTransactionsDeposit: updateTransactionsDepositByTransferInStoreReducer,
        userTransactionsWithdrawal: updateTransactionWithdrawalByTransferInStoreReducer,
        suggestAdvertiser: updateSuggestedAffiliatesInStoreReducer,
        notificationTemplates: updateNotificationInStoreReducer,
        userMarketplacePurchase: updateMarketplacePurchaseByUserInStoreReducer,
        marketplacePurchases: updateMarketplacePurchaseByMarketplaceInStoreReducer,
        allMarketplacePurchases: updateAllMarketplacePurchaseByMarketplaceInStoreReducer,
        notificationJobs: updateNotificationJobsInStoreReducer,
        notificationLogs: updateNotificationLogsInStoreReducer,
        simpleNotificationLogs: updateNotificationLogsInStoreSimpleReducer,
        affiliates: updateAffiliatesInStoreReducer,
        affiliateCategory: updateMarketplaceCategoriesInStoreReducer,
        marketplacePlatform: updateMarketplacePlatformInStoreReducer,
        marketplaces:updateMarketplacesInStoreReducer,
        allMarketplaces:updateAllMarketplacesInStoreReducer,
        affiliateDetails:updateAffiliateDetailsInStoreReducer,
        affiliatePurchaseStatistics: updateAffiliatePurchaseStatisticsInStoreReducer,
        bankAccountDetails: updateBankAccountDetailsInStoreReducer,
        paymentOptionDetails: updatePaymentOptionDetailsInStoreReducer,
        userSubscriptions:updateUserSubscriptionsInStoreReducer,
        subscriptionStatistics: updateSubscriptionStatisticsInStoreReducer,
        allUserSubscriptions:updateAllUserSubscriptionsInStoreReducer,
        withdrawList:updateWithdrawListInStoreReducer,
        withdrawalStatisticsData: updateWithdrawalStatisticsInStoreReducer,
        annualRapidWithdraw: udpateAnnualRapidWithdrawInStoreReducer,
        deviceIdsUsers: updateDeviceIdsWithMoreUsersInStoreReducer,
        usersDevice: updateListOfUsersByDeviceInStoreReducer,
        marketplaceCashbackRequest: updateCashbackRequestInStoreReducer,
        rejectedCashbackRequest: updateRejectedRequestInStoreReducer,
        approvedCashbackRequest: updateApprovedRequestInStoreReducer,
        subscriptionPlans:updateSubscriptionPlansInStoreReducer,
        cashbackStatistics: getCashbackStatisticsInStoreReducer,
        marketplaceProgramReserve: getMarketplaceProgramReserveInStoreReducer,
        totalMarketplacePurchases: getTotalMarketplacePurchasesInStoreReducer
    });

export type AppState = ReturnType<typeof mainReducer>;

const store = createStore(mainReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;


import { string } from "yup"

export interface Marketplace {
    marketplaceId: string;
    name: string;
    iconUrl: string;
    cashBackPercentage: number;
    guacCashBackPercentage:number;
    totalCashBackPercentage:number;
}

export interface CashbackGoals {
    id: string;
    name: string;
}

export interface CashBackDetail {
    id: string;
    goal: CashbackGoals;
    amount: number;
    cashBackCalculated: Date;
    collectedDate: Date;
    expirationDate: Date;
}

export interface MarketplacePurchase {
    id: string;
    type: number;
    marketplace: Marketplace;
    purchaseDate: Date;
    createdAt: Date;
    lastModifiedAt: Date;
    status: number;
    cashBackStatus: CashBackStatus;
    cashBack: number;
    guacCashBack:number;
    amount: number;
    isCollected: boolean;
    isCashBackCalculated: boolean;
    expirationDays: number;
    collectedDate: Date;
    cashBackCalculated: Date;
    claimedDate: Date;
    expiredOn: Date;
    availableOn: Date;
    canceledOn: Date;
    expirationDate: Date;
    associatedPurchase: string;
    goals: CashbackGoals[];
    totalCommission:number;
    user:UserPurchase
}
export interface UserPurchase {
    id:string;
    fullName:string
}
export interface MarketplacePurchaseModel {
    items: MarketplacePurchase[];
    totalItemCount: number;
    pageCount: number;
    pageSize: number;
    pageNumber: number;
}

export interface TotalMarketplacePurchases{
    amount: number;
    count: number;
}
export interface MarketplaceProgramReserve {
    amount : number;
}

export interface CashbackStatisticsModel{
    cashbacks: CashbackStatisticsData[]
}

export interface CashbackStatisticsData{
    amount : number,
    status: number,
    count: number
}

export enum PurchaseStatus {
    Pending,
    Completed,
    Reversed
}
export enum CashBackStatus {
    Processing,
    Claimed,
    Available,
    Collected,
    Expired,
    Canceled
}

export enum MarketplacePurchaseType {
    Booking,
    Carvana,
    Impact,
    Flex
}

export interface MarketplacePurchaseSearch {

    searchValue: string;
    pageNumber: number;
    pageSize: number;
    orderDescending: boolean;
    userId: string | null;
    sortColumn: string;
    affiliateId: string | null;
}

export interface AddNewMarketplacePurchase {
    userId:string;
    marketplaceId:string;
    amount?:number;
    guacCashBack?:number;
    cashBack?:number;
    purchaseDate:string;
    lockingDate:string;

}

export interface CSVMarketplacePurchase {
    PurchaseDate: Date;
    OrderNumber: string;
    Platform: string;
    Marketplace:string;    
    User:string;
    PurchaseStatus: string;
    CashBackStatus: string;
    Amount: number;  
    TotalCommission:number;
    CashBack: number;
    GuacCashBack:number;    
    IsCollected: boolean;
    TrackedOn: Date;
    CollectedDate: Date; 
    ClaimedDate: Date;
    ExpiredOn: Date;
    AvailableOn: Date;
    CanceledOn: Date; 
}
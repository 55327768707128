import {updateAllMarketplacePurchaseByMarketplaceInStoreAction} from "./updateAllMarketplacePurchaseByMarketplaceInStore";
import {updateMarketplacePurchaseByMarketplaceInStoreAction} from "./updateMarketplacePurchaseByMarketplaceInStore";
import {updateMarketplacePurchaseByUserInStoreAction} from "./updateMarketplacePurchaseByUserInStore";
import {getCashbackStatisticsInStoreAction} from "./getCashbackStatisticsInStore";
import {getMarketplaceProgramReserveInStoreAction} from "./getMarketplaceProgramReserveInStore";
import {getTotalMarketplacePurchasesInStoreAction} from "./getTotalMarketplacePurchasesInStore";


const marketplacePurchaseActions = {
    updateAllMarketplacePurchaseByMarketplaceInStoreAction,
    updateMarketplacePurchaseByMarketplaceInStoreAction,
    updateMarketplacePurchaseByUserInStoreAction,
    getCashbackStatisticsInStoreAction,
    getMarketplaceProgramReserveInStoreAction,
    getTotalMarketplacePurchasesInStoreAction
}
export default marketplacePurchaseActions;
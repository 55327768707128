import { Badge, Col, Divider, Empty, Row, Statistic, Tabs } from "antd";
import Table from "antd/lib/table";
import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { DailySubscriptionStatistics, MonthlySubscriptionStatistics, SubscriptionStatistics, TotalSubscriptionStatistics } from "../../../../core/models/dashboard/subscriptions/subscriptions";
import StatisticComponent from "../../../common/components/dashboard/StatisticsComponent";
import FullOption from "../../../common/components/dataDisplay/FullOptionPieChart";
import { ChartData } from "../../../common/components/dataDisplay/PieChart";
import { SubscriptionStatisticsChartColor } from "../../../common/utils/ChartColors";
import "../../../../styles/layout/dashboard/subscriptions/subscriptionPlans/subscriptionPlans.less";

import SubscriptionsResource from "../Resource";

const { TabPane } = Tabs;

export interface SubscriptionStatisticsProps {
    statistics: SubscriptionStatistics
}

export enum SubscriptionStatisticsType {
    DailySubscriptionStatistics,
    MonthlySubscriptionStatistics,
    TotalSubscriptionStatistics
}

const totalSubscriptionStatistics : TotalSubscriptionStatistics = {
    canceledSubscriptions:0,
    totalPremium:0,
    totalSubscriptions:0,
    totalTrial:0,
    usersWithPremiumCard:0
}

const monthlySubscriptionStatistics : MonthlySubscriptionStatistics = {
    canceledSubscriptions:0,
    totalPremium:0,
    totalSubscriptions:0,
    totalTrial:0,
    usersWithPremiumCard:0,
    totalExistingUser:0,
    totalNewUsers:0
}

const dailySubscriptionStatistics : DailySubscriptionStatistics = {
    canceledSubscriptions:0,
    totalPremium:0,
    totalSubscriptions:0,
    totalTrial:0,
    usersWithPremiumCard:0,
    totalExistingUser:0,
    totalNewUsers:0
}

const SubscriptionStatisticsComponent: React.FC<SubscriptionStatisticsProps> = (props: SubscriptionStatisticsProps) => {

    
    const totalSubStats =  (props.statistics && props.statistics.subscriptionStatistics) ? props.statistics.subscriptionStatistics : totalSubscriptionStatistics ;
    const monthlySubStats =  (props.statistics && props.statistics.monthlySubscriptionStatistics ) ? props.statistics.monthlySubscriptionStatistics : monthlySubscriptionStatistics;
    const dailySubStats =  (props.statistics && props.statistics.dailySubscriptionStatistics) ? props.statistics.dailySubscriptionStatistics: dailySubscriptionStatistics;
    const defaultActiveKey = "1";



    const getTotalStatsData = (type: SubscriptionStatisticsType) => {
        let userStatsData: Array<ChartData> = [];

        if (type == SubscriptionStatisticsType.DailySubscriptionStatistics) {
            userStatsData = userStatsData.concat([
                { title: SubscriptionsResource.statistics.totalPro, value: dailySubStats.totalPremium ?? 0, color: SubscriptionStatisticsChartColor.Pro },
                { title: SubscriptionsResource.statistics.totalTrial, value: dailySubStats.totalTrial ?? 0, color: SubscriptionStatisticsChartColor.Trial },
                { title: SubscriptionsResource.statistics.totalNewUsers, value: dailySubStats.totalNewUsers ?? 0, color: SubscriptionStatisticsChartColor.NewUsers },
                { title: SubscriptionsResource.statistics.totalExistingUsers, value: dailySubStats.totalExistingUser ?? 0, color: SubscriptionStatisticsChartColor.ExistingUsers },
                { title: SubscriptionsResource.statistics.canceledSubscriptions, value: dailySubStats.canceledSubscriptions ?? 0, color: SubscriptionStatisticsChartColor.Canceled },
                { title: SubscriptionsResource.statistics.usersPrimaryCardOfUse, value: dailySubStats.usersWithPremiumCard ?? 0, color: SubscriptionStatisticsChartColor.PrimaryCard },
            ])
        }

        if (type == SubscriptionStatisticsType.MonthlySubscriptionStatistics) {
            userStatsData = userStatsData.concat([
                { title: SubscriptionsResource.statistics.totalPro, value: monthlySubStats.totalPremium ?? 0, color: SubscriptionStatisticsChartColor.Pro },
                { title: SubscriptionsResource.statistics.totalTrial, value: monthlySubStats.totalTrial ?? 0, color: SubscriptionStatisticsChartColor.Trial },
                { title: SubscriptionsResource.statistics.totalNewUsers, value: monthlySubStats.totalNewUsers ?? 0, color: SubscriptionStatisticsChartColor.NewUsers },
                { title: SubscriptionsResource.statistics.totalExistingUsers, value: monthlySubStats.totalExistingUser ?? 0, color: SubscriptionStatisticsChartColor.ExistingUsers },
                { title: SubscriptionsResource.statistics.canceledSubscriptions, value: monthlySubStats.canceledSubscriptions ?? 0, color: SubscriptionStatisticsChartColor.Canceled },
                { title: SubscriptionsResource.statistics.usersPrimaryCardOfUse, value: monthlySubStats.usersWithPremiumCard ?? 0, color: SubscriptionStatisticsChartColor.PrimaryCard },
            ])
        }

        if (type == SubscriptionStatisticsType.TotalSubscriptionStatistics) {
            userStatsData = userStatsData.concat([
                { title: SubscriptionsResource.statistics.totalPro, value: totalSubStats.totalPremium ?? 0, color: SubscriptionStatisticsChartColor.Pro },
                { title: SubscriptionsResource.statistics.totalTrial, value: totalSubStats.totalTrial ?? 0, color: SubscriptionStatisticsChartColor.Trial },
                { title: SubscriptionsResource.statistics.canceledSubscriptions, value: totalSubStats.canceledSubscriptions ?? 0, color: SubscriptionStatisticsChartColor.Canceled },
                { title: SubscriptionsResource.statistics.usersPrimaryCardOfUse, value: totalSubStats.usersWithPremiumCard ?? 0, color: SubscriptionStatisticsChartColor.PrimaryCard },
            ])
        }
        userStatsData = userStatsData.filter(f => f.value > 0);
        return userStatsData;

    }

    return (
        <>
            <Tabs defaultActiveKey={defaultActiveKey}>
                <TabPane tab={SubscriptionsResource.statistics.allTime} key="1" id="alltime-id">
                    <Row justify="start" gutter={16}>
                        <Col span={12}>
                            <Statistic title={SubscriptionsResource.statistics.totalSubscriptions} value={totalSubStats.totalSubscriptions} />
                            <Row justify="start" className="row-statistics" gutter={[8, 8]}>
                                <Col span={12} >
                                    <StatisticComponent {...{ title: "Total pro", badgeColor: SubscriptionStatisticsChartColor.Pro, value: totalSubStats.totalPremium }} />
                                </Col>
                                <Col span={12} >
                                    <StatisticComponent {...{ title: "Total trial", badgeColor: SubscriptionStatisticsChartColor.Trial, value: totalSubStats.totalTrial }} />
                                </Col>
                                <Col span={12} >
                                    <StatisticComponent {...{ title: "Canceled subscriptions", badgeColor: SubscriptionStatisticsChartColor.Canceled, value: totalSubStats.canceledSubscriptions }} />
                                </Col>
                                <Col span={12} >
                                    <StatisticComponent {...{ title: "Users with primary card of use", badgeColor: SubscriptionStatisticsChartColor.PrimaryCard, value: totalSubStats.usersWithPremiumCard }} />
                                </Col>
                            </Row>
                        </Col>
                        <Divider type="vertical" className="divider" />
                        <Col className="full-charter" span={11}>
                            <FullOption data={getTotalStatsData(SubscriptionStatisticsType.TotalSubscriptionStatistics)} />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={SubscriptionsResource.statistics.lastMonth} key="2">
                    <Row justify="start" gutter={16}>
                        <Col span={12}>
                            <Statistic title="Total subscriptions" value={monthlySubStats.totalSubscriptions} />
                            <Row justify="start" className="row-statistics" gutter={[8, 8]}>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Total pro", badgeColor: SubscriptionStatisticsChartColor.Pro, value: monthlySubStats.totalPremium }} />
                                </Col>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Total trial", badgeColor: SubscriptionStatisticsChartColor.Trial, value: monthlySubStats.totalTrial }} />
                                </Col>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Total new users", badgeColor: SubscriptionStatisticsChartColor.NewUsers, value: monthlySubStats.totalNewUsers }} />
                                </Col>

                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Total existing user", badgeColor: SubscriptionStatisticsChartColor.ExistingUsers, value: monthlySubStats.totalExistingUser }} />
                                </Col>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Canceled subscriptions", badgeColor: SubscriptionStatisticsChartColor.Canceled, value: monthlySubStats.canceledSubscriptions }} />
                                </Col>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Users with primary card of use", badgeColor: SubscriptionStatisticsChartColor.PrimaryCard, value: monthlySubStats.usersWithPremiumCard }} />
                                </Col>
                            </Row>
                        </Col>
                        <Divider type="vertical" className="divider" />
                        <Col className="full-charter" span={11}>
                            <FullOption data={getTotalStatsData(SubscriptionStatisticsType.MonthlySubscriptionStatistics)} />
                        </Col>
                    </Row>

                </TabPane>
                <TabPane tab={SubscriptionsResource.statistics.lastDay} key="3">
                    <Row justify="start" gutter={16}>
                        <Col span={12}>
                            <Statistic title="Total subscriptions" value={dailySubStats.totalSubscriptions} />
                            <Row justify="start" className="row-statistics" gutter={[8, 8]}>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Total pro", badgeColor: SubscriptionStatisticsChartColor.Pro, value: dailySubStats.totalPremium }} />
                                </Col>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Total trial", badgeColor: SubscriptionStatisticsChartColor.Trial, value: dailySubStats.totalTrial }} />
                                </Col>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Total new users", badgeColor: SubscriptionStatisticsChartColor.NewUsers, value: dailySubStats.totalNewUsers }} />
                                </Col>

                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Total existing user", badgeColor: SubscriptionStatisticsChartColor.ExistingUsers, value: dailySubStats.totalExistingUser }} />
                                </Col>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Canceled subscriptions", badgeColor: SubscriptionStatisticsChartColor.Canceled, value: dailySubStats.canceledSubscriptions }} />
                                </Col>
                                <Col span={8} >
                                    <StatisticComponent {...{ title: "Users with primary card of use", badgeColor: SubscriptionStatisticsChartColor.PrimaryCard, value: dailySubStats.usersWithPremiumCard }} />
                                </Col>
                            </Row>
                        </Col>
                        <Divider type="vertical" className="divider" />
                        <Col className="full-charter" span={11}>
                            <FullOption data={getTotalStatsData(SubscriptionStatisticsType.DailySubscriptionStatistics)} />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </>
    )
}

const mapStateToProps = ({ }: AppState) =>
({
});
export default connect(mapStateToProps, {
})(SubscriptionStatisticsComponent);

import { Progress } from "antd";
import React from "react";

export interface ProgressBarProps {
    percentage: number
}

const ProgressBarComponent: React.FC<ProgressBarProps> = props => {
    return (
        <>
            <Progress
                strokeColor={{
                    from: '#108ee9',
                }}
                percent={props.percentage}
                status="active"
            />
        </>
    )
}

export default ProgressBarComponent;
import { GET_MARKETPLACES_ERROR, GET_MARKETPLACES_SUCCESS, MarketplacesInStoreAction } from '../../../../actions/dashboard/marketplace/affiliates/updateMarketplacesInStore';
import { GetMarketplaceModel } from './../../../../models/dashboard/marketplace/affiliatesModel';

export const updateMarketplacesInStoreReducer = (state: GetMarketplaceModel | null = null, action:MarketplacesInStoreAction) => {
    switch(action.type) {
        case GET_MARKETPLACES_SUCCESS:
            return { ...state, ...action.marketplaces }
        case GET_MARKETPLACES_ERROR:
            return {...state};
        default:
            return {...state};
    }
}
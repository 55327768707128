import { GET_USERSBYDEVICE_ERROR, GET_USERSBYDEVICE_SUCCESS, ListOfUsersByDeviceAction } from "../../../actions/dashboard/users/updateListOfUsersByDeviceInStore";
import { UserData, UserModel } from "../../../models/dashboard/users/user";


export const updateListOfUsersByDeviceInStoreReducer = (state: Array<UserModel> | [] = [], action: ListOfUsersByDeviceAction) => {
    switch (action.type) {
        case GET_USERSBYDEVICE_SUCCESS:
            return [ ...action.usersDevice ];
        case GET_USERSBYDEVICE_ERROR:
            return [] as Array<UserModel>;
        default:
            return [] as Array<UserModel>;
    }
}
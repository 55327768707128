import React, {useState} from 'react';
import BreadcrumbComponent, {BreadcrumbProps} from "../../common/components/dataDisplay/BreadcrumbComponent";
import {RoutePaths} from "../../routes";
import {Row} from "antd";
import NotificationLogListComponentWrapper from "./notificationLog/NotificationLogListComponentWrapper";
import NotificationExecutionLogListComponent from "./notificationExecution/NotificationExecutionLogListComponent";
import NotificationTemplateListComponent from './notificationTemplate/NotificationTemplateListComponent';
import { NotificationTemplateSendComponent } from './notificationTemplate/NotificationTemplateSendComponent';

const currentBreadCrumbs: BreadcrumbProps = {
    title: "Dashboard",
    className: "breadcrumb",
    description: "",
    breadcrumbs: [
        {
            title: "Notifications",
            path: RoutePaths.NOTIFICATION_PATH,
        }
    ]
};


const AdminNotification: React.FC<any> = (props: any) => {
    const [selectedRows, setSelectedRows] = useState([]);

    const showSend = selectedRows.length > 0;

    return (
        <>
            <BreadcrumbComponent {...currentBreadCrumbs}/>
            <Row className="notification-content">
                <div className="nt-row-card-content">
                    <NotificationTemplateListComponent
                        width={showSend ? '49.3%' : '96%'}
                        setSelectedRows={setSelectedRows}/>
                    {
                        showSend ?
                            <NotificationTemplateSendComponent templates={selectedRows}/>
                            : null
                    }
                </div>
            </Row>
            <Row className="notification-content">
                <div className="nt-row-card-content">
                    <NotificationExecutionLogListComponent
                        isLog={false}
                        title={'Sent Notifications'}
                    />
                </div>
            </Row>
            <Row className="notification-content">
                <div className="nt-row-card-content">
                    <NotificationLogListComponentWrapper/>
                </div>
            </Row>
        </>
    );
}


export default AdminNotification;
import { Empty, Row, Spin } from "antd";
import Table from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { CSVUserSubscriptions } from "../../../../core/models/dashboard/subscriptions/subscriptions";
import ButtonComponent from "../../../common/components/dataDisplay/Button";
import IconsComponent, { IconType } from "../../../common/components/dataDisplay/Icons";
import SearchComponent from "../../../common/components/dataInput/components/SearchComponent";
import "../../../../styles/layout/dashboard/subscriptions/subscriptionList/subscriptionList.less";
import { GetListOfWithdrawals, PaginatedWithdrawals, WithdrawalsModel, WithdrawType } from "../../../../core/models/dashboard/withdraw/withdraw";
import { TransferStatus } from "../../../../core/models/dashboard/transactions/transation";
import moment from "moment";
import { getMoneyType } from "../../../common/utils/Utils";
import { udpateWithdrawListInStoreAction } from "../../../../core/actions/dashboard/moveMoney/updateWithdrawListInStoreAction";

export interface WithdrawListComponentProps {
    withdrawList: PaginatedWithdrawals | null,
    udpateWithdrawListInStoreAction(searchWithdraw: GetListOfWithdrawals): void;

}

var initData: PaginatedWithdrawals = {
    items: [],
    pageCount: 1,
    pageNumber: 1,
    pageSize: 10,
    totalItemCount: 1
}

var initSearchParams: GetListOfWithdrawals = {
    orderDescending: true,
    pageNumber: 1,
    pageSize: 10,
    searchValue: '',
    sortColumn: 'CreatedAt'
}

const WithdrawListComponent: React.FC<WithdrawListComponentProps> = (props: WithdrawListComponentProps) => {
    let data: PaginatedWithdrawals = props.withdrawList ? props.withdrawList : initData;
    // let csvData: Array<UserSubscriptions> = props.allUserSubscriptions && props.allUserSubscriptions.length > 0 ? props.allUserSubscriptions : [];

    const defaultSortColumnt = "CreatedAt";
    const maxSearchLength = 50;
    const [isLoading, setLoading] = useState(false);
    const [currentWithdrawals, setcurrentWithdrawals] = useState<PaginatedWithdrawals>();
    const [csvUserSubscriptions, setUserSubscriptions] = useState<Array<CSVUserSubscriptions>>([])

    useEffect(() => {
        if (props.withdrawList) {
            if (!currentWithdrawals) {
                setcurrentWithdrawals(props.withdrawList)
            }

            if (!Object.is(currentWithdrawals, props.withdrawList)) {
                setLoading(false);
                setcurrentWithdrawals(props.withdrawList)
            }
        }
    });

    const columns = [
        {
            title: <><b>Full Name</b></>, dataIndex: 'user.fullName', key: 'Id', width: 220,
            fixed: true,
            sorter: true,
            render: (fullName: string, row: WithdrawalsModel) => (
                <> {`${row.fullName}`} </>
            )
        },
        {
            title: <><b>Email</b></>, dataIndex: 'user.email',
            width: 220,
            sorter: true,
            key: 3,
            render: (email: string, row: WithdrawalsModel) => (
                <> {row.email} </>
            )
        },
        {
            title: <><b>Withdraw Type </b></>,
            dataIndex: 'withdrawType', width: 170, key: 5, sorter: true,
            render: (withdrawType: WithdrawType, row: WithdrawalsModel) => (
                <> {`${WithdrawType[row.withdrawType]}`} </>
            )

        },
        {
            title: <><b>Withdraw Date </b></>,
            dataIndex: 'createdAt', width: 170, key: 5, sorter: true,
            render: (withdrawDate: Date, row: WithdrawalsModel) => (
                <> {row.createdAt ? `${moment(new Date(row.createdAt)).format("MM-DD-YYYY")}` : ("")} </>
            )
        },
        {
            title: <><b>Goal</b></>,
            dataIndex: 'nameOfFrom', width: 170, key: 5, sorter: true,
            render: (nameOfFrom: string, row: WithdrawalsModel) => (
                <> {`${row.nameOfFrom}`} </>
            )
        },
        {
            title: <><b>Bank destination</b></>,
            dataIndex: 'nameOfDestination', width: 170, key: 5, sorter: true,
            render: (nameOfDestination: string, row: WithdrawalsModel) => (
                <> {`${row.nameOfDestination} (****${row.mask})`} </>
            )
        },
        {
            title: <><b>Amount</b></>,
            dataIndex: 'amount', width: 170, key: 5, sorter: true,
            render: (amount: number, row: WithdrawalsModel) => (
                <> {getMoneyType(row.amount)} </>
            )
        },
        {
            title: <><b>Fee</b></>,
            dataIndex: 'withdrawFee', width: 170, key: 5, sorter: true,
            render: (fee: number, row: WithdrawalsModel) => (
                <> {getMoneyType(row.withdrawFee)} </>
            )
        },
        {
            title: <><b>Transfer Status</b></>,
            dataIndex: 'transfer.transferStatus', width: 170, key: 5, sorter: true,
            render: (transferStatus: string, row: WithdrawalsModel) => (
                <> {`${TransferStatus[row.transferStatus]}`} </>
            )
        }

    ];

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;
            } else {
                initSearchParams.sortColumn = defaultSortColumnt;
                initSearchParams.orderDescending = false;
            }
        }

        if (pagination) {
            if (props.withdrawList?.pageNumber != pagination.current) {
                initSearchParams.pageNumber = pagination.current;
            }
        };

        props.udpateWithdrawListInStoreAction(initSearchParams);
        setLoading(true);

    }

    const onSearchSubmit = (searchValue: string) => {
        initSearchParams.searchValue = searchValue;
        initSearchParams.pageNumber = 1;
        setLoading(true)
        props.udpateWithdrawListInStoreAction(initSearchParams);
    }


    const onGetAllWithdrawUsers= () => {
    }



    return (
        <>
            <Spin spinning={isLoading}>
                <Row className="subscription-list-search-row">
                    <span className="searchbox" style={{ display: 'flex' }}>
                        {/* <CSVLink
                            filename={`user-subscription-list.csv`}
                            title={`Download CSV`}
                            data={csvUserSubscriptions} >
                            <ButtonComponent
                                className="export-button"
                                shape="round"
                                size="large"
                                disabled={csvUserSubscriptions.length == 0}
                                icon={<IconsComponent {...{ type: IconType.Download }} />}
                                name={`Download CSV`} />
                        </CSVLink> */}
                        <SearchComponent {...{ className: "search-input", maxLength: maxSearchLength, size: "large", name: "searchValue", placeholder: "Search something...", onSearch: onSearchSubmit }} />
                    </span>
                </Row>
                <Table
                    columns={columns}
                    rowKey="id"
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`No result`} /> }}
                    pagination={{
                        total: data.totalItemCount, current: data.pageNumber,
                        pageSize: data.pageSize,
                        showSizeChanger: false,
                    }}
                    scroll={{ x: 1200 }}
                    sortDirections={['ascend', 'descend', 'ascend']}
                    onChange={(onChangeEvent)}
                    dataSource={data.items}
                />
            </Spin>
        </>
    )
}

const mapStateToProps = ({ withdrawList }: AppState) =>
({
    withdrawList: withdrawList
});
export default connect(mapStateToProps, {
    udpateWithdrawListInStoreAction
})(WithdrawListComponent);

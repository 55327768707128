import store from "../../commons/localstorage";

import jwtDecode from "jwt-decode";

const userDataClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata";

const getAuthenticatedUser = () => {
  try {
    const token: string | null = store.getToken();
    const decodedToken: any = token ? jwtDecode(token) : null;
    return decodedToken ? JSON.parse(decodedToken[userDataClaim]) : null;
  } catch (err) {
    return null;
  }
};

export default getAuthenticatedUser;

import {
    UserDetailsModel,
    UserDeletionModel,
    UpdateUsersLegalNameModel
} from './../../../models/dashboard/users/userDetails';
import { adminHttpClient } from "../../../commons/adminHttpClinet";

export const getUsersDetails = (userId: string ): Promise<UserDetailsModel> => {
    return adminHttpClient.get(`/api/Admin/AdminUsers/get-user-details?userId=${userId}`)
}

export const deleteUser = (userId: string, isScheduled: boolean): Promise<UserDetailsModel | null> => {

    return adminHttpClient.delete(`/api/Admin/AdminUsers/execute-user-deletion/${userId}`, {}) as Promise<UserDetailsModel | null>;
}

export const updateUsersLegalName = (model: UpdateUsersLegalNameModel) : Promise<UserDetailsModel | null> => {
    return adminHttpClient.post(`/api/Admin/AdminUsers/update-users-legal-name`, model);
}

export const cancelUserDeletion = (userId: string): Promise<UserDetailsModel | null> => {
    const body = {
        userId: userId,
        isScheduled: true
    } as UserDeletionModel;
    return adminHttpClient.post('/api/Admin/AdminUsers/cancel-user-deletion', body) as Promise<UserDetailsModel | null>;
}
import { updateAffiliatesInStoreReducer } from './affiliates/updateAffiliatesInStoreReducer';
import { updateMarketplaceCategoriesInStoreReducer } from './categories/updateMarkteplaceCategoriesInStoreReducer';
import { updateMarketplacePlatformInStoreReducer } from './platform/updateMarketplacePlatformInStoreReducer';
import { updateMarketplacesInStoreReducer } from './affiliates/updateMarketplacesInStoreReducer';
import {updateAffiliatePurchaseStatisticsInStoreReducer} from './affiliates/updateAffiliatePurchaseStatisticsInStoreReducer';
import {updateAffiliateDetailsInStoreReducer} from "./affiliates/updateAffiliateDetailsInStoreReducer";
import {updateAllMarketplacesInStoreReducer} from "./affiliates/updateAllMarketplacesInStoreReducer";
import {updateCashbackRequestInStoreReducer} from "./amazon/updateCashbackRequestInStoreReducer";
import {updateApprovedRequestInStoreReducer} from "./amazon/updateApprovedCashbackRequestInStoreReducer";
import {updateRejectedRequestInStoreReducer} from "./amazon/updateRejectedCashbackRequestInStoreReducer";

const marketplaceReducer = {
    updateAffiliatesInStoreReducer,
    updateMarketplaceCategoriesInStoreReducer,
    updateMarketplacePlatformInStoreReducer,
    updateMarketplacesInStoreReducer,
    updateAffiliateDetailsInStoreReducer, 
    updateAffiliatePurchaseStatisticsInStoreReducer,
    updateAllMarketplacesInStoreReducer,
    updateCashbackRequestInStoreReducer,
    updateApprovedRequestInStoreReducer,
    updateRejectedRequestInStoreReducer
}

export default marketplaceReducer;
import { DeleteFilled, DeleteOutlined, LinkOutlined, PlusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Row, Upload, Select, Divider, Alert, InputNumber, message, Space, Spin } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { MarketplaceCategoriesModel } from "../../../../core/models/dashboard/marketplace/marketplaceCategoryModel";
import { MarketplacePlatformModel } from "../../../../core/models/dashboard/marketplace/marketplaceModels";
import "../../../../styles/layout/dashboard/marketplace/addNewAffiliate.less"
import { ErrorMessage, FastField, Field, FieldArray, FieldArrayRenderProps, Formik, FormikErrors, getIn, useFormik } from "formik";
import * as Yup from "yup";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import ImgCrop from "antd-img-crop";
import { AddNewMarketplaceModel, OtherPercentageCommissionModel } from "../../../../core/models/dashboard/marketplace/affiliatesModel";
import { FileResponse } from "../../../../core/models/files/fileModel";
import { commandRequest } from "../../../common/requestUtils/requestUtils";
import services from "../../../../core/services";
import AlertComponent from "../../../common/components/dataDisplay/Alert";
import resource from "../../../common/resource/resource";
import { acceptFileUpload, getUploadChangeFormData, handleKeypress, maxInputLength } from "../../../common/utils/Utils";
import { updateMarketplacePlatformInStoreAction } from "../../../../core/actions/dashboard/marketplace/platform/updateMarketplacePlatformInStore";
import { updateMarketplaceCategoriesInStoreAction } from "../../../../core/actions/dashboard/marketplace/categories/updateMarketplaceCategoriesInStore";
import { AppState } from "../../../../core/commons/reduxStore";
import { connect } from "react-redux";
const { marketplace, uploadIcon } = services;

const { TextArea } = Input;
const { Option } = Select;

export interface AddNewAffiliateProps {
    isVisible: boolean;
    affiliateCategory: Array<MarketplaceCategoriesModel>
    marketplacePlatform: Array<MarketplacePlatformModel>
    changeVisibility: (isVisible: boolean) => void;
    updateMarketplaceCategoriesInStoreAction: (affiliateCategory: Array<MarketplaceCategoriesModel> | []) => void;
    updateMarketplacePlatformInStoreAction: (marketplacePlatform: Array<MarketplacePlatformModel> | []) => void;
}

interface PromotionPercentage {
    promotionName: string;
    cashBackPercentage: number | null;
    guacCashBackPercentage: number | null;
}

interface ErrorPromotionPercentage {
    promotionName: string | null;
    cashBackPercentage: string | null;
    guacCashBackPercentage: string | null;
}

interface NewAffiliateValues {
    name: string;
    iconUrl: string;
    accessUrl: string;
    description: string;
    secondAccessUrl: string;
    platformId: string;
    categoryId: string;
    percentages: Array<PromotionPercentage>;
}

let initialValues: NewAffiliateValues = {
    name: "",
    iconUrl: "",
    accessUrl: "",
    description: "",
    secondAccessUrl: "",
    platformId: "",
    categoryId: "",
    percentages: [] as Array<PromotionPercentage>
}

const formValidator =
    Yup.object({
        name: Yup.string().max(50, 'You have reached your maximum limit of characters allowed').required("Affilite name is required"),
        iconUrl: Yup.string().required("Image is required"),
        accessUrl: Yup.string().url('Must be a valid URL').required("Access Url is required"),
        secondAccessUrl: Yup.string().url('Must be a valid URL').nullable().optional(),
        platformId: Yup.string().required("Platform is required"),
        categoryId: Yup.string().required("Category is required"),
        percentages: Yup.array().of(
            Yup.object().shape({
                promotionName: Yup.string().notRequired(),
                cashBackPercentage: Yup.number().notRequired(),//.moreThan(0, 'Cashback percentage must be greater than 0')
                //.required("CashBack percentage is required"),
                guacCashBackPercentage: Yup.number().notRequired()//.moreThan(0, 'Cashback percentage must be greater than 0')
                //.required("Guac Cashback percentage is required"),
            })
        )
    });


const AddNewAffiliateComponent: React.FC<AddNewAffiliateProps> = props => {

    const [promotions, setPromotions] = useState<Array<number>>([])
    const [uploadedFile, setUploadedFile] = useState<FormData | null>();
    const [imageUrl, setImageUrl] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [percentageErrors, setPercentageErrors] = useState<Array<ErrorPromotionPercentage>>([])
    const [acceptedFiles, setAcceptedFiles] = useState<Array<string>>([]);
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isAmazonSelected, setIfAmazonIsSelected] = useState<boolean>(false)

    useEffect(() => {
        initPromotionsForm();
        setAcceptedFiles(acceptFileUpload.split(','))
    }, [promotions])

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img as any);
    }

    const onNewAffiliateSubmit = () => {
        setLoading(true);

        if (uploadedFile) {
            commandRequest(() => uploadIcon(uploadedFile))
                .then((res: FileResponse) => {
                    onAddNewAffiliate(res);
                }, (reason: any) => {
                    setErrorMessage(reason);
                }).catch((_error: any) => {
                })
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        onSubmit: onNewAffiliateSubmit
    })

    const onAddNewAffiliate = (uploadedIcon: FileResponse) => {
        let _promotions: Array<OtherPercentageCommissionModel> = [];
        formik.values.percentages.forEach((item) => {
            _promotions.push({
                cashBackPercentage: item.cashBackPercentage ?? 0,
                guacCashBackPercentage: item.guacCashBackPercentage ?? 0,
                promotionName: item.promotionName ?? ''
            })
        })

        const newAffiliate: AddNewMarketplaceModel = {
            name: formik.values.name,
            iconUrl: `${uploadedIcon.fileName}`,
            accessUrl: formik.values.accessUrl,
            secondAccessUrl: formik.values.secondAccessUrl,
            categoryId: formik.values.categoryId,
            platformId: formik.values.platformId,
            description: formik.values.description,
            promotions: _promotions
        }

        if (newAffiliate) {
            try {
                commandRequest(() => marketplace.addNewAffiliate(newAffiliate))
                    .then(() => {
                        onClose();
                    }, (reason: any) => {
                        setErrorMessage(reason)
                    })
                    .catch((error) => {
                    })
            }
            catch (error) {
            }
        }
    }

    const onUploadChange = (info: UploadChangeParam) => {

        if (info) {
            if (info.file && acceptedFiles.length > 0 && acceptedFiles.includes(info.file.type)) {

                let formData = getUploadChangeFormData(info);
                if (formData) {

                    formik.values.iconUrl = info.file.fileName ? info.file.fileName : "uploaded";
                    formik.setValues({ ...formik.values });
                }

                setUploadedFile(formData);

                getBase64(info.fileList.find(f => f.uid == info.file.uid)?.originFileObj as any, (imageUrl: any) => {
                    setImageUrl(imageUrl);
                });
            }
        }
    }

    const onBeforeCropImage = (file: File, fileList: File[]): boolean => {
        if (file && acceptedFiles.length > 0 && acceptedFiles.includes(file.type)) {
            return true;
        }
        return false;
    }

    const onPreview = async (info: UploadFile<any>) => {
        if (info && acceptedFiles.length > 0 && acceptedFiles.includes(info.type)) {
            let src = info?.url;
            if (!src) {
                src = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(info.originFileObj as any);
                    reader.onload = () => resolve(reader.result as any);
                });
            }
        }
    };

    const onClose = () => {
        formik.setValues({
            name: "",
            iconUrl: "",
            accessUrl: "",
            description: "",
            secondAccessUrl: "",
            platformId: "",
            categoryId: "",
            percentages: []
        });
        setLoading(false);
        props.changeVisibility(props.isVisible);
    };

    const onPlatformSelected = (item: string) => {
        formik.values.platformId = item;
        formik.setValues({ ...formik.values });
    }

    const onCategorySelected = (item: any) => {
        formik.values.categoryId = item;
        formik.setValues({ ...formik.values });
        // checkIfAmazonCategoryIsSelected();
    }

    const initPromotionsForm = () => {
        if (formik.values.percentages.length == 0) {
            addNewPromotionForm();
        }
    }

    const addNewPromotionForm = () => {
        let newPromotion: PromotionPercentage = {
            cashBackPercentage: (isAmazonSelected) ? 0 : null,
            guacCashBackPercentage: (isAmazonSelected) ? 0 : null,
            promotionName: 'Default Promotion'
        };

        formik.values.percentages.push(newPromotion);
        formik.setValues({ ...formik.values });
        let newPromotionsError: ErrorPromotionPercentage = { cashBackPercentage: null, guacCashBackPercentage: null, promotionName: null }
        percentageErrors.push(newPromotionsError)
        setPercentageErrors([...percentageErrors])
    }

    const removePromotionForm = (index: number) => {
        formik.values.percentages.splice(index, 1);
        formik.setValues({ ...formik.values });
        setPercentageErrors([...[], ...percentageErrors.splice(index, 1)])
        onErrorPercentageCheck();
    }

    const closeNotification = () => {
        setErrorMessage(null);
    }

    const getCategories = (
        <>
            {props.affiliateCategory.map((item: MarketplaceCategoriesModel, i) => {
                return (<option key={i} value={item.id}>{item.name}</option>)
            })
            }
        </>
    )

    const getPlatforms = (
        <>
            {props.marketplacePlatform.map((item: MarketplacePlatformModel, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            })
            }
        </>
    )

    const checkIfAmazonCategoryIsSelected = () => {
        var categories: Array<MarketplaceCategoriesModel> = [];
        props.affiliateCategory.map((item: MarketplaceCategoriesModel, i) => {
            categories.push(item)
        });
        if (categories.find(f => f.id.match(formik.values.categoryId))?.name.trim().toLowerCase().match('amazon')) {
            setIfAmazonIsSelected(true);
            formik.values.percentages[0].cashBackPercentage = 0;
            formik.values.percentages[0].guacCashBackPercentage = 0;
            formik.values.percentages[0].promotionName = 'Default Promotion';
        } else {
            setIfAmazonIsSelected(false);
        }
    }

    const uploadButton = (
        <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onErrorPercentageCheck = () => {
        formik.values.percentages.forEach((item, i) => {
            if (item) {
                if (!item.promotionName || item.promotionName == null || item.promotionName.trim().length == 0) {
                    percentageErrors.forEach((element, index) => {
                        if (index == i) {
                            element.promotionName = 'Promotion name is required';
                        }
                    });;
                } else {
                    percentageErrors.forEach((element, index) => {
                        if (index == i) {
                            element.promotionName = null;
                        }
                    });
                }

                if (!item.guacCashBackPercentage || item.guacCashBackPercentage == null || item.guacCashBackPercentage == 0) {
                    percentageErrors.forEach((element, index) => {
                        if (index == i) {
                            element.guacCashBackPercentage = 'Guac Cashback percentage is required';
                        }
                    });
                } else {
                    percentageErrors.forEach((element, index) => {
                        if (index == i) {
                            element.guacCashBackPercentage = null;
                        }
                    });
                }

                if (!item.cashBackPercentage || item.cashBackPercentage == null || item.cashBackPercentage == 0) {
                    percentageErrors.forEach((element, index) => {
                        if (index == i) {
                            element.cashBackPercentage = 'CashBack percentage is required';
                        }
                    });
                } else {
                    percentageErrors.forEach((element, index) => {
                        if (index == i) {
                            element.cashBackPercentage = null;
                        }
                    });
                }
            }
        })
    }

    const getPromotionSection = (index: number) => (

        <>
            <Row className="row-item" key={index}>
                {index != 0 ?
                    (
                        <>
                            <span className="new-promotion-section">
                                <span className="item-title">{resource.dashboard.marketplace.affiliate.newAffiliate.promoName}</span>
                                <span className="remove-promotion">
                                    <Button onClick={() => { removePromotionForm(index) }} className="button-delete" icon={<DeleteFilled className="error-style" />} size={'large'} />
                                </span>
                            </span>
                        </>
                    ) :
                    (
                        <span className="item-title">{resource.dashboard.marketplace.affiliate.newAffiliate.promoName}</span>
                    )
                }

                <Input
                    maxLength={maxInputLength}
                    name={`percentages.${index}.promotionName`}
                    value={formik.values.percentages[index].promotionName ?? ''}
                    onChange={formik.handleChange} className="item-input" />

                <span className="percentage-items">
                    <span className="percentage first-item">
                        <span className="item-title">{resource.dashboard.marketplace.affiliate.newAffiliate.userCashBack}</span>
                        <Input
                            min={0} type="number" name={`percentages.${index}.cashBackPercentage`}
                            onChange={formik.handleChange}
                            placeholder="Enter percentage..."
                            value={isAmazonSelected ? 0 :
                                formik.values.percentages[index].cashBackPercentage ?
                                    Number(formik.values.percentages[index].cashBackPercentage)
                                    : undefined}
                            className="item-input with-element-after" addonAfter="%" />
                    </span>

                    <span className="percentage">
                        <span className="item-title">{resource.dashboard.marketplace.affiliate.newAffiliate.guacCashBack}</span>
                        <Input
                            min={0} type="number" name={`percentages.${index}.guacCashBackPercentage`}
                            onChange={formik.handleChange}
                            placeholder="Enter percentage..."
                            value={isAmazonSelected ? 0 :
                                formik.values.percentages[index].guacCashBackPercentage ?
                                    Number(formik.values.percentages[index].guacCashBackPercentage)
                                    : undefined}
                            className="item-input  with-element-after" addonAfter="%" />
                    </span>
                </span>

                <Divider />
            </Row>
        </>
    )

    const getFooter = (
        <>
            <div style={{ textAlign: 'right' }}>
                <Button className="cancel-button" onClick={onClose} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
                <Button className="save-button" htmlType="submit" onClick={() => { return (formik.handleSubmit(), onErrorPercentageCheck()) }} type="primary">
                    Save
                </Button>
            </div>
        </>
    )

    return (
        <Space>
            <Form>
                <Drawer
                    title={
                        <>
                            <span className="modal-header-title">Add new Store</span>
                            {errorMessage ? <AlertComponent {...{ type: 'error', closable: true, message: 'Error', description: errorMessage, showIcon: true, onClose: closeNotification }}></AlertComponent> : ("")}
                        </>
                    }
                    placement={"right"}
                    closable={false}
                    onClose={onClose}
                    visible={props.isVisible}
                    key={"right"}
                    width={464}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <>
                            {getFooter}
                        </>
                    }>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <Row className="add-affiliate-row-item">
                            <Row className="row-item">
                                <span className="affiliate-name">
                                    <span className="head-item">
                                        <span className="affiliate-icon">
                                            <ImgCrop beforeCrop={onBeforeCropImage}
                                                aspect={1}
                                                grid={false}
                                                shape='round'
                                                zoom={true}
                                                minZoom={1}

                                                maxZoom={3}
                                                rotate={true}>
                                                <Upload
                                                    listType="picture-card"
                                                    className={formik.errors.iconUrl && formik.touched.iconUrl ? "affiliate-icon error" : "affiliate-icon"}
                                                    onChange={onUploadChange}
                                                    onPreview={onPreview}
                                                    accept={acceptFileUpload}
                                                    showUploadList={false}>
                                                    {imageUrl ? <img className="render-uploaded-image" src={imageUrl} alt="avatar" /> : uploadButton}
                                                </Upload>
                                            </ImgCrop>
                                        </span>
                                    </span>
                                    <span className="head-item name">
                                        <span className="item-title">Affiliate name</span>
                                        <Input maxLength={maxInputLength} onChange={formik.handleChange} name="name" className="item-input" placeholder="Please enter name" />
                                        {formik.errors.name && formik.touched.name && (
                                            <div
                                                className="ant-form-item-explain ant-form-item-explain-error">
                                                <div role="alert">{formik.errors.name}</div>
                                            </div>
                                        )}
                                    </span>
                                </span>
                            </Row>

                            {/* <Row className="row-item">
                                    <span className="item-title">Description</span>
                                    <TextArea onChange={formik.handleChange} name="description" placeholder="Type something..." className="item-input" maxLength={100} />
                                </Row>
                            */}

                            <Row className="row-item">
                                <span className="relation-options">
                                    <span className="platform-item first-item">
                                        <span className="item-title">Platform</span>
                                        <Select onChange={onPlatformSelected} className="item-select" defaultValue="Select platform"  >
                                            {getPlatforms}
                                        </Select>
                                        {formik.errors.platformId && formik.touched.platformId && (
                                            <div
                                                className="ant-form-item-explain ant-form-item-explain-error">
                                                <div role="alert">{formik.errors.platformId}</div>
                                            </div>
                                        )}
                                    </span>
                                    <span className="platform-item">
                                        <span className="item-title">Category</span>
                                        <Select onChange={onCategorySelected} className="item-select" defaultValue="Select category"  >
                                            {getCategories}
                                        </Select>
                                        {formik.errors.categoryId && formik.touched.categoryId && (
                                            <div
                                                className="ant-form-item-explain ant-form-item-explain-error">
                                                <div role="alert">{formik.errors.categoryId}</div>
                                            </div>
                                        )}
                                    </span>
                                </span>
                            </Row>

                            <Divider />

                            <Row className="row-item no-padding">
                                <span className="main-title">URL</span>
                                <Row className="row-item">
                                    <span className="item-title">Access URL</span>
                                    <Input onChange={formik.handleChange} name="accessUrl" placeholder="https://placelink.com" className="item-input with-element" addonBefore={<LinkOutlined />} />
                                    {formik.errors.accessUrl && formik.touched.accessUrl && (
                                        <div
                                            className="ant-form-item-explain ant-form-item-explain-error">
                                            <div role="alert">{formik.errors.accessUrl}</div>
                                        </div>
                                    )}
                                </Row>
                            </Row>

                            <Row className="row-item">
                                <span className="item-title">Second access URL (Optional)</span>
                                <Input onChange={formik.handleChange} name="secondAccessUrl" placeholder="https://placelink.com" className="item-input with-element" addonBefore={<LinkOutlined />} />
                                {formik.errors.secondAccessUrl && formik.touched.secondAccessUrl && (
                                    <div
                                        className="ant-form-item-explain ant-form-item-explain-error">
                                        <div role="alert">{formik.errors.secondAccessUrl}</div>
                                    </div>
                                )}
                            </Row>

                            <Divider />

                            <Row className="row-item no-padding">
                                <span className="main-title">Promotions {isAmazonSelected ? <i>(Optional)</i> : ("")} </span>
                                {/* <Form>
                                <FieldArray
                                    name="percentages"
                                    validateOnChange={false}
                                    render={arrayHelpers => (
                                        <div>
                                            {formik.values.percentages && formik.values.percentages.length > 0 ? (
                                                formik.values.percentages.map((item, index) => (
                                                    <div key={index}>
                                                        {getPromotionSection(arrayHelpers, index)}
                                                    </div>
                                                ))
                                            ) : (<> </>)}
                                        </div>
                                    )}
                                />
                            </Form> */}
                                <Form.List name="users">
                                    {() => (
                                        <>
                                            {formik.values.percentages.map((item, index) => (
                                                getPromotionSection(index)
                                            ))}
                                        </>
                                    )}
                                </Form.List>

                                <Button className="addNewPromotion-button" onClick={addNewPromotionForm} type="link" shape="round" icon={<PlusCircleOutlined />} >
                                    Add new
                                </Button>
                            </Row>
                        </Row>
                    </Spin>
                </Drawer>
            </Form>
        </Space>
    )
}
// export default AddNewAffiliateComponent;

const mapStateToProps = ({ affiliateCategory, marketplacePlatform }: AppState) =>
({
    affiliateCategory: affiliateCategory,
    marketplacePlatform: marketplacePlatform
});


export default connect(mapStateToProps, {
    updateMarketplaceCategoriesInStoreAction,
    updateMarketplacePlatformInStoreAction
})(AddNewAffiliateComponent);